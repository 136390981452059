
import { ACTION_TYPES } from '../actions/checkin-form-actions';

const INITIAL_STATE = {
  loading: '',
  guest_status: 'new', // new | existing | update
  guest: null,
  initialData: {},
  nda: null,
  employees: [],
  updatesFlags: {},
  lastActivity: 0
};

export default function checkinFormReducer(state=INITIAL_STATE, action) {
  switch(action.type){
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
        lastActivity: Date.now()
      };

    case ACTION_TYPES.SET_EMPLOYEES_LIST:
      return {
        ...state,
        employees: action.payload
      };

    case ACTION_TYPES.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: action.payload || INITIAL_STATE.initialData,
        guest_status: action.payload.timestamp_nda ? 'existing' : 'new'
      };

    case ACTION_TYPES.SET_GUEST:
      return {
        ...state,
        lastActivity: Date.now(),
        guest: action.payload
      };

    case ACTION_TYPES.UPDATE_GUEST:
      const {key, value} = action.payload;
      const guest = state.guest;
      const initialData = state.initialData;
      const updatesFlags = state.updatesFlags;

      guest[key] = value;

      if(key !== 'person_visiting') // Exclude person_visiting from updates object while performing checkin.
        updatesFlags[key] = (initialData[key] !== value) ? true : false;

      return {
        ...state,
        lastActivity: Date.now(),
        guest,
        updatesFlags
      };

    case ACTION_TYPES.SET_NDA:
      return {
        ...state,
        lastActivity: Date.now(),
        nda: action.payload
      }

    case ACTION_TYPES.SET_SIGNATURE:
      const nda = state.nda;
      nda && (nda['signature'] = action.payload);
      
      return {
        ...state,
        lastActivity: Date.now(),
        nda
      };

    case ACTION_TYPES.RESET_ALL:
      return {
        ...INITIAL_STATE,
        lastActivity: Date.now(),
      };

    case ACTION_TYPES.RESET_NDA:
      return {
        ...state,
        lastActivity: Date.now(),
        nda: null
      };

    default:
      return state;
  }
};
