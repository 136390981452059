import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import client from "../ordertrack-client/client";

const BASE_URL = process.env.REACT_APP_FILE_UPLOAD_URL || "";

// Function to initiate multipart upload
export const initiateMultipartUpload = async (filename: string) => {
  const response = await client.get(
    `${BASE_URL}/initiate?filename=${filename}`
  );

  return response.data;
};

// Function to generate presigned URL
export const generatePresignedUrl = async (
  filename: string,
  uploadId: string,
  partNumber: number
) => {
  const payload = JSON.stringify({
    filename,
    upload_id: uploadId,
    part_no: partNumber,
  });

  const response = await client.post(`${BASE_URL}/generate`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data.presigned_url;
};

export const uploadPart = async (
  presignedUrl: string,
  fileBuffer: Blob,
  uploadOptions?: AxiosRequestConfig
) => {
  const customAxios = axios.create();

  // Set an empty string as Content-Type
  customAxios.defaults.headers.common["Content-Type"] = "";

  try {
    const response: AxiosResponse = await customAxios.put(
      presignedUrl,
      fileBuffer,
      {
        ...uploadOptions,
        headers: {
          ...uploadOptions?.headers,
          "Content-Type": "",
        },
      }
    );
    if (response.status === 200) {
      const etag = response.headers["etag"];
      if (etag) {
        return {response, etag: etag.replace(/"/g, "")};
      } else {
        throw new Error("Etag header not found in the response.");
      }
    } else {
      throw new Error("Issue uploading the chunk...");
    }
  } catch (error) {
    throw new Error("Error during the upload: " + (error as Error).message);
  }
};

// Function to complete multipart upload
export const completeMultipartUpload = async (
  filename: string,
  uploadId: string,
  parts: any[]
) => {
  const payload = JSON.stringify({
    filename,
    upload_id: uploadId,
    part_details: parts,
  });

  const response = await client.post(`${BASE_URL}/complete`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data.etag.replace(/"/g, "");
};
