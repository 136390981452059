
import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { momentTrackService } from '../../../../core/system/legacy-services/moment-track';
import { jobTicketService } from '../../../../core/system/legacy-services/job-ticket';
import useNotify from '../../../../core/hooks/use-notify';
import { useConfirmDialog } from '../../../../core/providers/ConfirmDialogProvider';
import { useAuthentication } from '../../../../core/providers/AuthenticationProvider';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CountdownText from '../../../../core/components/CountdownText';
import ReportDialog from './ReportDialog';
import ActivityLogs from './ActivityLogs';
import PendingTicket from './PendingTicket';
import PutAwayTicket from './PutAwayTicket';
import ClosedTicket from './ClosedTicket';
import VoidTicket from './VoidTicket';

const JobTicket = function(props) {
  const {data, documentUrl} = props;

  const timer = useRef(null);
  const reportDialog = useRef(null);
  const notify = useNotify();
  const confirm = useConfirmDialog();
  const [currentUser] = useAuthentication();
  const currentLocation = useLocation();
  const [loading, setLoading] = useState(false);
  const [hideTimer, setHideTimer] = useState(false);
  const [clockedIn, setClockedIn] = useState(false);
  const [ticketOpen, setTicketOpen] = useState(false);
  const [newTimeLogs, setNewTimeLogs] = useState([]);
  const [ticketStatus, setTicketStatus] = useState(()=>{
    if(data.activeTicket){
      if(data.unknownCustomer)
        return { status: 'pending', title: 'Pending ticket' };
      else
        return { status: 'putaway', title: 'Putaway ticket' };
    }
    else{
      if(data.voidedby)
        return { status: 'void', title: 'Void ticket' };
      else
        return { status: 'closed', title: 'Closed ticket' };
    }
  });

  const activityLogs = useMemo(()=>{
    try{
      const logs = [...newTimeLogs];

      logs.push({ personId: momentTrackService.parseId(data.person_url).id, actionText: 'created this ticket.', timestampUnix: data.timestamp });
      if(typeof data.conversion === 'object')
        logs.push({ personId: momentTrackService.parseId(data.conversion.person_url).id, actionText: 'assigned customer.', timestampUnix: data.conversion.timestamp });
      if(typeof data.closedby === 'object')
        logs.push({ personId: momentTrackService.parseId(data.closedby.person_url).id, actionText: 'closed this ticket.', timestampUnix: data.closedby.timestamp });
      if(typeof data.voidedby === 'object')
        logs.push({ personId: momentTrackService.parseId(data.voidedby.person_url).id, actionText: 'voided this ticket.', timestampUnix: data.voidedby.timestamp });
      if(typeof data.timeLog === 'object'){
        Object.keys(data.timeLog).forEach((logKey)=>logs.push({
          personId: momentTrackService.parseId(data.timeLog[logKey].person_url).id,
          actionText: 'clocked in.',
          timestampUnix: logKey
        }));
      }

      logs.sort((a, b)=>a.timestampUnix - b.timestampUnix);

      return logs;
    }
    catch(err){
      notify(new Error('Unable to compile activity logs.'));
      return [];
    }

  }, [data, notify, newTimeLogs]);

  async function handleClockIn() {
    try{
      setHideTimer(true);
      setLoading(true);
      await jobTicketService.clockIn(documentUrl, currentUser.person_id);
      setClockedIn(true);
      setNewTimeLogs(logs=>[...logs, {
        personId: momentTrackService.parseId(currentUser.person_id).id,
        actionText: 'clocked in.',
        timestampUnix: moment().unix()
      }]);
      notify('You have clocked in.');
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleConvertTicket(webFormData) {
    try{
      if(!(await confirm('Are you sure?', 'You are about to convert this ticket from unknown to known customer. This action cannot be undone.')))
        return;

      setLoading(true);
      await jobTicketService.convertTicket(documentUrl, currentUser.person_id, webFormData);
      setTicketStatus({status: 'putaway', title: 'Putaway ticket'});
      setNewTimeLogs(logs=>[...logs, {
        personId: momentTrackService.parseId(currentUser.person_id).id,
        actionText: 'assigned customer.',
        timestampUnix: moment().unix()
      }]);
      notify('Ticket converted!');
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleCloseTicket(locationId, webFormData) {
    try{
      if(!(await confirm('Are you sure?', 'You are about to permanently close this ticket. This action cannot be undone.')))
        return;

      setLoading(true);
      await jobTicketService.closeTicket(documentUrl, locationId, currentUser.person_id, webFormData);
      setTicketStatus({status: 'closed', title: 'Closed ticket'});
      setNewTimeLogs(logs=>[...logs, {
        personId: momentTrackService.parseId(currentUser.person_id).id,
        actionText: 'closed this ticket.',
        timestampUnix: moment().unix()
      }]);
      notify('Ticket closed!');
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleVoidTicket() {
    try{
      if(!(await confirm('Are you sure?', 'You are about to void this ticket. This action cannot be undone.')))
        return;

      setLoading(true);
      await jobTicketService.voidTicket(documentUrl, currentUser.person_id);
      setTicketStatus({status: 'void', title: 'Void ticket'});
      setNewTimeLogs(logs=>[...logs, {
        personId: momentTrackService.parseId(currentUser.person_id).id,
        actionText: 'voided this ticket.',
        timestampUnix: moment().unix()
      }]);
      notify('You can throw this ticket away.');
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    timer.current && timer.current.start();
  }, []);

  // Flags
  const activeTicket = ticketStatus.status === 'pending' || ticketStatus.status === 'putaway';

  if(!currentUser){
    return (
      <Container>
        <Box py={3}>
          <Typography variant="h5" align="center" gutterBottom><b>{ ticketStatus.title }</b></Typography>
          <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>

          <Box mt={5} textAlign="center">
            <Typography color="textSecondary" component="p" gutterBottom>Authentication required.</Typography>
            <Link
              component={RouterLink}
              to={{
                pathname: '/login',
                state: { referrer: currentLocation }
              }}
              underline="none"
            >
              <Button size="large" variant="contained" color="primary">Login</Button>
            </Link>
          </Box>
        </Box>
      </Container>
    );
  }

  if(!ticketOpen){
    return (
      <Container>
        <Box py={3}>
          <Typography variant="h5" align="center" gutterBottom><b>{ ticketStatus.title }</b></Typography>
          <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>

          <Box mt={5} textAlign="center">
            <Button
              size="large"
              variant="contained"
              color="secondary"
              startIcon={<OpenInNewIcon />}
              onClick={()=>setTicketOpen(true)}
              disabled={loading}
            >
              OPEN
            </Button>

            {activeTicket && !hideTimer && (
              <Box mt={8} textAlign="center">
                <Typography gutterBottom color="textSecondary">
                  <CountdownText
                    ref={timer}
                    textBefore="Auto clock in after "
                    textAfter=" seconds"
                    startValue={5}
                    onEnd={handleClockIn}
                  />
                </Typography>

                <Button size="small" onClick={()=>{timer.current.stop(); setHideTimer(true)}}>
                  <u>Stop counter</u>
                </Button>
              </Box>
            )}

            <div><br /></div>

            {activeTicket && !clockedIn && (
              <Button
                size="large"
                variant="outlined"
                startIcon={<ScheduleIcon />}
                onClick={handleClockIn}
                disabled={loading}
              >
                CLOCK IN
              </Button>
            )}

            {clockedIn && (
              <Typography align="center" gutterBottom>You have clocked in!</Typography>
            )}
          </Box>

          {loading && (
            <Box mt={4} textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      {ticketStatus.status === 'pending' && <PendingTicket documentUrl={documentUrl} disabled={loading} onConvertTicket={handleConvertTicket} />}
      {ticketStatus.status === 'putaway' && <PutAwayTicket documentUrl={documentUrl} document={data} disabled={loading} onCloseTicket={handleCloseTicket} />}
      {ticketStatus.status === 'closed' && <ClosedTicket documentUrl={documentUrl} document={data} />}
      {ticketStatus.status === 'void' && <VoidTicket documentUrl={documentUrl} />}

      {loading && (
        <Box mt={2} textAlign="center">
          <CircularProgress />
        </Box>
      )}

      {ticketStatus.status !== 'void' && (
        <Box py={3} textAlign="center">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<AssessmentIcon />}
            onClick={()=>reportDialog.current.openDialog()}
          >
            VIEW REPORT
          </Button>
        </Box>
      )}

      {activeTicket && (
        <Box py={2} mt={4} textAlign="center">
          <Button
            onClick={handleVoidTicket}
            disabled={loading}
            startIcon={<CloseIcon color="error" />}
          >
            <u>Void this ticket</u>
          </Button>
        </Box>
      )}

      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <ActivityLogs
              logs={activityLogs}
              windowHeight={500}
            />
          </Box>
        </Paper>
      </Box>

      <ReportDialog
        ref={reportDialog}
        documentUrl={documentUrl}
        document={data}
        activityLogs={activityLogs}
      />
    </Container>
  );
}

export default JobTicket;
