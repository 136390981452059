
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

import InDocPositionedBox from '../core/components/InDocPositionedBox';
import Loader from '../core/components/Loader';
import GuestTrackLogo from '../assets/images/guest-track-logo.png';
import AdminSetup from './AdminSetup';
import Home from './Home';
import CheckIn from './CheckIn';
import AdminConsole from './admin-console/AdminConsole';

const AuthRoute = ({ isLoggedIn, redirectTo, ...otherProps }) => isLoggedIn ? <Route {...otherProps} /> : <Redirect to={redirectTo} />;
const NoAuthRoute = ({ isLoggedIn, redirectTo, ...otherProps }) => !isLoggedIn ? <Route {...otherProps} /> : <Redirect to={redirectTo} />;

const GuestTrack = ({ match, isLoggedIn, isAuthenticated, loaderText, formLoadingText }) => {
  return (
    <Route>
      <Switch>
        <NoAuthRoute
          path={`${match.path}/setup`}
          component={AdminSetup}
          isLoggedIn={isLoggedIn}
          redirectTo={match.path}
        />
        <AuthRoute
          path={`${match.path}/check-in`}
          component={CheckIn}
          isLoggedIn={isLoggedIn}
          redirectTo={`${match.path}/setup`}
        />
        <AuthRoute
          path={`${match.path}/console`}
          component={AdminConsole}
          isLoggedIn={isAuthenticated}
          redirectTo={`${match.path}/setup`}
        />
        <AuthRoute
          path={match.path}
          exact
          component={Home}
          isLoggedIn={isLoggedIn}
          redirectTo={`${match.path}/setup`}
        />
        <Redirect to={match.path} />
      </Switch>
      
      <InDocPositionedBox style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', pointerEvents: 'none' }}>
        <Container>
          <div style={{ padding: '5px 0', textAlign: 'right', lineHeight: 0 }}>
            <img
              src={GuestTrackLogo}
              height={32}
              alt="Guest track logo"
            />
          </div>
        </Container>
      </InDocPositionedBox>

      <Loader show={!!loaderText} label={loaderText} />
      <Loader show={!!formLoadingText} label={formLoadingText} variant="large" />
    </Route>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: !!state.checkinAuth.access_rights &&
    !!state.checkinAuth.location &&
    !!state.checkinAuth.licence_plate,

    isAuthenticated: !!state.checkinAuth.access_rights,

    loaderText: state.checkinAuth.loading,
    formLoadingText: state.checkinForm.loading
  };
}

export default connect(mapStateToProps)(GuestTrack);
