import axios from "axios";

export interface sendEmailOptions {
  email: string[];
  cc?: string[];
  subject: string;
  body: string;
}

async function sendEmail(options?: sendEmailOptions): Promise<string> {
  const res = await axios.post(
    process.env.REACT_APP_ZETA_API + "communications/sendEmail",
    {
      ...options,
    }
  );
  return res.data.data?.s3_path;
}

export default sendEmail;
