
import React from 'react';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const ClosedTicket = function(props) {
  const {documentUrl, document} = props;

  return (
    <Box py={3}>
      <Box px={4}>
        <Typography variant="h5" align="center" gutterBottom><b>Closed ticket</b></Typography>
        <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          component="div"
          gutterBottom
          align="center"

        >This ticket has been closed</Typography>
      </Box>

      <Divider />

      <Box mt={4}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>PROJECT</TableCell>
                <TableCell>{document.project}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PROJECT ID</TableCell>
                <TableCell>{document.projectID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MODULE</TableCell>
                <TableCell>{document.module}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MODULE ID</TableCell>
                <TableCell>{document.moduleID}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ClosedTicket;
