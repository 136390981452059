
import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  tabsContainer: {
    paddingRight: theme.spacing(1)
  },
  activeTab: {
    backgroundColor: theme.palette.secondary['main'],
    color: theme.palette.secondary['contrastText']
  },
  tableHead: {
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.text.primary
  },
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'inherit',

    '& > svg': {
      verticalAlign: 'bottom'
    }
  }
});

export default styles;
