
import { createContext } from 'react';
import { LayoutContext } from './types';

const layoutContext = createContext<LayoutContext>({
  api: {
    registerAside: ()=>()=>{},
    options: ()=>{}
  },
  options: {}
});

export default layoutContext;
