
import { useState, useEffect } from 'react';
import { getProduct, Product } from '../../system/products';

function useProduct (productId?: number): [Product|null, boolean] {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let active = true;

    (async ()=>{
      try{
        if(!productId)
          return;

        setLoading(true);
        const product = await getProduct(productId);

        if(!active) return;
        setProduct(product);
        setLoading(false);
      }
      catch(err){
        if(!active) return;
        setProduct(null);
        setLoading(false);
      }
    })();

    return ()=>{ active = false; };
  }, [productId]);

  return [product, loading];
};

export default useProduct;
