
import React, { useState } from 'react';
import { firebaseService } from '../../core/system/legacy-services/firebase';
import useNotify from '../../core/hooks/use-notify';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import QrCodeScanner from '../../core/components/QrCodeScanner';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const ExplorerPage = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  async function handleScanResult(scanResult) {
    try{
      setLoading(true);
      const data = await firebaseService.getData(scanResult);
      const dataJson = JSON.stringify(data, undefined, 2);

      setData(dataJson);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Typography variant="h6" align="center" gutterBottom>Scan licence plate</Typography>
        <Typography align="center">
          <QrCodeScanner
            onResult={handleScanResult}
          />
        </Typography>
      </Box>

      {loading && (
        <Box py={2}>
          <CircularProgress />
        </Box>
      )}

      {!loading && !!data && (
        <Box py={2}>
          <pre>
          <Typography>{ data }</Typography>
          </pre>
        </Box>
      )}
    </Container>
  );
};

export default ExplorerPage;
