
import React from 'react';
import moment from 'moment/moment';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiTextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VideoUploader from '../core/components/VideoUploader';
import QrCodeScanner from '../core/components/QrCodeScanner';

const FIREBASE_GET = process.env.REACT_APP_FIREBASE_GET_URL;
const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

/**
 * BEGIN: Helper methods
 */
function makeIds(qty, totalLength, prefix='3hd.us/') {
  return [...Array(qty)].map(() => {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let text = prefix;
    for (let i = 0; i < totalLength - prefix.length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    
    return text;
  });
}

async function fetchPersonInfo(id) {
  const url = FIREBASE_GET;
  const body = { path: `access_rights/${id}` };

  const response = await axios.post(url, body);

  if(!response.data)
    throw new Error('Data not available.');

  return response.data;
}

async function createLitmon(data) {
  const url = process.env.REACT_APP_LITMON_URL;
  const body = data || {};

  const response = await axios.post(url, body);
  return response.data;
}

const TextField = React.forwardRef((props, ref) => <MuiTextField ref={ref} variant="outlined" fullWidth {...props} />);

/**
 * BEGIN: "StepsInput" component
 */
const StepsInput = props => {
  const {value:steps=[], onChange=()=>null, stepsLimit=50, disabled=false} = props;

  const onChangeHandler = index => ev => {
    steps[index] = ev.target.value;
    onChange([...steps]);
  };

  const handleAddStep = () => {
    if(steps.length >= stepsLimit)
      return;

    onChange([...steps, '']);
  };

  const removeStepHandler = index => () => {
    steps.splice(index, 1);
    onChange([...steps]);
  };

  return (
    <div>
      {steps.map((step, index) => (
        <div key={`step-${index+1}`} className="step-row">
          <Grid container direction={(index % 2 === 0) ? 'row' : 'row-reverse'}>
            <Grid item md />
            <Grid item>
              <div className="step-stop">
                <span className="line"/>
                <Fab
                  size="medium"
                  className="remove-btn"
                  onClick={removeStepHandler(index)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon="trash" />
                </Fab>
              </div>
            </Grid>
            <Grid item xs>
              <Box className="step-content">
                <TextField
                  label={`Step #${index + 1}`}
                  value={step}
                  onChange={onChangeHandler(index)}
                  color="secondary"
                  multiline
                  disabled={disabled}
                />
                {/* <Typography variant="body2">
                  STEP description can go here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus porta magna at augue ultricies, vulputate euismod quam elementum.
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
        </div>
      ))}
      <div className="step-row">
        <Grid container>
          <Grid item md />
          <Grid item>
            <div className="step-stop">
              <span className="line"/>
              <Fab color="secondary" className="add-btn" onClick={handleAddStep} disabled={disabled}>
                <FontAwesomeIcon icon="plus" />
              </Fab>
            </div>
          </Grid>
          <Grid item xs />
        </Grid>
      </div>
    </div>
  );
};

/**
 * BEGIN: "LearningInTheMoment" component
 */
const LearningInTheMoment = props => {
  const videoUploaderRef = React.useRef();

  const [personId, setPersonId] = React.useState('');
  const [personData, setPersonData] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [trainingTitle, setTrainingTitle] = React.useState('');
  const [steps, setSteps] = React.useState(['']);
  const [shouldPrint, setShouldPrint] = React.useState(false);
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const loadPersonDataFromId = personId => {
    personId = personId.trim();
    personId = personId.includes('/') ? personId.split("/")[1] : personId;

    setPersonId(personId);
    setErrorMessage('');
    setPersonData(null);
    setSteps(['']);
    setTrainingTitle('');
    setShouldPrint(false);
    setAgreedToTerms(false);
    setUploading(false);

    if(!personId)
      return;

    setLoading(true);

    fetchPersonInfo(personId)
      .then(personData => {
        setPersonData(personData);
        setLoading(false);
      })
      .catch(err => {
        setErrorMessage(err.message);
        setLoading(false);
      })
    ;
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    setUploading(true);

    try{
      const filename = await videoUploaderRef.current.startUpload();

      const data = {
        id: makeIds(1, 39)[0],
        document_type: 'litmon',
        title: trainingTitle,
        video: filename,
        steps: steps,
        user: {
          type: 'erp',
          id: personData.person_url,
          email: personData.email_person
        },
        timestamp: moment().unix()
      };

      if(shouldPrint)
        data.printer_url = personData['preferred_4x6_printer'];

      await createLitmon(data);
      
      let parsedId = data.id.split('/');
      parsedId = parsedId.length === 2 ? parsedId[1] : parsedId[0];
      props.history.replace(parsedId);
    }
    catch(err){
      alert(err.message);
    }
    finally{
      setUploading(false);
    }
  }

  const personDataAvailable = !loading && !!personData;

  return (
    <div className="litmon-page">
      <Container>
        <div className="page-header">
          <Grid container>
            <Grid item xs md={8}>
              <Typography variant="h5">Create a video</Typography>
              <Typography variant="caption" gutterBottom>
                Small description can go here. Vivamus porta magna at ultricies.
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Person URL/ID"
              value={personId}
              onChange={ev => {
                setPersonId(ev.target.value);
                loadPersonDataFromId(ev.target.value);
              }}
              color="secondary"
              disabled={uploading || loading}
            />
            <QrCodeScanner
              label="Scan ID badge"
              className="qr-scanner"
              onResult={result => {
                setPersonId(result);
                loadPersonDataFromId(result);
              }}
              disabled={uploading || loading}
            />

            {personDataAvailable && <Card className="person-card">
              <CardHeader
                avatar={<Avatar>{personData.person_first.charAt(0)}</Avatar>}
                title={`${personData.person_first} ${personData.person_last}`}
                subheader={personData.email_person}
              />
            </Card>}
            {loading && <div className="info-message-container"><CircularProgress /></div>}
            {!personDataAvailable && !!errorMessage && (
              <Typography variant="body1" className="info-message-container">{errorMessage}</Typography>
            )}
          </Grid>
          {personDataAvailable && <Grid item xs={12} md={8}>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Learn how to?"
                    required
                    color="secondary"
                    value={trainingTitle}
                    onChange={ev => setTrainingTitle(ev.target.value)}
                    disabled={uploading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VideoUploader
                    uploadTargetUri={FILE_UPLOAD_URL}
                    ref={videoUploaderRef}
                    className="video-input"
                    disabled={uploading}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>Steps to remember</Typography>
                  <StepsInput value={steps} onChange={steps => setSteps(steps)} disabled={uploading}/>
                </Grid>
                <Grid item xs={12}>
                  {!!personData['preferred_4x6_printer'] && (
                    <FormControlLabel
                      disabled={uploading}
                      control={<Checkbox value={shouldPrint} onClick={() => setShouldPrint(!shouldPrint)}/>}
                      label="Print document"
                    />
                  )}
                  <br />
                  <FormControlLabel
                    disabled={uploading}
                    control={<Checkbox value={agreedToTerms} onClick={() => setAgreedToTerms(!agreedToTerms)}/>}
                    label="I agree to terms and conditions"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className="submit-button"
                    disabled={!agreedToTerms || uploading}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>}
        </Grid>
      </Container>
    </div>
  );
};

export default LearningInTheMoment;
