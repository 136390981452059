export interface IEmailCertTemplate {
  email: string;
  pdf_url: string;
  title: string;
}

const certificate_email_template = (data: IEmailCertTemplate) => {
  return `<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta
      name="viewport"
      content="width=
    , initial-scale=1.0"
    />
    <style>
      * {
        font-family: AvenirNext, ui-sans-serif, system-ui, -apple-system,
          BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
          "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol", "Noto Color Emoji";
      }
      .table_body {
        padding: 2rem;
      }
      .logo_img {
        height: 35px;
        width: 200px;
        display: block;
      }
      .image_container {
        padding: 30px;
      }
      @media only screen and (max-width: 600px) {
        .table_body {
          padding: 20px;
        }
        .logo_img {
            width: 150px;
            height: 30px;
        }
        .image_container {
          padding: 20px;
        }
      }
      .btn {
        background-color: #ec1c24;
        border: none;
        padding: 0.6rem 1.2rem;
        color: white !important;
        text-decoration: none;
        margin: 1rem 0;
        display: inline-block;
      }
      p{
        color: #000000;
      }
    </style>
  </head>
  <body style="margin: 0; padding: 0; height: 100vh">
    <table
      role="presentation"
      style="
        width: 100%;
        border-collapse: collapse;
        border: 0;
        height: 100%;
        border-spacing: 0;
        background: #f3f4f5;
      "
    >
      <tr>
        <td align="center" style="padding: 5%">
          <table
            role="presentation"
            style="
              width: 100%;
              border-collapse: collapse;
              border-spacing: 0;
              text-align: left;
              background-color: white;
              max-width: 800px;
            "
          >
            <tr style="border-bottom: 1px solid #e6e9ec">
              <td align="left" class="image_container">
                <a target="_blank" rel="noopener noreferrer" href="https://www.momenttrack.com/">
                <img
                src="https://www.momenttrack.com/assets/images/mtlogo-dd2883fef4.png"
                alt="logo"
                width="214"
                class="logo_img"
              />
                </a>
              </td>
            </tr>
            <tr>
              <td class="table_body">
                <p
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 33px;
                  "
                >
                  Hello there!,
                </p>
                <p
                  style="
                    font-size: 16px;
                    line-height: 25px;
                    margin-top: 0.5rem;
                  "
                >
                  This is to certify that you have completed the training video on ${
                    data.title
                  }, click the button below to download your certificate of completion.
                </p>
                <a target="_blank" rel="noopener noreferrer" href="${
                  data.pdf_url
                }" class="btn">Download Certificate</a>
              </td>
            </tr>
            <tr>
              <td align="center" style="padding: 32px 0px; background: #ec1c24">
                <table>
                  <tr>
                    <td style="padding: 0">
                      <p
                        style="
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 16px;
                          color: #ffffff;
                        "
                      >
                        ©${new Date().getFullYear()}
                        <a
                        target="_blank" rel="noopener noreferrer"
                          style="color: white"
                          href="https://www.momenttrack.com/"
                          >Momenttrack</a
                        >. All rights reserved.
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
`;
};

export default certificate_email_template;
