
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadSecurityEmail, updateSecurityEmail } from '../../core/redux/actions/admin-console-actions';
import useNotify from '../../core/hooks/use-notify';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import LinearProgress from '../../core/components/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SecuritySettings() {
  const notify = useNotify();
  const dispatch = useDispatch();
  const {loading: {security_alert_email: loading}, security_alert_email} = useSelector(state=>state.adminConsole);
  const [editMode, setEditMode] = useState(false);
  const [securityEmail, setSecurityEmail] = useState('');

  const reload = useCallback(()=>{
    dispatch(loadSecurityEmail())
      .catch(notify)
    ;
  }, [notify, dispatch]);

  useEffect(()=>{
    if(security_alert_email === null)
      reload();
  }, [reload, security_alert_email]);

  const handleUpdate = useCallback(async ev => {
    ev.preventDefault();
    if(!securityEmail.trim())
      return;

    dispatch(updateSecurityEmail(securityEmail))
      .then(message=>{
        notify(message);
        setEditMode(false);
      })
      .catch(notify)
    ;
  }, [notify, dispatch, securityEmail]);

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item><Typography variant="h5">Security Settings</Typography></Grid>
        <Grid item><IconButton onClick={reload}><FontAwesomeIcon icon="sync" /></IconButton></Grid>
      </Grid>
      <LinearProgress show={loading} />
      <div style={{ marginBottom: 16 }} />
      
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <div className="setting-section">
            <Typography variant="h6" className="title">Security email</Typography>
            <Typography gutterBottom>{ security_alert_email || '' }</Typography>
            {!loading && !editMode && (
              <Button variant="outlined" onClick={()=>{setEditMode(true)}}>Edit</Button>
            )}
            {editMode && (
              <form onSubmit={handleUpdate}>
                <Card>
                  <CardContent>
                    <TextField
                      placeholder="Type new email..."
                      type="email"
                      value={securityEmail}
                      onChange={ev=>setSecurityEmail(ev.target.value)}
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      disabled={!!loading}
                    />
                  </CardContent>
                  <Divider />
                  <CardActions >
                    <Button
                      type="submit"
                      color="secondary"
                      size="small"
                      disabled={!!loading}
                    >Update</Button>
                  </CardActions>
                </Card>
              </form>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
