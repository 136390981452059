import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { EEmbeddedContentDialogComponent } from "./types";

const EmbeddedContentDialog: EEmbeddedContentDialogComponent = (
  { title = "", onCompleted, specificBtn = false, children },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [srcUri, setSrcUri] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      openDialog: async (contentSrc) => {
        try {
          let srcUri: string;

          setErrMsg("");
          setSrcUri("");
          setLoading(true);
          setOpen(true);
          if (typeof contentSrc === "function") srcUri = await contentSrc();
          else srcUri = await contentSrc;

          setSrcUri(srcUri);
          setLoading(false);
          if (onCompleted) onCompleted(srcUri);
        } catch (err: any) {
          setErrMsg(err.message);
          setLoading(false);
        }
      },
      loadingContent: loading,
    }),
    // eslint-disable-next-line
    []
  );

  const handleBtnClick = () => {
    setOpen(false);
  };

  const handleDialogClose = () => {
    if (loading) return;
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>{title}</DialogTitle>
      {loading && <LinearProgress />}
      <Box>
        {!!errMsg && (
          <Box p={2}>
            <Typography color="error">{errMsg}</Typography>
          </Box>
        )}
        {!!srcUri && (
          <iframe
            title={title}
            src={srcUri}
            width="100%"
            style={{ border: "none", height: "70vh" }}
          />
        )}
      </Box>
      <DialogActions>
        {!specificBtn && (
          <Button disabled={loading} onClick={handleBtnClick}>
            Ok
          </Button>
        )}
        {specificBtn && children}
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(EmbeddedContentDialog);
