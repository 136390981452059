import React, {useState, useEffect} from "react";
import {ProductionOrderDocumentTemplateProps} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import StackedBarChart from "../../../core/components/PieChart/StackedBarChart";
import {makeStyles} from "@material-ui/core/styles";
import {cardStyles} from "./style";
import {Popover, Button} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import useCopy from "../../../core/hooks/useCopy";
import useNotify from "../../../core/hooks/use-notify";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useCardStyles = makeStyles(cardStyles);
export const mock_order_status = [
  {
    name: "Request for quote",
    id: "1223",
    Quantity: "4",
    icon: "/images/manufacture-steps/quote-icon.png",
    color: "#FF0000",
  },
  {
    name: "Purchase Order received",
    id: "1222",
    Quantity: "3",
    icon: "/images/manufacture-steps/send-icon.png",
    color: "#FF3300",
  },
  {
    name: "Surface mount",
    id: "1221",
    Quantity: "5",
    icon: "/images/manufacture-steps/circuit-icon.png",
    color: "#FF6600",
  },
  {
    name: "Automated optical inspection",
    id: "1224",
    Quantity: "7",
    icon: "/images/manufacture-steps/microscope-icon.png",
    color: "#FF9900",
  },
  {
    name: "Through Hole",
    id: "1225",
    Quantity: "5",
    icon: "/images/manufacture-steps/drill-icon.png",
    color: "#FFCC00",
  },
  {
    name: "Assembly and build",
    id: "1226",
    Quantity: "2",
    icon: "/images/manufacture-steps/settings-icon.png",
    color: "#FFFF00",
  },
  {
    name: "Cables and wire assembly",
    id: "1227",
    Quantity: "2",
    icon: "/images/manufacture-steps/cable-icon.png",
    color: "#CCFF00",
  },
  {
    name: "Testing",
    id: "1228",
    Quantity: "2",
    icon: "/images/manufacture-steps/file-icon.png",
    color: "#99FF00",
  },
  {
    name: "Inspection",
    id: "1229",
    Quantity: "6",
    icon: "/images/manufacture-steps/search-icon.png",
    color: "#69eb13",
  },
  {
    name: "Coating",
    id: "1230",
    Quantity: "1",
    icon: "/images/manufacture-steps/brush.png",
    color: "#3ac517",
  },
  {
    name: "Pack and ship",
    id: "1231",
    Quantity: "3",
    icon: "/images/manufacture-steps/truck.png",
    color: "#1c7c1c",
  },
];

const ProductionOrderDemoTemplate: React.FC<
  ProductionOrderDocumentTemplateProps
> = props => {
  const cardClasses = useCardStyles();

  const [quantityPieSeries, setquantityPieSeries] = useState<{
    series: any[];
    total: number;
  }>();
  const totalAssigned = mock_order_status.reduce((cum, cur) => {
    return cum + parseInt(cur.Quantity);
  }, 0);
  const requestedQty = totalAssigned + 2;
  useEffect(() => {
    let data = {
      series: [
        {
          name: "Not started",
          data: [requestedQty - totalAssigned],
          color: "#fff",
        },
        ...mock_order_status
          .map((stationName, i) => {
            return {
              name: stationName.name,
              data: [parseInt(stationName.Quantity)],
              color: stationName.color,
            } as any;
          })
          .reverse(),
      ],
      total: totalAssigned,
    };
    setquantityPieSeries(data);
  }, [requestedQty, totalAssigned]);

  const handleGetSvg = (svg: string) => {
    // setChartSvg(svg);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const notify = useNotify();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "qr-popover" : undefined;

  const shareFn = async (): Promise<void> => {
    if (!navigator.share) {
      // Browser doesn't support the Web Share API
      notify(
        "Your browser doesn't support the Web Share API. Please try a different browser."
      );
      return;
    }

    try {
      await navigator.share({
        title: `Momenttrack order details`,
        text: `Check out this production order on Momenttrack`,
        url: `${window.location.origin}/production-order-demo`,
      });
    } catch (err: any) {
      if (err.name === "AbortError") {
        // Permission denied
        notify(
          "You have disabled permission to share. Please enable it to use this feature."
        );
      } else {
        // Other error occurred
        notify(
          "An error occurred while trying to share. Please try again later."
        );
      }
    }
  };

  const {copied, copyAction} = useCopy(
    `${window.location.origin}/production-order-demo`
  );

  const [showButton, setShowButton] = useState(false);

  const LocationCard = ({
    station,
    location_number,
  }: {
    station: (typeof mock_order_status)[0];
    location_number: number;
  }) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        // md={4}
        // lg={4}
      >
        <Card className={cardClasses.card_root}>
          <CardHeader
            className={cardClasses["card-header"]}
            title={`${location_number}. ${station.name.split("_").join(" ")}`}
            titleTypographyProps={{
              variant: "body1",
              style: {textTransform: "capitalize"},
            }}
            subheaderTypographyProps={{color: "textSecondary"}}
          />
          <CardContent>
            <div className={cardClasses["icon-wrap"]}>
              <div>
                <Typography variant="h4">{station.Quantity}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Item
                  {parseInt(station.Quantity) !== 1 ? "s" : ""}
                </Typography>
              </div>
              <img
                className={cardClasses.icon}
                src={station.icon}
                alt="brush"
              />
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box py={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper style={{height: "100%"}}>
                <Box p={2} display="flex" bgcolor="grey.200">
                  <Box sx={{mr: "1rem"}}>
                    <Typography variant="h5">Order Status</Typography>
                    <Typography variant="body1">
                      {process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...
                      {"d8fe275652ce48639e4fff02bbc85752".slice(-5)}
                    </Typography>
                  </Box>
                  <Box
                    className={cardClasses.qr_box}
                    aria-haspopup="true"
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{ml: "auto"}}
                  >
                    <img
                      alt="place qr code"
                      src={`https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/production-order-demo`}
                      style={{width: "0.75in"}}
                    />
                    <span className={cardClasses.qr_share}>
                      <ShareIcon fontSize={"small"} />
                    </span>
                  </Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={cardClasses.popover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Button
                      onClick={() => copyAction()}
                      startIcon={<FileCopyIcon />}
                    >
                      {copied ? "Copied" : "Copy URL"}
                    </Button>
                    <Button onClick={shareFn} startIcon={<ShareIcon />}>
                      Share URL
                    </Button>
                  </Popover>
                </Box>

                <Box p={2} mb={2} style={{textAlign: "center"}}>
                  <Typography variant="overline" align="center">
                    Progress To Completion
                  </Typography>
                  <StackedBarChart
                    options={{
                      xAxis: {
                        categories: ["Quantity"],
                        visible: false,
                      },
                      yAxis: {
                        ceiling: requestedQty,
                        title: "",
                      },
                      legend: {
                        // reversed: true,
                        enabled: false,
                      },
                    }}
                    series={quantityPieSeries?.series}
                    getSvg={handleGetSvg}
                  />
                  <Typography variant="overline" align="center">
                    Completed ={" "}
                    <span className={cardClasses.completed_indicator}></span>
                  </Typography>
                </Box>

                <Divider />

                <Box p={2} mb={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      // textAlign: "center",
                    }}
                  >
                    Details
                  </Typography>
                  <Divider style={{margin: "0.3rem 0"}} />
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid item>
                      <Typography variant="overline" color="textSecondary">
                        Part #
                      </Typography>
                      <Typography variant="body1">1234</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="overline" color="textSecondary">
                        Description
                      </Typography>
                      <Typography variant="body1">
                        Main PCBA board version 2.3
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        // textAlign: "center",
                      }}
                    >
                      Quantities
                    </Typography>
                    <Divider style={{margin: "0.3rem 0"}} />
                    <Grid
                      className={cardClasses.details_grid}
                      container
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Started
                        </Typography>
                        <Typography variant="body1">
                          {mock_order_status[0].Quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Shipped
                        </Typography>
                        <Typography variant="body1">
                          <span>
                            {
                              mock_order_status[mock_order_status.length - 1]
                                .Quantity
                            }
                          </span>{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Total Orders
                        </Typography>
                        <Typography variant="body1">{requestedQty}</Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        // textAlign: "center",
                      }}
                    >
                      Start Date
                    </Typography>
                    <Divider style={{margin: "0.3rem 0"}} />
                    <Grid
                      className={cardClasses.details_grid}
                      container
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Expected
                        </Typography>
                        <Typography variant="body1">12-Sep-2023</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Revised
                        </Typography>
                        <Typography
                          variant="body1"
                          className={cardClasses.info_tooltip}
                        >
                          <span>16-Sep-2023</span>{" "}
                          <Tooltip
                            classes={{tooltip: cardClasses.tooltip}}
                            arrow
                            placement="top"
                            title="Revised due to delayed fix of our machine"
                          >
                            <HelpIcon />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Actual
                        </Typography>
                        <Typography variant="body1">12-Sep-2023</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        // textAlign: "center",
                      }}
                    >
                      Ship Date
                    </Typography>
                    <Divider style={{margin: "0.3rem 0"}} />
                    <Grid
                      className={cardClasses.details_grid}
                      container
                      spacing={2}
                    >
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Expected
                        </Typography>
                        <Typography variant="body1">12-Sep-2023</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Revised{" "}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={cardClasses.info_tooltip}
                        >
                          <span>16-Sep-2023</span>{" "}
                          <Tooltip
                            classes={{tooltip: cardClasses.tooltip}}
                            arrow
                            placement="top"
                            title="Revised due to delayed fix of our machine"
                          >
                            <HelpIcon />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="overline" color="textSecondary">
                          Actual
                        </Typography>
                        <Typography variant="body1">12-Sep-2023</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} sm>
              <Grid container spacing={2}>
                <LocationCard
                  station={mock_order_status[0]}
                  location_number={1}
                />

                <Grid
                  item
                  xs={12}
                  sm={6}
                  onClick={() => {
                    setShowButton(prev => !prev);
                  }}
                >
                  <div className={cardClasses.showMoreButton}>
                    <div>
                      Step 2 <MoreHorizIcon /> Step{" "}
                      {mock_order_status.length - 1}
                      <ArrowDropDownIcon
                        style={{
                          marginLeft: "auto",
                          transform: showButton
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>
                    <div>
                      Quantity{" "}
                      {mock_order_status
                        .slice(1, mock_order_status.length - 1)
                        .reduce((cum, cur) => {
                          return cum + parseInt(cur.Quantity);
                        }, 0)}{" "}
                      out of {requestedQty} items
                    </div>
                  </div>
                </Grid>

                {showButton &&
                  mock_order_status
                    .slice(1, mock_order_status.length - 1)
                    .map((station, i) => (
                      <LocationCard location_number={i + 2} station={station} />
                    ))}

                <LocationCard
                  location_number={mock_order_status.length}
                  station={mock_order_status[mock_order_status.length - 1]}
                />
              </Grid>
            </Grid>
          </Grid>
          <div id="bottom"></div>
          <Fab
            style={{
              position: "fixed",
              bottom: "1rem",
              right: "1rem",
            }}
            color="primary"
            variant="extended"
            component="a"
            href="#bottom"
          >
            <KeyboardArrowDownIcon />
          </Fab>
        </Box>
      </Container>
    </>
  );
};

export default ProductionOrderDemoTemplate;
