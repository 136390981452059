import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLayout } from "@momenttrack/react/base/Layout";
import {
	lookupLicensePlate,
	lookupLifetimeEngagement,
} from "../../core/system/license-plates";
import PublicLicensePlateTemplate from "../LicensePlateTemplate/PublicLicensePlateTemplate";
import { IS_DEV_MODE } from "../../core/system/utils";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";

type Props = {
	qrCode: string;
};

const CustomerEngagementTemplate: React.FC<Props> = props => {
	const { qrCode } = props;
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [currentUser] = useAuthentication();
	const authenticated = currentUser !== null;
	const [qrNotFound, setqrNotFound] = useState("");
	useLayout({
		noHeader: true,
		noFooter: true,
	});

	useEffect(() => {
		const timer = setTimeout(async () => {
			let lifetimeResult, lookupResult;
			try {
				setLoading(true);

				lookupResult = await lookupLicensePlate(qrCode);
				lifetimeResult = await lookupLifetimeEngagement(qrCode);

				if (lookupResult.redirect_url && !authenticated) {
					const redirectUrl = lookupResult.redirect_url.startsWith("http")
						? lookupResult.redirect_url
						: "https://" + lookupResult.redirect_url;
					window.location.replace(redirectUrl);
				} else {
					const redirectUrl = `https://${
						IS_DEV_MODE ? "productiontrack-dev" : "productiontrack"
					}.momenttrack.com/customer-template/?qr=${props.qrCode}`;
					window.location.replace(redirectUrl);
				}
			} catch (err) {
				console.log(err);
				if (lookupResult?.redirect_url && !authenticated) {
					const redirectUrl = lookupResult.redirect_url.startsWith("http")
						? lookupResult.redirect_url
						: "https://" + lookupResult.redirect_url;
					window.location.replace(redirectUrl);
				} else {
					setqrNotFound("true");
				}
			} finally {
				setLoading(false);
				if (lifetimeResult) setqrNotFound("false");
			}
		}, 100); // Debounce redirection by 100ms

		return () => clearTimeout(timer); // Cleanup
	}, [qrCode, history, props.qrCode, authenticated]);

	if (qrNotFound === "true")
		return <PublicLicensePlateTemplate licensePlateId={props.qrCode} />;
	return (
		<Box
			lineHeight={0}
			height="100%"
			style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			{loading && (
				<Box textAlign="center" p={5}>
					<CircularProgress size={24} />
					<p style={{ marginTop: "10px" }}>Searching for customer template...</p>
				</Box>
			)}
		</Box>
	);
};

export default CustomerEngagementTemplate;
