
import { createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  contentContainer: {
    flex: 1
  },
  logo: {
    maxHeight: 40
  }
});

export default styles;
