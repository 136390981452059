
import React, { Fragment } from 'react';
import { MainSideNavProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthentication } from '../core/providers/AuthenticationProvider';
import Layout, { Header, Content, Footer } from '@momenttrack/react/base/Layout';
import PersonBadge from '../core/components/PersonBadge';
import { NavLink, Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import styles from './styles';
import momenttrackLogo from '../assets/images/logo.png';

const navItems = [
  {id: 0, label: 'Lookup QR codes', icon: 'pageview', path: '/'},
  {id: 1, label: 'Learning in the moment', icon: 'school', path: '/learning-in-the-moment', children: []},
  {id: 2, label: 'Guest track', icon: 'badge', path: '/guest-track', children: []}
];

const useStyles = makeStyles(styles);

const MainSideNav: React.FC<MainSideNavProps> = props => {
  const [person,,{ logout }] = useAuthentication();
  const currentLocation = useLocation();
  const classes = useStyles();

  return (
    <Paper className={classes.root}
      square
      elevation={4}
    >
      <Layout>
        <Header mb={4}>
          <Toolbar>
            <img src={momenttrackLogo} alt="Momenttrack logo" className={classes.logo} />
          </Toolbar>
          <Divider light />
        </Header>
        
        <Content>
          <List onClick={()=>props.onItemClick && props.onItemClick()}>
            {navItems.map(item=>(
              <Fragment key={''+item.id}>
                <ListItem button component={NavLink} to={item.path} exact activeClassName={classes.activeNavListItem}>
                  <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
                {/* {item.children.map(childItem=>(
                  <ListItem key={childItem.id} button component={NavLink} to={childItem.path} exact activeClassName={classes.activeNavListItem}>
                    <ListItemIcon><Icon>{childItem.icon}</Icon></ListItemIcon>
                    <ListItemText inset={!childItem.icon} primary={childItem.label} />
                  </ListItem>
                ))} */}
              </Fragment>
            ))}
          </List>
        </Content>
        <Footer>
          {!!person && (
            <>
              <Box px={2} py={1}>
                <PersonBadge
                  {...person}
                />
              </Box>
              <Box px={2} py={1} mb={1}>
                <Button
                  onClick={logout}
                  size="small"
                  color="primary"
                  startIcon={<Icon>logout</Icon>}
                
                >Logout</Button>
              </Box>
            </>
          )}

          {!person && (
            <Box px={2} py={1} mb={1}>
              <Link component={RouterLink} underline="none" to={{ pathname: '/login', state: { referrer: currentLocation } }}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon>login</Icon>}
                >
                  Login
                </Button>
              </Link>
            </Box>
          )}
          
          <Divider light />
          <Box px={2} py={1}>
            <Typography
              color="textSecondary"
              variant="caption"
            
            >&copy; {(new Date()).getFullYear()} - Moment track</Typography>
          </Box>
        </Footer>
      </Layout>
    </Paper>
  );
};

export default MainSideNav;
