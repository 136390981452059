
import React from 'react';
import TextField from '@material-ui/core/TextField';

const FormSelectInput = React.forwardRef(({label, required, children, ...otherProps}, ref)=>{
  return (
    <div className="form-select-input">
      {!!label && <label>{label}{required && ' *'}</label>}
      <TextField
        select
        ref={ref}
        variant="outlined"
        fullWidth
        color="secondary"
        required={required}

        {...otherProps}
      >
        {children}
      </TextField>
    </div>
  );
});

export default FormSelectInput;
