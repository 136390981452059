
import apiClient from './api-client';
import algoliaSearch from 'algoliasearch/lite';

const algoliaApiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaIndexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME;
const algoliaClient = algoliaSearch(algoliaAppId, algoliaApiKey);
const algoliaIndex = algoliaClient.initIndex(algoliaIndexName);

function makePath(partial) {
  return 'guest_track.' + partial;
}

export async function getLocation(location_id) {
  const response = await apiClient.post(makePath('get_location'), {
    location: location_id
  });

  return response.data;
}

export async function getLicencePlate(licence_plate_id) {
  const response = await apiClient.post(makePath('get_licence_plate'), {
    licence_plate: licence_plate_id
  });

  return response.data;
}

export async function getCompanyLogo(company_code) {
  const response = await apiClient.post(makePath('get_logo'), { company_code });
  return response.data;
}

export async function getEmployee(person_url) {
  const response = await apiClient.post(makePath('get_employee'), {
    person_url
  });

  return response.data;
}

export async function getEmployees(company_code) {
  const response = await apiClient.post(makePath('employee_list'), { company_code });
  return response.data.employee_list || [];
}

export async function searchEmployees(query, company_code) {
  try {
    if(!query)
      return [];

    const response = await algoliaIndex.search(query, {
      filters: `company_code:${company_code}`,
      hitsPerPage: 5
    });

    return response.hits.map(emp=>({
      ...emp,
      full_name: `${emp.person_first} ${emp.person_last}`,
      person_url: `3hd.us/${emp.person_id}`,
      person_picture: emp.picture_person || '',
      id: emp.person_id
    }));
  }
  catch(err){
    throw new Error(err.message);
  }
}

export async function createGuest(email, company_code) {
  const response = await apiClient.post(makePath('get_guest'), {
    email,
    company_code
  });

  return response.data.guest_data;
}

export async function getGuest(person_url, company_code) {
  const response = await apiClient.post(makePath('get_guest'), {
    person_url,
    company_code
  });

  return response.data.guest_data;
}

export async function checkIn(details, company_code) {
  const response = await apiClient.post(makePath('check_in'), {
    ...details,
    company_code
  });

  return response.data.message;
}

export async function checkOut(guest_id, company_code) {
  const response = await apiClient.post(makePath('check_out'), {
    guest_id,
    company_code
  });

  return response.data.message;
}

export async function getCurrentGuests(company_code) {
  const response = await apiClient.post(makePath('current_guests'), { company_code });
  return response.data.current_guests;
}

export async function getNdas(company_code) {
  const response = await apiClient.post(makePath('get_ndas'), { company_code });
  return response.data.ndas || {default: ''};
}

export async function getEmailTemplates(company_code) {
  const response = await apiClient.post(makePath('email_templates'), { company_code });
  return response.data.email_templates;
}

export async function getSecurityAlertEmail(company_code) {
  const response = await apiClient.post(makePath('security_alert_email'), { company_code });
  return response.data.security_alert_email;
}

export async function updateNdas(data, company_code) {
  const response = await apiClient.post(makePath('update_ndas'), {
    data,
    company_code
  });

  return response.data.message;
}

export async function updateEmailTemplates(data, company_code) {
  const response = await apiClient.post(makePath('update_email_templates'), {
    data,
    company_code
  });

  return response.data.message;
}

export async function updateSecurityAlertEmail(email, company_code) {
  const response = await apiClient.post(makePath('update_security_alert_email'), {
    email,
    company_code
  });

  return response.data.message;
}