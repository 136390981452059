
import React from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { logout } from '../core/redux/actions/checkin-auth-actions';
import { checkout } from '../core/redux/actions/checkin-form-actions';
import useNotify from '../core/hooks/use-notify';
import RatioedButton from '../core/components/RatioedButton';
import QrCodeScanner from '../core/components/QrCodeScanner';

const Home = ({ history, loading, logout, checkout, companyProfile }) => {
  const notify = useNotify();
  const checkoutScanner = React.useRef();

  function handleLogout(personId) {
    logout(personId)
      .then(()=>{
        notify('Successfully logged out!');
        history.replace('/guest-track/login');
      })
      .catch(err=>{
        notify(err.message);
      })
    ;
  }

  const handleCheckinClick = React.useCallback(()=>{
    history.push('/guest-track/check-in');

  }, [history]);


  function handleCheckout(person_url) {
    checkout(person_url)
      .then(()=>{
        notify('Check out success!')
      })
      .catch(err=>{
        notify(err.message);
      })
    ;
  }

  const hasLogo = !!companyProfile.company_logo;

  return (
    <div className="guest-track-home">
      <Container maxWidth="md">
        <div className="header">
          {hasLogo ? (
            <img src={companyProfile.company_logo} className="company-logo" alt="Company Logo"/>
          ) : (
            <Typography variant="h2" className="the-title">Welcome!</Typography>
          )}
        </div>
      </Container>
      <Container maxWidth="sm">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <RatioedButton
              className="big-btn"
              ratio="16:9"
              fullWidth
              variant="outlined"
              size="large"
              color="secondary"
              style={{ backgroundColor: '#ffffff' }}
              disabled={!!loading}
              onClick={handleCheckinClick}
            >Check In</RatioedButton>
          </Grid>
          <Grid item xs={6}>
            <RatioedButton
              className="big-btn"
              ratio="16:9"
              fullWidth
              variant="outlined"
              size="large"
              disabled={!!loading}
              onClick={()=>checkoutScanner.current.openScanner()}
            >Check Out</RatioedButton>
            <QrCodeScanner
              ref={checkoutScanner}
              facingMode="user"
              onResult={handleCheckout}
              hidden
              disabled={!!loading}
            />
           
          </Grid>
        </Grid>

        <div className="other-actions">
          <QrCodeScanner
            label="logout!"
            facingMode="user"
            onResult={handleLogout}
            disabled={!!loading}
          />
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    companyProfile: state.checkinAuth.companyProfile || {},
    loading: state.checkinAuth.loading || state.checkinForm.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: (personId)=>dispatch(logout(personId)),
    checkout: person_url=>dispatch(checkout(person_url))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
