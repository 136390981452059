
import React, { useState, useEffect } from 'react';
import { jobTicketService } from '../../../../core/system/legacy-services/job-ticket';
import useNotify from '../../../../core/hooks/use-notify';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import DynamicWebForm from '../../../../core/components/DynamicWebForm';

const PendingTicket = function(props) {
  const {
    documentUrl,
    onConvertTicket = ()=>{},
    disabled = false
  } = props;
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);

  useEffect(()=>{
    (async ()=>{
      try{
        setLoading(true);
        const activity = await jobTicketService.getConvertActivity();
        setActivity(activity);
      }
      catch(err){
        notify(err);
      }
      finally{
        setLoading(false);
      }
    })();
  }, [ notify ]);

  return (
    <Box py={3}>
      <Box px={4}>
        <Typography variant="h5" align="center" gutterBottom><b>Pending ticket</b></Typography>
        <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          component="div"
          gutterBottom
          align="center"

        >Choose the customer from the dropdown below and number of tickets to print</Typography>
      </Box>

      {!!activity && (
        <Box mt={4}>

          <DynamicWebForm
            formFields={activity.required.web_form_data || []}
            disabled={loading || disabled}
            onSubmit={onConvertTicket}
          >
            <Box textAlign="center" py={1}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<CheckIcon />}
                disabled={loading || disabled}
              >
                CONVERT TICKET
              </Button>
            </Box>
          </DynamicWebForm>
        </Box>
      )}

      {loading && (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default PendingTicket;
