import {createStyles, Theme} from "@material-ui/core/styles";

export const cardStyles = (theme: Theme) =>
  createStyles({
    order_details_grid_wrap: {
      paddingBottom: "1.5rem",
      "& .MuiGrid-item": {
        paddingBottom: 0,
      },
    },
    qr_box: {
      position: "relative",
      cursor: "pointer",
    },
    popover: {
      "& .MuiPopover-paper": {
        borderRadius: "0.3rem",
        padding: "0.3rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.2rem",
      },
    },
    qr_share: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "rgb(239, 73, 79)",
      borderRadius: "50%",
      width: 28,
      height: 28,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
    card_root: {
      borderRadius: "0.3rem",
      boxShadow: "none",
      cursor: "pointer",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "card-header": {
      flexDirection: "row-reverse",
      "& .MuiTypography-h6": {
        fontSize: "1.2rem",
        textTransform: "capitalize",
      },
      "& .MuiCardHeader-avatar": {
        marginRight: 0,
        padding: "0.2rem",
        display: "flex",
        borderRadius: "0.3rem",
      },
    },
    card_content: {
      marginTop: "auto",
    },
    icon: {
      width: 40,
      height: 40,
      marginLeft: "auto",
    },
    "icon-wrap": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chart_grid_wrap: {
      flexGrow: 2,
      "& .highcharts-container": {
        width: "100%",
        "& svg": {
          width: "100%",
        },
      },
    },
    info_tooltip: {
      display: "flex",
      alignItems: "center",
      gap: "0.2rem",
      "& svg": {
        fontSize: "1.2rem",
        cursor: "pointer",
      },
    },
    tooltip: {
      background: "white",
      padding: "1rem",
      fontSize: "0.9rem",
      color: "black",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    completed_indicator: {
      background: "#1c7c1c",
      width: 10,
      height: 10,
      // borderRadius: "50%",
      display: "inline-block",
    },
    showMoreButton: {
      fontSize: "1.1rem",
      cursor: "pointer",
      borderRadius: "0.3rem",
      background: "white",
      padding: "0.5rem",

      "& > div:first-child": {
        fontWeight: "bold",
        marginBottom: "1rem",
        display: "flex",
        alignItems: "center",
      },
    },
    details_grid: {
      flexWrap: "wrap",

      "& .MuiTypography-overline": {
        width: "max-content",
        display: "inline-block",
      },
    },
    details_table: {
      width: "100%",
      marginBottom: "1rem",
      "& td": {
        padding: "1rem 1rem 0rem 0",
      },
      "& .MuiTypography-body1": {
        fontWeight: 600,
      },
      "& .td-label": {
        width: "30%",
        "@media (max-width: 760px)": {
          width: "50%",
        },
      },
    },
    details_wrap: {
      display: "flex",
      gap: "0.75rem",
      width: "60%",
      margin: "1rem 0",
      flexWrap: "wrap",
      "& > div": {
        flex: 1,
        minWidth: "45%",
      },
      "@media (max-width: 760px)": {
        width: "100%",
      },
    },
  });
