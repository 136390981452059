
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary['light'],
    color: theme.palette.primary['contrastText'],
    border: `2px solid #ffffff`,
    boxShadow: `rgba(0,0,0,0.5) 0 0 8px -2px`,
    marginRight: theme.spacing(1)
  }
});

export default styles;
