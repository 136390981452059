import apiClient from "../ordertrack-client";
import {
  Product,
  ProductReportApiResponse,
  ProductReport,
  LicensePlate,
  ProductInputData,
} from "./types";

export async function getAllProducts(): Promise<Product[]> {
  const res = await apiClient.get("/products");
  return res.data.data;
}

export async function getProduct(productId: number): Promise<Product> {
  const res = await apiClient.get(`/products/${productId}`);
  return res.data.data;
}

export async function partNumberLookup(part_number: string): Promise<Product> {
  const res = await apiClient.get("/products/lookup", {
    params: {part_number},
  });

  return res.data.data;
}

export const getProductReport = async (
  productId: number
): Promise<ProductReport> => {
  const res = await apiClient.get<ProductReportApiResponse>(
    `/products/${productId}/report`
  );
  const reportDataMap: {[key: string]: LicensePlate[]} = {};
  res.data.data.license_plates.forEach(lp => {
    const lpCollection = reportDataMap[lp.location_id.toString()] || [];
    lpCollection.push(lp);
    reportDataMap[lp.location_id.toString()] = lpCollection;
  });

  return {
    name: res.data.data.name,
    description: res.data.data.description,
    part_number: res.data.data.part_number,
    data: Object.keys(reportDataMap).map(k => ({
      stationName: k,
      licensePlates: reportDataMap[k],
    })),
  };
};

export const createProduct = async (
  data: ProductInputData
): Promise<Product> => {
  const res = await apiClient.post("/products", data);
  return res.data.data;
};

export const updateProduct = async (
  productId: number,
  updatedData: ProductInputData
): Promise<Product> => {
  const res = await apiClient.put(`/products/${productId}`, updatedData);
  return res.data.data;
};
