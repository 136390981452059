import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { sendapimouser } from '../core/system/legacy-services/digikey/diginode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useAuthentication } from '../core/providers/AuthenticationProvider';

const Mouser = function() {
    const [digikeyqr, setDigikeyqr] = useState(null)
    const [loading, setLoading] = useState(false);
    const currentLocation = useLocation();
    const [currentUser] = useAuthentication();

      async function sendtoapi () {
      try{
       setLoading(true);
       const post = await sendapimouser(digikeyqr, currentUser);
       console.log(post.data)
       if(post.data !== "failed"){
        setDigikeyqr('')
       }
       else if(post.data === 'failed'){
       
       }
      }
      catch(err){ 
      }
      finally{
       setLoading(false);
      }
      }

      if(!currentUser)
        return (
          <Box p={3} textAlign="center">
              <Typography gutterBottom>Please verify your identity.</Typography>
              <Link component={RouterLink} to={{ pathname: '/login', state: { referrer: currentLocation } }} underline="none">
                  <Button variant="contained" color="primary">Login</Button>
              </Link>
          </Box>
        );

    return (
        <Container>
            <Box pt={3}>
            <Paper>
                <Box p={2} bgcolor="grey.200">
                <Typography variant="h6">Mouser</Typography>
                <Typography variant="subtitle2" color="textSecondary">Receipt of Electronics with Double data matrix</Typography>
                
                <Box p={3}> 
                <TextField
                fullWidth
                variant="outlined"
                label="Mouser"
                required
                multiline
                value={digikeyqr}
                onChange={e => setDigikeyqr(e.target.value)}
                />   
                </Box>

                <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          disabled={loading}
                          onClick={sendtoapi}
                        >
                          TRANSACT ACTIVITY
                          {loading && (
                            <CircularProgress size={22} />
                            )}
                </Button>

                </Box>

            </Paper>
            </Box>
        </Container>
    )
}

export default Mouser
