
import React from 'react';
import Box from '@material-ui/core/Box';

const InDocPositionedBox = props => {
  const inDocContainer = React.useRef();
  const box = React.useRef();

  React.useEffect(()=>{
    switch(props.position){
      case 'fixed':
        inDocContainer.current.style.paddingTop = box.current.offsetHeight + 'px';
        break;
      default:
        break;
    }

  }, [props.position]);

  return (
    <div ref={inDocContainer}>
      <Box ref={box} {...props} />
    </div>
  );
};

export default InDocPositionedBox;
