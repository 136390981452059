import React, {useState, useEffect, useMemo} from "react";
import {ProductionOrderDocumentTemplateProps} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import {
  getPublicProductionOrder,
  PublicViewOrder,
} from "../../../core/system/production-orders";
import useNotify from "../../../core/hooks/use-notify";
import StackedBarChart from "../../../core/components/PieChart/StackedBarChart";
import {makeStyles} from "@material-ui/core/styles";
import {cardStyles} from "./style";
import {useHistory} from "react-router-dom";
import DateTag from "../../../core/components/DateTag";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import useCopy from "../../../core/hooks/useCopy";

const useCardStyles = makeStyles(cardStyles);
const PublicProductionOrderTemplate: React.FC<
  ProductionOrderDocumentTemplateProps
> = props => {
  const {orderId} = props;
  const classes = useCardStyles();

  const [productionOrder, setProductionOrder] =
    useState<PublicViewOrder | null>(null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const requestedQty = productionOrder?.requested_qty || 0;

  const distributionMap = useMemo(() => {
    if (!productionOrder) return {};

    return productionOrder.lineitems_totals
      .filter(el => el.total_items >= 1)
      .reduce(
        (map, location) => {
          return {
            ...map,
            [location.name]: {
              total: location.total_items,
              icon: "",
              id: "",
            },
          };
        },
        {} as {
          [key: string]: {total: number; icon: string; id: string | number};
        }
      );
  }, [productionOrder]);

  const [quantityPieSeries, setquantityPieSeries] = useState<{
    series: any[];
    total: number;
  }>();

  useEffect(() => {
    let data;
    if (requestedQty === 0) data = {};
    let totalAssigned = Object.keys(distributionMap).reduce((cum, cur) => {
      return cum + distributionMap[cur].total;
    }, 0);
    data = {
      series: [
        {
          name: "Not started",
          data: [requestedQty - totalAssigned],
          color: "#fff",
        },
        ...Object.keys(distributionMap).map((stationName, i) => {
          return {
            name: stationName,
            data: [distributionMap[stationName].total],
          } as any;
        }),
      ],
      total: totalAssigned,
    };
    setquantityPieSeries(data);
  }, [distributionMap, requestedQty]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const productionOrder = await getPublicProductionOrder(orderId);
        setProductionOrder(productionOrder);
      } catch (err) {
        notify(err as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [orderId, notify]);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "qr-popover" : undefined;
  const shareFn = async () => {
    try {
      await navigator.share({
        title: `Momenttrack order details`,
        text: `Check out this production order on momenttrack`,
        url: `${window.location.origin}/${productionOrder?.docid}`,
      });
    } catch (err) {
      notify("Your browser does not support this feature");
    }
  };
  const {copied, copyAction} = useCopy(
    `${window.location.origin}/${productionOrder?.docid}`
  );

  return (
    <>
      {loading && (
        <LinearProgress
          style={{position: "fixed", left: 0, right: 0, zIndex: 100, height: 6}}
          variant="query"
        />
      )}
      <Container maxWidth={false}>
        <Box py={3}>
          <Grid container spacing={2}>
            {!!productionOrder && (
              <Grid
                item
                className={classes.chart_grid_wrap}
                xs={12}
                md={Object.keys(distributionMap)?.length > 0 ? 6 : 12}
                lg={Object.keys(distributionMap)?.length > 0 ? 4 : 12}
              >
                <Paper style={{height: "100%"}}>
                  <Box p={2} display="flex" bgcolor="grey.200">
                    <Box mr={2}>
                      <img
                        src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg"
                        style={{maxHeight: 48}}
                        alt="Production order document"
                      />
                    </Box>
                    <Box sx={{mr: "1rem"}}>
                      <Typography variant="h5">Production order</Typography>
                      <Typography variant="caption">
                        {process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...
                        {productionOrder.docid.slice(-5)}
                      </Typography>
                    </Box>
                    <Box
                      className={classes.qr_box}
                      aria-haspopup="true"
                      aria-describedby={id}
                      onClick={handleClick}
                      sx={{ml: "auto"}}
                    >
                      <img
                        alt="place qr code"
                        src={`https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${productionOrder.location_id}`}
                        style={{width: "0.75in"}}
                      />
                      <span className={classes.qr_share}>
                        <ShareIcon fontSize={"small"} />
                      </span>
                    </Box>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      className={classes.popover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Button
                        onClick={() => copyAction()}
                        startIcon={<FileCopyIcon />}
                      >
                        {copied ? "Copied" : "Copy URL"}
                      </Button>
                      <Button onClick={shareFn} startIcon={<ShareIcon />}>
                        Share URL
                      </Button>
                    </Popover>
                  </Box>

                  <Box px={2} py={1}>
                    <Grid
                      className={classes.order_details_grid_wrap}
                      container
                      spacing={4}
                      alignItems="flex-start"
                    >
                      <Grid
                        style={{
                          maxWidth: "100%",
                        }}
                        item
                      >
                        <Typography variant="overline" color="textSecondary">
                          Part #
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            maxWidth: "100%",
                            wordWrap: "break-word",
                            display: "block",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {productionOrder.product.part_number}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline" color="textSecondary">
                          Date Created
                        </Typography>
                        <Typography variant="body2">
                          <DateTag
                            date={productionOrder.created_at}
                            dateInputType="utc"
                          />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline" color="textSecondary">
                          Quantity In Progress
                        </Typography>
                        <Typography variant="body2">
                          {quantityPieSeries?.total} out of {requestedQty}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline" color="textSecondary">
                          Description
                        </Typography>
                        <Typography variant="body2">
                          {productionOrder.product.description || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Divider />

                  <Box p={2} mt={2}>
                    <StackedBarChart
                      options={{
                        xAxis: {
                          categories: ["Quantity"],
                          visible: false,
                        },
                        yAxis: {
                          ceiling: requestedQty,
                        },
                      }}
                      series={quantityPieSeries?.series}
                    />
                  </Box>
                </Paper>
              </Grid>
            )}

            {!!productionOrder && Object.keys(distributionMap)?.length > 0 && (
              <Grid item xs={12} sm>
                <Grid container spacing={2}>
                  {Object.keys(distributionMap).map((stationName, i) => (
                    <Grid
                      key={i + "" + stationName}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={3}
                    >
                      <Card
                        onClick={() =>
                          history.push(`/${distributionMap[stationName].id}`)
                        }
                        className={classes.card_root}
                      >
                        <CardHeader
                          className={classes["card-header"]}
                          title={stationName.split("_").join(" ")}
                          titleTypographyProps={{variant: "h6"}}
                        />
                        <CardContent className={classes.card_content}>
                          <div className={classes["icon-wrap"]}>
                            <div>
                              <Typography variant="h4">
                                {distributionMap[stationName].total}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Item
                                {distributionMap[stationName].total !== 1
                                  ? "s"
                                  : ""}
                              </Typography>
                            </div>
                            {/* {distributionMap[stationName].icon && (
                              <img
                                className={classes.icon}
                                src={distributionMap[stationName].icon}
                                alt={stationName}
                              />
                            )} */}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PublicProductionOrderTemplate;
