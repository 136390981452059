
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import checkinAuthReducer from './reducers/checkin-auth-reducer';
import checkinFormReducer from './reducers/checkin-form-reducer';
import adminConsoleReducer from './reducers/admin-console-reducer';

const persistConfig = {
  key: 'checkin-auth',
  storage: storage,
  blacklist: ['loading']
};

const rootReducer = combineReducers({
  checkinAuth: persistReducer(persistConfig, checkinAuthReducer),
  checkinForm: checkinFormReducer,
  adminConsole: adminConsoleReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

export const persistor = persistStore(store);
export default store;
