
import { useState, useEffect } from 'react';
import { getPlace, Place } from '../../system/places';

const usePlace = (placeId?: number): [Place|null, boolean] => {
  const [place, setPlace] = useState<Place | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let active = true;

    (async ()=>{
      try{
        if(!placeId)
          return;

        setLoading(true);
        const place = await getPlace(placeId);

        if(!active) return;
        setPlace(place);
        setLoading(false);
      }
      catch(err){
        if(!active) return;
        setPlace(null);
        setLoading(false);
      }
    })();

    return ()=>{ active = false; };
  }, [placeId]);

  return [place, loading];
};

export default usePlace;
