
const styles = theme => ({
  message: ({ variant })=>({
    color: theme.palette[variant] ? theme.palette[variant].main : theme.palette.text.primary
  }),
  icon: ({ variant })=>({
    color: theme.palette[variant] ? theme.palette[variant].main : theme.palette.text.primary
  })
});

export default styles;
