
import React, { useState, useMemo, useRef } from 'react';
import moment from 'moment';
import { momentTrackService } from '../../../core/system/legacy-services/moment-track';
import { firebaseService } from '../../../core/system/legacy-services/firebase';
import useNotify from '../../../core/hooks/use-notify';
// import { useConfirmDialog } from '../../../../providers/ConfirmDialogProvider';
import { useAuthentication } from '../../../core/providers/AuthenticationProvider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import DynamicWebForm from '../../../core/components/DynamicWebForm';
import QrCodeScanner from '../../../core/components/QrCodeScanner';

const QrCodeInputField = ({placeholder, onChange=()=>{}, disabled=false}) => {
  const scanner = useRef(null);
  const [val, setVal] = useState('');

  return (
    <form onSubmit={(ev)=>{ev.preventDefault();onChange(val);setVal('');}}>
      <TextField
        value={val}
        onChange={ev=>setVal(ev.target.value)}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                edge="start"
                onClick={()=>scanner.current.openScanner()}
                disabled={disabled}
              >
                <Icon>qr_code</Icon>
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                edge="end"
                disabled={!val.length || disabled}
              >
                <Icon>done</Icon>
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <QrCodeScanner
        ref={scanner}
        hidden
        onResult={onChange}
        disabled={disabled}
      />
    </form>
  );
};

const ActivityForm = function(props) {
  const {data, idInfo} = props;
  const [loading, setLoading] = useState(false);
  // const [openForm, setOpenForm] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [scannedData, setScannedData] = useState({
    activityData: {
      url: idInfo.url,
      timestampUnix: moment().unix()
    }
  });
  const notify = useNotify();
  // const confirm = useConfirmDialog();
  const [currentUser] = useAuthentication();

  const requirements = useMemo(()=>{
    const requirements = [];

    if(data.required.thing_url.min_scans > 0){
      requirements.push({
        type: 'licence_plate',
        field: 'thingData',
        label: 'Scan licence plate',
        min_scans: data.required.thing_url.min_scans,
        max_scans: data.required.thing_url.max_scans === 0 ? Infinity : data.required.thing_url.max_scans
      });
    }
    if(data.required.document_url.min_scans > 0){
      requirements.push({
        type: 'document',
        field: 'documentData',
        label: 'Scan document',
        min_scans: data.required.document_url.min_scans,
        max_scans: data.required.document_url.max_scans === 0 ? Infinity : data.required.document_url.max_scans
      });
    }
    if(data.required.beacon_url.min_scans > 0){
      requirements.push({
        type: 'location',
        field: 'beaconData',
        label: 'Scan location',
        min_scans: data.required.beacon_url.min_scans,
        max_scans: data.required.beacon_url.max_scans === 0 ? Infinity : data.required.beacon_url.max_scans
      });
    }

    return requirements;

  }, [data]);

  function validateScannedData() {
    for(let i=0; i<requirements.length; i++){
      const requirement = requirements[i];
      if(!scannedData[requirement.field] || scannedData[requirement.field].length < requirement.min_scans)
        throw new Error(`Please scan atleast ${requirement.min_scans} ${requirement.type}(s).`);
    }
  }

  function scanDataRemoveHandler(scanData, dataKey) {
    return () => {
      setScannedData(data=>({
        ...data,
        [dataKey]: data[dataKey].filter(d=>d !== scanData)
      }));
    };
  }

  function scanResultHandler(resultType, dataKey) {
    return (scanResult)=>{
      try{
        const idInfo = momentTrackService.parseId(scanResult);
        if(idInfo.type !== resultType)
          throw new Error(`Invalid ${resultType} Qr.`);

        if(scannedData[dataKey] && scannedData[dataKey].find(d=>d.url===idInfo.url))
          return;

        const timestampUnix = moment().unix();
        setScannedData(oldData=>({
          ...oldData,
          [dataKey]: [
            ...(oldData[dataKey] || []),
            {url: idInfo.url, timestampUnix}
          ]
        }));
      }
      catch(err){
        notify(err);
      }
    };
  }

  async function handleTransactActivity(webFormData) {
    try{
      validateScannedData();

      const personIdInfo = momentTrackService.parseId(currentUser.person_id);
      const timestampUnix = moment().unix();

      setLoading(true);
      const transaction = await firebaseService.transactActivity(idInfo.id, {
        data: {
          ...scannedData,
          personData: {
            url: personIdInfo.url,
            timestampUnix
          }
        },
        web_form_data: webFormData
      });

      notify('Success.');
      setTransaction(transaction);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Container>
      <Box py={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">{data.activityTitle || 'Activity'}</Typography>
            <Typography variant="caption" color="textSecondary">{data.activityDescription || idInfo.url}</Typography>
          </Box>

          <Box p={2}>
            {!transaction && (
              <Grid container spacing={4}>
                {requirements.map((requirement, i)=>(
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h5" gutterBottom>{requirement.label}</Typography>

                    {(!scannedData[requirement.field] || scannedData[requirement.field].length < requirement.max_scans) && (
                      <QrCodeInputField
                        placeholder="Type/Scan QR code..."
                        onChange={scanResultHandler(requirement.type, requirement.field)}
                      />
                    )}
                    <div><br/></div>
                    {!!scannedData[requirement.field] && scannedData[requirement.field].map(scanData=>(
                      <Chip
                        label={`3hd.us/...${scanData.url.slice(-5)}`}
                        color="secondary"
                        variant="outlined"
                        onDelete={scanDataRemoveHandler(scanData, requirement.field)}
                      />
                    ))}
                  </Grid>
                ))}

                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h5" gutterBottom>Submit</Typography>
                  <DynamicWebForm
                    formFields={data.required.web_form_data || []}
                    onSubmit={handleTransactActivity}
                    disabled={loading}
                  >
                    <div>
                      {loading && (
                        <CircularProgress color="secondary" size={32} />
                      )}
                      {!loading && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                          disabled={loading}
                        >
                          TRANSACT ACTIVITY
                        </Button>
                      )}
                    </div>
                  </DynamicWebForm>
                </Grid>
              </Grid>
            )}
            {!!transaction && (
              <Typography align="center">Transaction successful!</Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ActivityForm;
