
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LitmonDocument from './LitmonDocument';
import JobTicket from './JobTicket';
import ProductionOrderDocument from './ProductionOrder';

const Document = props => {
  const { data, idInfo } = props;

  switch((data.document_type || data.type || '').toLowerCase()){
    case 'litmon': return <LitmonDocument data={data} />;
    case 'jobticket': return <JobTicket data={data} documentUrl={idInfo.url} />;
    case 'production_order': return <ProductionOrderDocument data={data} documentUrl={idInfo.url} />;
    default: return (
      <Box pt={10} textAlign="center">
        <Typography variant="body1">No template has been set up for this <code>document_type</code> yet. Please try again later.</Typography>
      </Box>
    );
  }
}

export default Document;
