
import axios from 'axios';
import { AuthData } from './types';
import { subscribe } from '../pubsub';

const authClient = axios.create();

let authData: AuthData = {
  access_token: '',
  refresh_token: '',
  org_slug: 'default'
};

function updateAuth(data: AuthData | null) {
  Object.assign(authData, data || {
    org_slug: 'default'
  });
  
  authClient.defaults.baseURL = `${process.env.REACT_APP_ORDERTRACK_API_HOST}/auth/${authData.org_slug}`;
}

subscribe<AuthData>('update:authentication', ({ data })=>updateAuth(data));

authClient.interceptors.response.use(undefined, err=>{
  return Promise.reject(new Error(err.response.data.message));
});

export default authClient;
