
type TemplateData = {
  qr: string;
  asset: string;
};

const template = (data: TemplateData) => (`
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Vertical label</title>

  <style>
    body, html {
      margin: 0;
      padding: 0;
      font-size: 10px;
      font-family: sans-serif;
    }

    .container {
      width: 1in;
      height: 2in;
    }

    .label-root {
      position: relative;
      width: 1in;
      height: 2in;
      line-height: normal;
      background: #ffffff;
    }

    .safe-area-container {
      position: absolute;
      top: 0.07in;
      left: 0.07in;
      right: 0.07in;
      bottom: 0.07in;
    }

    .label-root p {
      margin: 0;
    }

    .label-root .qr-code-wrapper {
      width: 100%;
      line-height: 0;
      margin: 0 auto 0.07in;
      box-sizing: border-box;
      padding: 12%;
    }

    .qr-code-wrapper .qr-code {
      max-width: 100%;
    }

    .details-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .details-wrapper .label-info {
      overflow: hidden;
      text-align: center;
    }

    .label-info .title {
      margin-bottom: 0.035in;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-info .subtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  </style>
</head>
<body>
    <div class="container">

      <!-- BEGIN: Label -->
      <div class="label-root">
        <div class="safe-area-container">
          <div class="qr-code-wrapper">
            <img src="https://api.3hd.us/app/qrcode_gen.create?data=${data.qr}" class="qr-code"/>
          </div>
          <div class="details-wrapper">
            <div class="label-info">
              <p class="subtitle">Gov Prop</p>
              <p class="title">Asset</p>
              <p class="subtitle"><b>${data.asset}</b></p>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Label -->

    </div>
</body>
</html>
`);

export default template;
