
import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RichTextEditor from '../../core/components/RichTextEditor';

const dynamicFieldsMap = {
  'first_name': 'First name',
  'last_name': 'Last name',
  'email': 'Email address',
  'current_time': 'Current time'
};

const NdaEditingPanel = ({ expanded, onExpansionChange=()=>null, nda='', label='NDA', disabled, onSave=()=>null }) => {
  const [updatedNda, setUpdatedNda] = React.useState(nda);
  const [editMode, setEditMode] = React.useState(false);

  async function handleSave() {
    const success = await onSave(updatedNda);
    setEditMode(!success);
  }

  return (
    <Accordion expanded={expanded} onChange={onExpansionChange} className="nda-editing-panel">
      <AccordionSummary expandIcon={<FontAwesomeIcon icon="chevron-down" size="xs" />}>
        <b>{label}</b>
      </AccordionSummary>
      <AccordionDetails>
        <Box flexGrow={1}>
          {editMode && (
            <RichTextEditor
              EditorProps={{
                editorClassName: 'editor-body'
              }}
              contentToEdit={nda}
              onChange={newNda=>setUpdatedNda(newNda)}
              disabled={disabled}
              dynamicFieldsMap={dynamicFieldsMap}
            />
          )}
          {!editMode && (
            <div dangerouslySetInnerHTML={{ __html: nda }} className="nda-body" />
          )}
        </Box>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {editMode && (
          <>
            <Button
              size="small"
              color="primary"
              onClick={()=>setEditMode(false)}
              disabled={disabled}
            >Cancel</Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={disabled}
            >Save</Button>
          </>
        )}
        {!editMode && (
          <Button
            size="small"
            color="secondary"
            onClick={()=>setEditMode(true)}
            disabled={disabled}
          >Edit</Button>
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default NdaEditingPanel;
