import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DateTag from "../../../core/components/DateTag";
import moment from "moment/moment";
import {LicensePlateActivityLog} from "../../../core/system/license-plates";

const columnDefs = (isPublic: boolean) => [
  {
    field: "when",
    headerName: "When",
    valueGetter: (log: LicensePlateActivityLog) =>
      moment.utc(log.created_at).local().format("MMM D, YYYY [at] h:mma"),
    renderCell: (log: LicensePlateActivityLog) => (
      <span style={{width: "max-content", display: "inline-block"}}>
        <DateTag date={log.created_at} dateInputType="utc" />
      </span>
    ),
  },
  {
    field: "activity",
    headerName: "Activity",
    valueGetter: (log: LicensePlateActivityLog) => log.activity,
  },
  {
    field: "location",
    headerName: "Place",
    renderCell: (log: LicensePlateActivityLog) => {
      if (!log.meta) return "";

      if (!log.meta.location.beacon_id)
        return <Typography>{log.meta.location.name}</Typography>;

      return isPublic ? (
        log.meta.location.name
      ) : (
        <Link href={`/${log.meta.location.beacon_id}`} target="_BLANK">
          {log.meta.location.name}
        </Link>
      );
    },
  },
  {
    field: "person",
    headerName: "Who",
    renderCell: (log: LicensePlateActivityLog) =>
      isPublic ? (
        log.user.first_name
      ) : (
        <Link href={`/${log.user.person_id}`} target="_BLANK">
          {log.user.first_name}
        </Link>
      ),
  },
  // {
  //   field: "linked",
  //   headerName: "Linked",
  //   renderCell: (log: LicensePlateActivityLog) => {
  //     if (!log.linked) return "";

  //     return (
  //       <Link href={"//" + log.linked} target="_BLANK">{`${
  //         process.env.REACT_APP_QR_BASENAME || "3hd.us"
  //       }/...${log.linked.slice(-5)}`}</Link>
  //     );
  //   },
  // },
  {
    field: "notes",
    headerName: "Notes",
    width: 360,
    padding: "checkbox",
    valueGetter: (log: LicensePlateActivityLog) => log.message,
    renderCell: (log: LicensePlateActivityLog) => {
      const attachmentRegex = /<File>(http.+)<\/File>/i;
      const matches = log.message?.match(attachmentRegex);

      return (
        <>
          {!matches
            ? log.message
            : (() => `File: ${matches[1].split("/").pop()}`)()}
          {matches && matches.length > 0 && (
            <Link href={matches[1]} target="_BLANK">
              <IconButton>
                <Icon>download</Icon>
              </IconButton>
            </Link>
          )}
        </>
      );
    },
  },
];

export default columnDefs;
