
import axios from 'axios';
import moment from 'moment';
import { momentTrackService } from '../moment-track';
import { firebaseService } from '../firebase';

const TIMELOG_WEBHOOK_URL = process.env.REACT_APP_TIMELOG_WEBHOOK_URL;
const CONVERT_TICKET_WEBHOOK_URL = process.env.REACT_APP_CONVERT_TICKET_WEBHOOK_URL;
const CLOSE_TICKET_WEBHOOK_URL = process.env.REACT_APP_CLOSE_TICKET_WEBHOOK_URL;
const VOID_TICKET_WEBHOOK_URL = process.env.REACT_APP_VOID_TICKET_WEBHOOK_URL;

export default class JobTicketService {
  async clockIn(documentId, personId) {
    const documentIdInfo = momentTrackService.parseId(documentId);
    const personIdInfo = momentTrackService.parseId(personId);

    if(documentIdInfo.type !== 'document')
      throw new Error('Invalid document id.');
    if(personIdInfo.type !== 'person')
      throw new Error('Invalid person id.');

    const timestampUnix = moment().unix();

    const transaction = await firebaseService.createTransaction({
      data: {
        activityData: {
          url: '3hd.us/Na50Z62fF',
          timestampUnix
        },
        documentData: [
          {
            url: documentIdInfo.url,
            timestampUnix
          }
        ],
        personData: [
          {
            url: personIdInfo.url,
            timestampUnix
          }
        ]
      }
    });

    return axios.post(TIMELOG_WEBHOOK_URL, { transactionID: transaction.transactionID }, {
      headers: { 'Content-Type': 'text/plain' }
    });
  }

  getConvertActivity() {
    return firebaseService.getActivity('convertid');
  }

  getPermCloseActivity() {
    return firebaseService.getActivity('PermClose');
  }

  async convertTicket(documentId, personId, web_form_data) {
    const documentIdInfo = momentTrackService.parseId(documentId);
    const personIdInfo = momentTrackService.parseId(personId);

    if(documentIdInfo.type !== 'document')
      throw new Error('Invalid document id.');
    if(personIdInfo.type !== 'person')
      throw new Error('Invalid person id.');

    const timestampUnix = moment().unix();

    const transaction = await firebaseService.createTransaction({
      data: {
        activityData: {
          url: '3hd.us/convertid',
          timestampUnix
        },
        documentData: [
          {
            url: documentIdInfo.url,
            timestampUnix
          }
        ],
        personData: [
          {
            url: personIdInfo.url,
            timestampUnix
          }
        ]
      },
      web_form_data
    });

    return axios.post(CONVERT_TICKET_WEBHOOK_URL, { transactionID: transaction.transactionID }, {
      headers: { 'Content-Type': 'text/plain' }
    });
  }

  async closeTicket(documentId, locationId, personId, web_form_data) {
    const documentIdInfo = momentTrackService.parseId(documentId);
    const personIdInfo = momentTrackService.parseId(personId);
    const locationIdInfo = momentTrackService.parseId(locationId);

    if(documentIdInfo.type !== 'document')
      throw new Error('Invalid document id.');
    if(personIdInfo.type !== 'person')
      throw new Error('Invalid person id.');
    if(locationIdInfo.type !== 'location')
      throw new Error('Invalid location id.');

    const timestampUnix = moment().unix();

    const transaction = await firebaseService.createTransaction({
      data: {
        activityData: {
          url: '3hd.us/PermClose',
          timestampUnix
        },
        documentData: [
          {
            url: documentIdInfo.url,
            timestampUnix
          }
        ],
        beaconData: [
          {
            url: locationIdInfo.url,
            timestampUnix
          }
        ],
        personData: [
          {
            url: personIdInfo.url,
            timestampUnix
          }
        ]
      },
      web_form_data
    });

    return axios.post(CLOSE_TICKET_WEBHOOK_URL, { transactionID: transaction.transactionID }, {
      headers: { 'Content-Type': 'text/plain' }
    });
  }

  async voidTicket(documentId, personId) {
    const documentIdInfo = momentTrackService.parseId(documentId);
    const personIdInfo = momentTrackService.parseId(personId);

    if(documentIdInfo.type !== 'document')
      throw new Error('Invalid document id.');
    if(personIdInfo.type !== 'person')
      throw new Error('Invalid person id.');

    const timestampUnix = moment().unix();

    const transaction = await firebaseService.createTransaction({
      data: {
        activityData: {
          url: '3hd.us/void_pend',
          timestampUnix
        },
        documentData: [
          {
            url: documentIdInfo.url,
            timestampUnix
          }
        ],
        personData: [
          {
            url: personIdInfo.url,
            timestampUnix
          }
        ]
      }
    });

    return axios.post(VOID_TICKET_WEBHOOK_URL, { transactionID: transaction.transactionID }, {
      headers: { 'Content-Type': 'text/plain' }
    });
  }
}
