
import React, { useState, useEffect } from 'react';
import { momentTrackService } from '../../../../core/system/legacy-services/moment-track';
import { jobTicketService } from '../../../../core/system/legacy-services/job-ticket';
import useNotify from '../../../../core/hooks/use-notify';
import useLocation from '../../../../core/hooks/use-location';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedIn';
import DynamicWebForm from '../../../../core/components/DynamicWebForm';
import QrCodeScanner from '../../../../core/components/QrCodeScanner';

const PutAwayTicket = function(props) {
  const {
    documentUrl,
    document,
    onCloseTicket = ()=>{},
    disabled = false
  } = props;

  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(null);
  const [locationId, setLocationId] = useState('');
  const [location, loadingLocation, message] = useLocation(locationId);

  function handleSubmit(formData) {
    try{
      if(!locationId)
        throw new Error('Scan the location where items has been put.');

      const locationIdInfo = momentTrackService.parseId(locationId);

      if(locationIdInfo.type !== 'location')
        throw new Error('Invalid location.');

      onCloseTicket(locationIdInfo.id, formData);
    }
    catch(err){
      notify(err);
    }
  }

  useEffect(()=>{
    (async ()=>{
      try{
        setLoading(true);
        const activity = await jobTicketService.getPermCloseActivity();
        setActivity(activity);
      }
      catch(err){
        notify(err);
      }
      finally{
        setLoading(false);
      }
    })();
  }, [ notify ]);

  return (
    <Box py={3}>
      <Box px={4}>
        <Typography variant="h5" align="center" gutterBottom><b>Putaway ticket</b></Typography>
        <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>
      </Box>

      <Divider />

      <Box my={2}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>PROJECT</TableCell>
                <TableCell>{document.project}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PROJECT ID</TableCell>
                <TableCell>{document.projectID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MODULE</TableCell>
                <TableCell>{document.module}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MODULE ID</TableCell>
                <TableCell>{document.moduleID}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {!!activity && (
        <Box mt={4}>
          {!location && (
            <Typography
              color="textSecondary"
              variant="subtitle2"
              component="div"
              gutterBottom
              align="left"

            >Scan the location where it has been placed.</Typography>
          )}
          {!!location && (
            <>
              <Typography variant="h6">{location.name}</Typography>
              <Typography variant="caption" color="textSecondary" component="div">{locationId}</Typography>
              <div><br/></div>
            </>
          )}
          {!!message && (
            <Typography color="error" gutterBottom>{message}</Typography>
          )}
          <QrCodeScanner
            label="Scan location"
            onResult={setLocationId}
            disabled={disabled || loading || loadingLocation}
            ButtonProps={{
              variant: 'outlined',
              color: 'primary'
            }}
          />
          <div><br/><br/></div>

          <DynamicWebForm
            formFields={activity.required.web_form_data || []}
            disabled={disabled || loading || loadingLocation}
            onSubmit={handleSubmit}
          >
            <Box textAlign="center" py={1}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<AssignmentIcon />}
                disabled={disabled || loading || loadingLocation}
              >
                CLOSE TICKET
              </Button>
            </Box>
          </DynamicWebForm>
        </Box>
      )}

      {loading && (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default PutAwayTicket;
