
import React from 'react';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

export default function LinearProgress({show=false, ...props}) {
  return (
    <div style={{ position: 'relative' }}>
      {show && <MuiLinearProgress style={{ position: 'absolute', width: '100%' }} color="secondary" {...props} />}
    </div>
  );
}
