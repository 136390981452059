
import { Person, PersonLookupResult } from './types';
import orderTrackApi, { publicApiClient } from '../ordertrack-client';

export const lookupPerson = async (personId: string): Promise<PersonLookupResult> => {
  const res = await publicApiClient.get('/users/lookup', { params: { person_id: personId } });
  return res.data.data;
};

export const getAllPersons = async () => {
  const res = await orderTrackApi.get('/users', {
    params: { include_inactive: true }
  });
  return res.data.data as Person[];
};

export const getPerson = async (personId: number): Promise<Person> => {
  const res = await orderTrackApi.get(`/users/${personId}`);
  return res.data.data;
};
