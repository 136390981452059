import apiClient, {publicApiClient} from "../ordertrack-client";
import {Place, IPlaceReport, IPlaceLookupResult} from "./types";
import getPlaceTemplate from "./place-templates/place-template";
import htmlToPdf, {PdfOptions} from "../utils/html-to-pdf";
import {IPageData} from "../../../IdLookup/PlaceTemplate/PlaceReport";

export async function getAllPlaces(): Promise<Place[]> {
  const res = await apiClient.get("/locations");
  return res.data.data;
}

export async function getPlace(placeId: number): Promise<Place> {
  const res = await apiClient.get(`/locations/${placeId}`);
  return res.data.data;
}

export async function getPlaceReport(
  placeId: number,
  params?: {page: number; per_page?: number}
): Promise<IPlaceReport> {
  const res = await apiClient.get(`/locations/${placeId}/report`, {
    params: {...params},
  });
  return res.data.data;
}

export const lookupPlace = async (
  placeId: string
): Promise<IPlaceLookupResult> => {
  const res = await publicApiClient.get("/locations/lookup", {
    params: {beacon_id: placeId},
  });
  return res.data.data;
};

export const generateSheets = (pageData: IPageData): Promise<string> => {
  const labelsHtml = getPlaceTemplate(pageData, 18);

  const pdfOptions: PdfOptions = {
    pdf_mode: "portrait",
    pdf_name: `place-report-sheet-${pageData.reportData.id}`,
    margin_top: "0mm",
    page_width: "210mm",
    page_height: "297mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  };

  return htmlToPdf(labelsHtml, pdfOptions);
};
