import { Box, Typography, Button } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import React from "react";

function LoginRedirect({ text = "Please login to verify identity." }) {
  const currentLocation = useLocation();

  return (
    <>
      <Box my={8} p={2} textAlign="center">
        <Typography variant="h6" gutterBottom>{text}</Typography>
        <Button style={{ marginTop: "0.5rem" }} component={RouterLink}
          to={{
            pathname: "/login",
            state: { referrer: currentLocation },
          }} size="large" variant="contained" color="primary">
          Login
        </Button>
      </Box>
    </>
  );
}

export default LoginRedirect;
