
import { useState, useEffect } from 'react';
import { getStations } from '../../core/system/legacy-services/wms';

function useStations() {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(()=>{
    (async ()=>{
      try{
        setLoading(true);
        const stations = await getStations();
        setLoading(false);
        setMessage('');
        setStations(stations);
      }
      catch(err){
        setMessage(err.message);
        setLoading(false);
      }
    })();
  }, []);

  return [stations, loading, message];
}

export default useStations;
