
import React/*, { useState, useEffect }*/ from 'react';
import * as firebase from '../../core/system/legacy-services/firebase-old';
import moment from 'moment/moment';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
// import CircularProgress from '@material-ui/core/CircularProgress';

export const LicencePlate = function(props) {
  const { data } = props;
  // const [loading, setLoading] = useState(false);
  // const [partDetails, setPartDetails] = useState(null);

  // useEffect(()=>{
  //   async function loadPartDetails(){
  //     try{
  //       setLoading(true);
  //       const details = await firebase.getPartDetails(data.part_number, data.company);
  //       setPartDetails(details);
  //     }
  //     catch(err){
  //       alert(err.message);
  //     }
  //     finally{
  //       setLoading(false);
  //     }
  //   }

  //   if(data.part_number)
  //     loadPartDetails();
  // }, [data]);

  return (
    <Container>
      <Box pt={3} mb={20}>
        <Paper>
          <Box p={2} bgcolor="grey.200">
            <Typography variant="h6"><b>Licence plate</b></Typography>
            <Typography variant="subtitle2" color="textSecondary">{ data.licence_plate }</Typography>
          </Box>

          <Divider />

          <Box p={2} mb={4}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>PART #</Typography>
                <Typography>{ data.part_number }</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>CURRENTLY RESIDING</Typography>
                <Link target="_BLANK" component={RouterLink} to={`/${firebase.cleanId(data.location)}`}>{ data.location }</Link>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>QTY.</Typography>
                <Typography>{ data.qty }</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>INTAKE TIME</Typography>
                <Typography>{ moment.unix(data.intake_timestamp).format('ddd MMM Do, YYYY - hh:mma') }</Typography>
              </Grid>
            </Grid>
          </Box>

          {/* TODO: Uncomment after authentication is in place */}
          {/* <Box px={2}>
            <Box px={2} py={1} bgcolor="grey.100">
              <Typography variant="h6">Part description</Typography>
            </Box>
          </Box> */}

          {/* <Box p={2}>
            {loading && <CircularProgress />}
            {!loading && !!partDetails && (
              <Grid container spacing={4}>
                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>DEVICE ID</Typography>
                  <Typography>{ partDetails.deviceID }</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>IP ADDRESS</Typography>
                  <Typography>{ partDetails.IP_address }</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>MAC ADDRESS</Typography>
                  <Typography>{ partDetails.MAC_address }</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>TRANSACTION</Typography>
                  <Link target="_BLANK" component={RouterLink} to={`/${firebase.cleanId(partDetails.transactionID)}`}>{ partDetails.transactionID }</Link>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>ACTIVITY DATA</Typography>
                  <Typography>{partDetails.data.activityData && moment.unix(partDetails.data.activityData.timestampUnix).format('ddd MMM Do, YYYY - hh:mma')}</Typography>
                  <Link
                    target="_BLANK"
                    component={RouterLink}
                    to={`/${firebase.cleanId(partDetails.data.activityData && partDetails.data.activityData.url)}`}
                  >
                    { partDetails.data.activityData && partDetails.data.activityData.url }
                  </Link>
                </Grid>
                {partDetails.data.personData && partDetails.data.personData.map((data, i)=>(
                  <Grid key={''+i} item xs={6} sm={4} md={3}>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>PERSON DATA</Typography>
                    <Typography>{moment.unix(data.timestampUnix).format('ddd MMM Do, YYYY - hh:mma')}</Typography>
                    <Link
                      target="_BLANK"
                      component={RouterLink}
                      to={`/${firebase.cleanId(data.url)}`}
                    >
                      { data.url }
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box> */}
        </Paper>
      </Box>
    </Container>
  );
};

export default LicencePlate;
