
const chartOptions = {
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<span style="font-weight: 400;">{point.name}</span>: <b>{point.percentage:.0f} %</b>'
          }
      }
  }
};

export default chartOptions;
