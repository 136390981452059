import React, {useMemo, useState} from "react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  TablePagination,
  Typography,
} from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import {ProductionOrderLogsProps} from "./types";
import DataGrid from "../../../../core/components/DataGrid";
import columnDefs from "./column-defs";
import {Footer} from "@momenttrack/react/base/Layout";
import SendCSVModal from "../../../../core/components/SendCSVModal";

const ROWS_PER_PAGE = 50;
// sendProductionOrderLogs
const ProductionOrderLogs: React.FC<ProductionOrderLogsProps> = props => {
  const {
    logs,
    product,
    onShare,
    paginationParams,
    setpaginationParams,
    loading,
    order_id,
  } = props;

  const logsNormalized = useMemo(() => {
    return logs.map(log => ({...log, product}));
  }, [logs, product]);

  const [openMailModal, setopenMailModal] = useState(false);

  return (
    <div>
      <Box
        px={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Logs</Typography>
        <Box>
          <IconButton
            aria-label="share via mail"
            color="secondary"
            onClick={onShare}
          >
            <EmailOutlinedIcon />
          </IconButton>
          <Button
            color="secondary"
            startIcon={<Icon>file_download</Icon>}
            onClick={() => setopenMailModal(true)}
            disabled={logsNormalized.length === 0}
          >
            Download CSV
          </Button>
        </Box>
      </Box>
      <DataGrid
        rows={logsNormalized}
        columns={columnDefs}
        noPagination
        disableSelectionOnClick
      />
      <Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1rem",
          flexWrap: "wrap",
        }}
        textAlign="right"
        bgcolor="grey.200"
      >
        <div style={{marginLeft: "auto"}}>
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[]}
            page={paginationParams.page}
            nextIconButtonProps={{
              disabled: logs.length < 50,
            }}
            labelDisplayedRows={({from, to, count}) =>
              `Showing item ${from} - ${
                loading ? "..." : from + logs.length - 1
              }`
            }
            onPageChange={(ev, page) => {
              setpaginationParams(prev => ({
                ...prev,
                page,
              }));
            }}
          />
        </div>
      </Footer>
      <SendCSVModal
        reqBody={{order_id}}
        openModal={openMailModal}
        setOpenModal={setopenMailModal}
        type="orders"
      />
    </div>
  );
};

export default ProductionOrderLogs;
