import {createTheme} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const mainLight = createTheme({
  palette: {
    primary: {main: "#ec1c24"},
    secondary: {main: "#007dc8"},
    background: {
      default: grey["100"],
    },
  },
  props: {
    MuiAppBar: {
      color: "default",
      elevation: 0,
      position: "static",
      square: true,
    },
    MuiPaper: {
      square: true,
      elevation: 0,
    },
    MuiDialog: {
      TransitionProps: {timeout: {enter: 1000, exit: 800}},
      BackdropProps: {timeout: {enter: 800, exit: 800}},
    },
  },
  overrides: {
    MuiFab: {
      root: {
        "&$disabled": {
          backgroundColor: "#e0e0e0",
        },
      },
    },
    // MuiBackdrop: {
    //   'root': {
    //     backgroundColor: `rgba(255,255,255,0.85)`
    //   }
    // },
    MuiSnackbarContent: {
      root: {
        fontSize: "1.2em",
      },
    },
  },
});

mainLight.typography.h1 = {
  fontSize: "6.8rem",
  fontWeight: 400,
  lineHeight: 1.2,
  [mainLight.breakpoints.only("xs")]: {fontSize: "4.8rem"},
};

export default mainLight;
