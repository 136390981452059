import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import chartOptions from "./chart-options";
import useChartSvg from "../../hooks/use-chart-svg";

const PieChart = function (props) {
  const chartComponent = useRef(null);
  const [chartSvg] = useChartSvg(chartComponent.current?.chart);

  const options = {
    ...chartOptions,
    title: { text: props.title },
    series: props.series,
  };

  useEffect(() => {
    if (!chartSvg || !props.getSvg) return;
    props.getSvg(chartSvg);
    // eslint-disable-next-line
  }, [chartSvg]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponent}
    />
  );
};

export default PieChart;
