
import { useContext, useEffect } from 'react';
import { LayoutOptions } from './types';
import layoutContext from './context';

const useLayout = (options: LayoutOptions = {}) => {
  const { api } = useContext(layoutContext);
  const {
    noHeader,
    noFooter

  } = options;

  useEffect(() => api.options({ noHeader, noFooter }), [noHeader, noFooter, api]);
}

export default useLayout;
