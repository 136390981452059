
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const VoidTicket = function(props) {
  const { documentUrl } = props;

  return (
    <Box py={3}>
      <Box px={4}>
        <Typography variant="h5" align="center" gutterBottom><b>Void ticket</b></Typography>
        <Typography align="center" variant="subtitle1">3hd.us/...{documentUrl.slice(-5)}</Typography>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          component="div"
          gutterBottom
          align="center"

        >This ticket has been voided</Typography>
      </Box>
    </Box>
  );
}

export default VoidTicket;
