
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HomePage from './HomePage';
import ExplorerPage from './ExplorerPage';
import ReportsPage, { StationReportPage } from './ReportsPage';

const WMS: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/wms" component={HomePage} />
      <Route path="/wms/explorer" component={ExplorerPage} />
      <Route path="/wms/stations/:stationId/report" component={StationReportPage} />
      <Route path="/wms/stations" component={ReportsPage} />

      <Redirect to="/wms" />
    </Switch>
  );
};

export default WMS;
