import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {QrScanner} from "@yudiel/react-qr-scanner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MultiCodeScanner = function (props, ref) {
  const {
    title = "Scan QR code",
    facingMode = "environment",
    onResult = () => null,
    constraints = {},
    label = "Scan QR",
    showScanButton = false,
    ButtonProps = {},
  } = props;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = React.useState("Loading...");

  const handleDialogClose = useCallback(() => {
    setMessage("");
    setOpen(false);
  }, []);

  const handleScanResult = useCallback(
    result => {
      if (!result) return setMessage("No QR detected.");

      if (open) handleDialogClose();

      onResult(result);
    },
    [open, handleDialogClose, onResult]
  );

  const handleScanError = useCallback(err => {
    console.dir(err);
    setMessage(
      err?.message ? err.message : "Allow camera access for live scan."
    );
  }, []);

  const openScanner = useCallback(() => {
    setOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({openScanner}), [openScanner]);

  return (
    <>
      {showScanButton && (
        <Button
          size="large"
          startIcon={<FontAwesomeIcon icon="qrcode" />}
          onClick={openScanner}
          {...ButtonProps}
        >
          {label}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
        className="qrcode-scanner"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="input-window">
          <DialogContentText>{message || "--"}</DialogContentText>

          <QrScanner
            constraints={{facingMode, ...constraints}}
            onDecode={handleScanResult}
            onError={handleScanError}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDialogClose} size="large">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default forwardRef(MultiCodeScanner);
