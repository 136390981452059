import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Accesstoken,sendtoapinode } from '../core/system/legacy-services/digikey/diginode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useAuthentication } from '../core/providers/AuthenticationProvider';

const Digikey = function(props) {
    const authcode = props.location.search || '';
    const [accesstoken, setAccesstoken] = useState(null)
    const [digikeyqr, setDigikeyqr] = useState(null)
    const [loading, setLoading] = useState(false);
    const currentLocation = useLocation();
    const [currentUser] = useAuthentication();

    useEffect(() => {
        if(!currentUser)
            return;

        async function getaccesstoken () {
            const getaccess = await Accesstoken(authcode.substring(6, 14));
            console.log(getaccess.data.accesstoken)
           if(getaccess !== "Failed"){
                setAccesstoken(getaccess.data.accesstoken)
           }
           else{
               window.location.replace('https://api.digikey.com/v1/oauth2/authorize?response_type=code&client_id=LttNhtAWuhAxykn3fDdkrvOaAWgDUXro&redirect_uri=https://www.momenttrack.com/DigiKey');
           }
        }

        if (authcode.trim() === ""){
            window.location.replace('https://api.digikey.com/v1/oauth2/authorize?response_type=code&client_id=LttNhtAWuhAxykn3fDdkrvOaAWgDUXro&redirect_uri=https://www.momenttrack.com/DigiKey');
        }
        else{
            getaccesstoken()
        }
    }, [authcode, currentUser]);
    
      async function sendtoapi () {
      try{
       setLoading(true);
       if (accesstoken === undefined){
        window.location.replace('https://api.digikey.com/v1/oauth2/authorize?response_type=code&client_id=LttNhtAWuhAxykn3fDdkrvOaAWgDUXro&redirect_uri=https://www.momenttrack.com/DigiKey');
       }
       const post = await sendtoapinode(digikeyqr, accesstoken, currentUser);
       console.log(post.data)
       if(post.data !== "failed"){
        setDigikeyqr('')
       }
       else if(post.data === 'failed'){
        window.location.replace('https://api.digikey.com/v1/oauth2/authorize?response_type=code&client_id=LttNhtAWuhAxykn3fDdkrvOaAWgDUXro&redirect_uri=https://www.momenttrack.com/DigiKey');
       }
      }
      catch(err){ 
      }
      finally{
       setLoading(false);
      }
      }

    if(!currentUser)
      return (
        <Box p={3} textAlign="center">
            <Typography gutterBottom>Please verify your identity.</Typography>
            <Link component={RouterLink} to={{ pathname: '/login', state: { referrer: currentLocation } }} underline="none">
                <Button variant="contained" color="primary">Login</Button>
            </Link>
        </Box>
      );

    return (
        <Container>
            <Box pt={3}>
            <Paper>
                <Box p={2} bgcolor="grey.200">
                <Typography variant="h6">DigiKey</Typography>
                <Typography variant="subtitle2" color="textSecondary">Receipt of Electronics with Double data matrix</Typography>
                
                <Box p={3}> 
                <TextField
                fullWidth
                variant="outlined"
                label="DigiKey"
                required
                multiline
                value={digikeyqr}
                onChange={e => setDigikeyqr(e.target.value)}
                />   
                </Box>

                <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          disabled={loading}
                          onClick={sendtoapi}
                        >
                          TRANSACT ACTIVITY
                          {loading && (
                            <CircularProgress size={22} />
                            )}
                </Button>

                </Box>

            </Paper>
            </Box>
        </Container>
    )
}

export default Digikey
