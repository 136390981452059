
class Cache extends Map<any, { value: any; _ts: number; }> {
  // constructor () {
  //   super();
  // }

  set (key: any, value: any) {
    return super.set(key, { value, _ts: Date.now() });
  }

  get <T = any>(key: any, maxAge?: number): T | undefined {
    const value = super.get(key);
    if (!value) 
      return undefined;

    if (maxAge && (Date.now() - value._ts) >= maxAge) {
      super.delete(key);
      return undefined;
    }

    return value.value;
  }
}

export default Cache;
