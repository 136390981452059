
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadEmailTemplates, updateEmailTemplate } from '../../core/redux/actions/admin-console-actions';
import useNotify from '../../core/hooks/use-notify';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinearProgress from '../../core/components/LinearProgress';
import EmailTemplateEditingPanel from './EmailTemplateEditingPanel';

export default function EmailTemplates() {
  const notify = useNotify();
  const dispatch = useDispatch();
  const {loading: { email_templates: loading }, email_templates} = useSelector(state=>state.adminConsole);
  const [expandedPanel, setExpandedPanel] = useState('');

  const reload = useCallback(()=>{
    dispatch(loadEmailTemplates())
      .catch(notify)
    ;
  }, [notify, dispatch]);

  useEffect(()=>{
    if(email_templates === null)
      reload();
  }, [reload, email_templates]);

  const templateSaveHandler = templateType => async updatedTemplate => {
    dispatch(updateEmailTemplate(templateType, updatedTemplate))
      .then(message=>{
        notify(message);
        return true;
      })
      .catch(err=>{
        notify(err.message);
        return false;
      })
    ;
  };

  const panelExpansionHandler = panelName => (ev, isExpanded) => {
    setExpandedPanel(isExpanded ? panelName : false);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item><Typography variant="h5">Email Templates</Typography></Grid>
        <Grid item><IconButton onClick={reload}><FontAwesomeIcon icon="sync" /></IconButton></Grid>
      </Grid>
      <LinearProgress show={loading} />
      <div style={{ marginBottom: 16 }} />

      {!!email_templates && Object.keys(email_templates).map(templateType=>(
        <EmailTemplateEditingPanel
          key={templateType}
          template={email_templates[templateType]}
          label={templateType}
          disabled={!!loading}
          expanded={expandedPanel === templateType}
          onExpansionChange={panelExpansionHandler(templateType)}
          onSave={templateSaveHandler(templateType)}
        />
      ))}
    </div>
  );
}
