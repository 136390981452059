import {useState, useRef, useEffect, useCallback} from "react";
import {copyTextToClipboard} from "../system/utils/parse-momenttrack-id";

export default function useCopy(str: string) {
  const copyableString = useRef(str);
  const [copied, setCopied] = useState(false);

  const copyAction = useCallback(() => {
    copyTextToClipboard(copyableString.current).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    });
  }, [copyableString]);

  useEffect(() => {
    copyableString.current = str;
  }, [str]);

  return {copied, copyAction, setCopied};
}
