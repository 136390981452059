
import moment from 'moment/moment';

function Checkin(data) {
  if(typeof data !== 'object')
    return null;

  Object.assign(this, {
    person_visiting: '',
    time_in: 0,
    time_out: 0

  }, data);

  if(!(this.time_in instanceof Date)){
    // Assume its unix "number of seconds"
    this.time_in = moment.unix(this.time_in).toDate();
  }
  this.time_in.toJSON = function(){ return moment(this).unix(); }
  this.time_in.toString = function(){ return moment(this).format('ddd, MMMM Do - hh:mm a') }

  if(!(this.time_out instanceof Date)){
    // Assume its unix "number of seconds"
    this.time_out = moment.unix(this.time_out).toDate();
  }
  this.time_out.toJSON = function(){ return moment(this).unix(); }
  this.time_out.toString = function(){ return moment(this).format('ddd, MMMM Do - hh:mm a') }
}

const proxyHandler = {
  set: function(obj, prop, value) {
    switch(prop){
      case 'time_in':
      case 'time_out':
        if(value instanceof Date)
          obj[prop] = value;
        else // Assume its unix "number of seconds"
          obj[prop] = moment.unix(value).toDate();

        obj[prop].toJSON = function(){ return moment(this).unix(); }
        obj[prop].toString = function(){ return moment(this).format('ddd, MMMM Do - hh:mm a') }
        break;

      default:
        obj[prop] = value;
    }

    return true;
  }
};

function CheckinProxy(...args) {
  return new Proxy(new Checkin(...args), proxyHandler);
}

export default CheckinProxy;
