
import moment from 'moment/moment';
import React from "react";

const dynamicValueRegExp = /\$\{\w+(\.\w+)?\}/ig;

function readPropFromObject(propStr = '', obj = {}) {
  const propParts = propStr.split('.');

  if (!propParts.length)
    return undefined;

  let propLevel = 0;
  let value = obj;

  while (typeof value === 'object' && propLevel < propParts.length) {
    value = value[propParts[propLevel]];
    propLevel++;
  }

  if (propLevel < propParts.length)
    return undefined;

  return value;
}

export default function parseTemplate(template, data = {}) {
  template = template.toString();
  const expressions = template.match(dynamicValueRegExp);
  const sourceData = {
    current_time: moment().format('MMMM DD, YYYY'),
    ...data
  };

  if (Array.isArray(expressions))
    expressions.forEach(exp => {
      const prop = exp.match(/\w+(\.\w+)?/ig)[0]; // Since exp is of known format from above dynamicValueRegExp.
      template = template.replace(exp, readPropFromObject(prop, sourceData));
    });

  return template;
}


export const LOGO_TEMPLATE = () => `
<svg
version="1.0"
xmlns="http://www.w3.org/2000/svg"
width="2025.000000pt"
height="341.000000pt"
class="logo_img"
viewBox="0 0 2025.000000 341.000000"
preserveAspectRatio="xMidYMid meet"
>
<g
  transform="translate(0.000000,341.000000) scale(0.100000,-0.100000)"
  fill="#ec1c24"
  stroke="none"
>
  <path
    d="M0 1700 c0 -1673 0 -1680 20 -1680 11 0 20 2 20 4 0 11 471 3315 475
3334 l5 22 -260 0 -260 0 0 -1680z"
  />
  <path
    d="M1424 3363 c3 -10 114 -475 246 -1033 132 -558 244 -1023 248 -1033
5 -11 96 357 251 1015 135 568 247 1041 249 1051 4 16 -25 17 -498 17 -473 0
-501 -1 -496 -17z"
  />
  <path
    d="M3330 3373 c-1 -5 106 -758 237 -1675 213 -1493 240 -1668 255 -1668
17 0 18 85 18 1675 l0 1675 -255 0 c-140 0 -255 -3 -255 -7z"
  />
  <path
    d="M3940 3370 c0 -5 14 -10 30 -10 l30 0 0 -80 c0 -47 4 -80 10 -80 6 0
10 33 10 80 l0 80 30 0 c17 0 30 5 30 10 0 6 -30 10 -70 10 -40 0 -70 -4 -70
-10z"
  />
  <path
    d="M4090 3291 c0 -54 4 -91 10 -91 6 0 10 29 10 67 0 75 7 74 40 -9 12
-32 25 -58 29 -58 4 0 21 30 38 68 l31 67 1 -67 c1 -38 5 -68 11 -68 6 0 10
37 10 91 0 78 -2 90 -16 87 -9 -2 -27 -30 -41 -65 -14 -35 -28 -63 -32 -63 -5
0 -19 28 -32 62 -15 38 -31 64 -42 66 -15 3 -17 -7 -17 -87z"
  />
  <path
    d="M14400 2604 l0 -117 308 4 307 4 3 -882 2 -883 130 0 130 0 0 885 0
885 310 0 310 0 0 110 0 110 -750 0 -750 0 0 -116z"
  />
  <path
    d="M18630 1725 l0 -995 130 0 130 0 0 274 0 275 67 75 67 75 63 -87 c35
-48 147 -204 251 -347 l187 -260 158 -3 c86 -1 157 1 157 4 0 4 -138 192 -307
418 -169 226 -311 416 -316 423 -6 8 79 105 265 303 150 160 275 295 276 300
2 6 -54 10 -150 10 l-153 0 -227 -267 c-125 -148 -251 -299 -280 -337 l-53
-69 -3 602 -2 601 -130 0 -130 0 0 -995z"
  />
  <path
    fill="black"
    d="M4894 1723 c-78 -544 -140 -991 -137 -995 7 -12 511 -10 518 2 3 5
31 189 61 407 30 219 58 406 62 415 4 10 47 -152 106 -402 l99 -420 252 0 252
0 98 420 c54 231 101 418 103 416 2 -3 121 -824 122 -843 0 -2 119 -3 264 -3
l265 0 -5 32 c-3 18 -67 465 -143 993 l-137 960 -261 3 c-203 2 -262 0 -265
-10 -3 -7 -69 -286 -148 -620 -79 -334 -145 -607 -146 -605 -1 1 -66 270 -144
597 -77 327 -143 605 -147 618 l-5 22 -262 0 -261 0 -141 -987z"
  />
  <path
    fill="black"
    d="M13850 2340 l0 -160 -90 0 -90 0 0 -192 0 -193 -28 80 c-79 225 -235
334 -477 335 -154 0 -272 -47 -377 -150 l-66 -66 -6 28 c-3 15 -8 57 -12 93
l-7 65 -234 0 c-220 0 -235 -1 -228 -17 4 -10 9 -336 12 -725 l6 -708 263 0
264 0 0 431 c0 281 4 445 11 474 14 56 69 120 115 135 49 17 125 8 162 -18 17
-12 41 -43 54 -70 23 -47 23 -48 26 -499 l3 -453 270 0 271 0 -5 498 c-3 273
-8 512 -12 530 l-7 32 90 0 90 0 4 -342 c5 -374 9 -402 68 -524 53 -109 164
-186 308 -214 76 -15 312 -8 387 12 l50 13 3 201 2 201 -99 -1 c-114 -1 -141
11 -176 80 -18 35 -20 64 -23 307 l-4 267 151 0 151 0 0 195 0 195 -150 0
-150 0 0 160 0 160 -260 0 -260 0 0 -160z"
  />
  <path
    d="M16735 2209 c-145 -16 -384 -100 -372 -132 2 -7 15 -41 27 -77 13
-36 26 -68 30 -73 3 -4 40 8 81 27 99 46 219 70 323 64 185 -10 287 -109 303
-292 l6 -76 -94 0 c-259 0 -509 -76 -637 -194 -98 -92 -142 -196 -142 -342 0
-98 25 -179 78 -249 82 -108 181 -156 337 -163 167 -7 297 40 411 150 34 33
64 56 67 51 3 -4 8 -45 12 -91 l7 -82 120 0 120 0 -6 38 c-4 20 -11 255 -15
522 -9 528 -9 535 -71 668 -87 187 -306 281 -585 251z m395 -897 c0 -157 0
-160 -30 -219 -54 -108 -145 -174 -268 -195 -173 -29 -297 58 -310 217 -18
225 163 349 516 354 l92 1 0 -158z"
  />
  <path
    fill="black"
    d="M7507 2195 c-411 -78 -649 -411 -605 -848 25 -253 161 -458 370 -562
144 -70 225 -88 398 -88 170 0 256 19 385 83 191 94 306 233 367 447 30 104
33 351 5 453 -68 247 -249 429 -497 500 -99 29 -311 36 -423 15z m254 -385
c47 -25 92 -90 117 -170 23 -78 24 -304 0 -380 -41 -132 -114 -196 -214 -188
-77 7 -125 42 -163 120 -42 85 -54 161 -48 298 7 173 50 275 135 320 49 26
122 26 173 0z"
  />
  <path
    fill="black"
    d="M9252 2195 c-90 -25 -163 -67 -231 -132 -36 -35 -67 -63 -67 -63 -1
0 -5 33 -9 73 -11 119 15 108 -238 105 l-222 -3 1 -30 c1 -16 5 -344 9 -728
l8 -697 256 2 256 3 5 465 5 465 24 41 c62 110 216 112 271 5 33 -66 40 -157
40 -579 l0 -402 258 2 257 3 5 465 c5 464 5 465 28 505 33 57 67 78 132 83 94
6 147 -46 170 -167 6 -31 10 -232 10 -472 l0 -419 261 0 260 0 -3 512 c-4 493
-5 516 -26 593 -60 216 -177 338 -361 376 -77 16 -222 6 -296 -20 -76 -27
-156 -80 -220 -146 l-50 -52 -34 53 c-42 64 -111 118 -188 146 -76 27 -236 34
-311 13z"
  />
  <path
    fill="black"
    d="M11355 2193 c-270 -57 -464 -248 -547 -538 -33 -114 -33 -334 0 -448
37 -128 85 -212 171 -297 88 -87 171 -135 306 -176 79 -23 112 -27 270 -31
133 -4 207 -1 284 11 136 21 281 64 281 84 0 8 -14 89 -31 179 l-32 165 -26
-6 c-14 -3 -57 -12 -96 -21 -38 -8 -133 -18 -210 -21 -163 -7 -256 8 -339 54
-45 26 -96 80 -96 103 0 5 200 9 445 9 415 0 446 1 453 18 4 9 7 98 6 197 0
167 -2 186 -28 270 -40 130 -80 199 -166 286 -85 87 -176 136 -297 163 -89 20
-254 20 -348 -1z m263 -365 c44 -29 77 -88 88 -155 l7 -43 -217 0 -216 0 6 28
c21 88 79 164 146 189 53 20 140 11 186 -19z"
  />
  <path
    d="M16150 2193 c-108 -38 -200 -124 -256 -240 l-28 -58 -7 55 c-4 30 -7
94 -8 143 l-1 87 -117 0 -116 0 6 -107 c4 -58 7 -384 7 -725 l0 -618 129 0
130 0 3 448 c4 423 5 450 25 509 40 120 110 204 207 249 41 19 68 24 139 24
l88 0 -3 123 -3 122 -75 2 c-47 1 -92 -4 -120 -14z"
  />
  <path
    d="M18025 2196 c-33 -8 -94 -27 -136 -43 -215 -84 -373 -270 -430 -508
-18 -77 -20 -111 -17 -235 4 -130 8 -155 37 -239 17 -52 48 -122 69 -155 82
-136 229 -242 399 -288 103 -27 320 -30 437 -4 114 25 186 54 186 74 0 20 -36
171 -42 177 -2 2 -33 -6 -68 -19 -140 -51 -306 -57 -426 -16 -168 58 -286 202
-320 389 -55 308 84 575 338 652 59 18 88 21 201 17 114 -4 141 -8 197 -31 35
-15 67 -23 71 -19 15 15 58 187 49 196 -5 5 -46 20 -92 34 -70 21 -108 25
-238 28 -107 2 -174 -1 -215 -10z"
  />
  <path
    d="M1141 1459 c-1 -18 -188 -1359 -196 -1401 l-4 -28 275 0 c258 0 275
1 270 18 -3 9 -80 330 -171 712 -91 382 -167 700 -169 705 -3 6 -5 3 -5 -6z"
  />
  <path
    d="M2531 762 c-91 -383 -167 -705 -169 -714 -4 -17 14 -18 267 -18 242
0 271 2 271 16 0 31 -191 1379 -198 1396 -4 11 -74 -265 -171 -680z"
  />
  <path
    d="M19945 890 c3 -5 17 -10 31 -10 23 0 24 -2 24 -75 0 -43 4 -75 10
-75 6 0 10 32 10 75 0 73 1 75 25 75 14 0 25 5 25 10 0 6 -29 10 -66 10 -41 0
-63 -4 -59 -10z"
  />
  <path
    d="M20080 816 c0 -51 4 -86 10 -86 6 0 10 30 11 68 l0 67 33 -70 34 -70
24 55 c14 30 28 62 31 70 3 8 6 -15 6 -52 1 -38 5 -68 11 -68 6 0 10 35 10 85
0 107 -16 112 -55 20 l-28 -66 -26 63 c-16 38 -33 64 -44 66 -15 3 -17 -7 -17
-82z"
  />
</g>
</svg>
`

export function RenderLinksInString({ inputString }) {
  if (!inputString) {
    return null;
  }

  const parts = [];
  let currentText = '';
  let insideLink = false;
  let insideUrl = false;
  let urlText = '';

  for (let i = 0; i < inputString.length; i++) {
    const char = inputString[i];

    if (insideLink) {
      if (char === ']') {
        insideLink = false;
        insideUrl = true;
      } else {
        currentText += char;
      }
    } else if (insideUrl) {
      if (char === ')') {
        insideUrl = false;
        parts.push(
          <a
            key={i}
            href={urlText.replace("(", "")}
            style={{ color: '#ec1c24', textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {currentText}
          </a>
        );
        currentText = '';
        urlText = '';
      } else {
        urlText += char;
      }
    } else if (char === '[') {
      insideLink = true;
      parts.push(<span key={i}>{currentText}</span>);
      currentText = '';
    } else {
      currentText += char;
    }
  }

  if (currentText) {
    parts.push(<span key={parts.length}>{currentText}</span>);
  }

  return <>{parts}</>;
}




