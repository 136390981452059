
import { momentTrackService } from '../../system/legacy-services/moment-track';
import {
  getCompanyLogo,
  getLocation,
  getLicencePlate,
  getEmployee
} from '../../system/legacy-services/guest-track';

export const ACTION_TYPES = {
  SET_AUTH: 'SET_CHECKIN_AUTH',
  UNSET_AUTH: 'UNSET_CHECKIN_AUTH',
  SET_LOADING: 'SET_CHECKIN_AUTH_LOADING',
  SET_COMPANY_PROFILE: 'SET_COMPANY_PROFILE'
};

function setLoading(msg='') {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: msg
  };
}

export function setCheckinAuth(payload) {
  return {
    type: ACTION_TYPES.SET_AUTH,
    payload
  };
}

function setCompanyProfile(payload) {
  return {
    type: ACTION_TYPES.SET_COMPANY_PROFILE,
    payload
  };
}

export function loadEmployee(person_url) {
  return async dispatch => {
    try{
      const idInfo = momentTrackService.parseId(person_url);
      if(idInfo.type !== 'person')
        throw new Error('Invalid id. Please scan a valid id badge.');

      dispatch(setLoading('Processing...'));
      return await getEmployee(person_url);
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function loadLocation(location_id) {
  return async dispatch => {
    try{
      const idInfo = momentTrackService.parseId(location_id);
      if(idInfo.type !== 'location')
        throw new Error('Invalid id. Please scan a valid location id.');

      dispatch(setLoading('Processing...'));
      return await getLocation(location_id);
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function loadPrinter(licence_plate_id) {
  return async dispatch => {
    try{
      const idInfo = momentTrackService.parseId(licence_plate_id);
      if(idInfo.type !== 'licence_plate')
        throw new Error('Invalid id. Please scan a valid printer id.');

      dispatch(setLoading('Processing...'));
      return await getLicencePlate(licence_plate_id);
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function loadCompanyProfile(company_code) {
  return async dispatch => {
    try{
      if(!company_code)
        throw new Error('Authentication required before loading company profile.');

      dispatch(setLoading('Loading profile...'));
      const company_logo = await getCompanyLogo(company_code);

      const companyProfile = {
        company_code,
        company_logo: company_logo + `?_t=${Date.now()}`
      };

      dispatch(setCompanyProfile(companyProfile));
      return companyProfile;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function logout(person_url) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.companyProfile.company_code;

      dispatch(setLoading('Verifying...'));
      const employee = await getEmployee(person_url);

      if(employee.company_code !== company_code)
        throw new Error('Please use an id from same company to logout.');

      dispatch({ type: ACTION_TYPES.UNSET_AUTH })
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}
