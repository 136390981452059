import React from "react";
import { Skeleton } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const LoadingTable = ({ rows = 10, cols = 4 }) => {
	return (
		<>
			<Box style={{ width: "100%" }}>
				<Paper
					style={{
						boxShadow: "none",
						borderRadius: "8px",
						width: "100%",
						marginBottom: 16,
					}}
				>
					<TableContainer>
						<Table style={{ minWidth: 750 }} aria-labelledby="tableTitle">
							<TableBody>
								{Array.from(new Array(rows)).map((_el, i) => {
									return (
										<TableRow key={"row-" + i} hover role="checkbox">
											{Array.from(new Array(cols)).map((_el, k) => (
												<TableCell key={"col-" + k} align="left">
													<Skeleton animation="wave" width="100%" height={20} />
												</TableCell>
											))}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Box>
		</>
	);
};

export default LoadingTable;
