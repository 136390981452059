
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const DynamicWebForm = function(props) {
  const {
    formFields = [],
    onSubmit = ()=>{},
    disabled,
    children
  } = props;

  const [values, setValues] = useState({});

  function updateValue(fieldTitle, value) {
    setValues(values=>({ ...values, [fieldTitle]: {value} }));
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    onSubmit(values);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={3}>
        {formFields.map(field=>(
          <Grid key={field.field_title} item>
            {field.field_input_type === 'string' && (
              <TextField
                fullWidth
                variant="outlined"
                label={field.field_title}
                value={(values[field.field_title] || {}).value || ''}
                onChange={(ev)=>updateValue(field.field_title, ev.target.value)}
                required
                disabled={disabled}
              />
            )}

            {field.field_input_type === 'radio' && (
              <TextField
                fullWidth
                variant="outlined"
                label={field.field_title}
                value={(values[field.field_title] || {}).value || ''}
                onChange={(ev)=>updateValue(field.field_title, ev.target.value)}
                select
                SelectProps={{ native: true }}
                required
                disabled={disabled}
              >
                <option value=""></option>
                {field.values.map(value=>(
                  <option key={''+value.value} value={value.value}>{ value.value }</option>
                ))}
              </TextField>
            )}

            {field.field_input_type === 'boolean' && (
              <FormControlLabel
                label={field.field_title}
                control={
                  <Checkbox
                    checked={(values[field.field_title] || {}).value || false}
                    onChange={(ev, checked)=>updateValue(field.field_title, checked)}
                    required
                    disabled={disabled}
                  />
                }
              />
            )}
          </Grid>
        ))}

        {!!children && (
          <Grid item>
            { children }
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default DynamicWebForm;
