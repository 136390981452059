import React from "react";
import { useAuthentication } from "../../../core/providers/AuthenticationProvider";
import ActivityForm from "./ActivityForm";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import MoveAllForm from "../MoveAll/MoveAllForm";

const Activity = function (props) {
  const authenticated = !!useAuthentication()[0];
  const currentLocation = useLocation();

  if (!authenticated) {
    return (
      <Container maxWidth="xs">
        <Box py={3}>
          <Paper>
            <Box p={2} textAlign="center">
              <Typography
                align="center"
                color="textSecondary"
                component="p"
                gutterBottom
              >
                Authentication required.
              </Typography>
              <Link
                component={RouterLink}
                to={{
                  pathname: "/login",
                  state: { referrer: currentLocation },
                }}
                underline="none"
              >
                <Button size="large" variant="contained" color="primary">
                  Login
                </Button>
              </Link>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }

  return props.idInfo.id === "move_all" ? (
    <MoveAllForm />
  ) : (
    <ActivityForm {...props} />
  );
};

export default Activity;
