import React, {useMemo} from "react";
import Box from "@material-ui/core/Box";
import {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {Container, Grid, CircularProgress} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import MultiCodeScanner from "../../core/components/MultiCodeScanner";
import parseDataMatrix, {
  editDataMatrix,
} from "../../core/system/utils/parse-datamatrix";
import QrCodeSearchInput from "../../core/components/QrCodeSearchInput";
import {useMainToolbar} from "../../MainLayout";
import moment from "moment";
import {generateDatamatrixLabel} from "../../core/system/documents";
import useNotify from "../../core/hooks/use-notify";
import TextField from "@material-ui/core/TextField";
import {createProduct} from "../../core/system/products";
import {
  createLicensePlate,
  createOrder,
} from "../../core/system/production-orders";
import ErrorDisplay from "../../core/components/ErrorDsiplay";
import {isObjectEmpty} from "../../core/system/utils";

type Props = {
  qrCode: string;
};

const DataMatrixTemplate: React.FC<Props> = props => {
  const history = useHistory();
  const [parsedData, setparsedData] = useState<Record<string, string>>({});
  const location = useLocation<{datamatrix_string?: string}>();
  const [qrCode, setQrCode] = useState(location.state?.datamatrix_string || "");
  const [generatingLabel, setgeneratingLabel] = useState(false);
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [curStatus, setCurStatus] = useState("");

  useEffect(() => {
    let parsedData = parseDataMatrix(qrCode);
    const data = {
      quantity: Object.entries(parsedData)?.find(el =>
        el[0].includes("Quantity")
      ),
      customerPN: Object.entries(parsedData)?.find(el =>
        el[0].includes("Customer P")
      ),
      supplierPN: Object.entries(parsedData)?.find(el =>
        el[0].includes("Supplier Part")
      ),
    };

    setError(undefined);
    // create a licenseplate with prodcution order

    async function createProductionOrder() {
      setLoading(true);
      try {
        setCurStatus("Creating product");
        const product = await createProduct({
          name:
            "New Datamatrix product " +
            (data.supplierPN ? data.supplierPN[1] : ""),
          part_number: data.supplierPN ? data.supplierPN[1] : "",
        });
        setCurStatus("Creating order");
        const newOrder = await createOrder(product?.id as number, {
          requested_qty: parseFloat(data.quantity ? data.quantity[1] : "0"),
          external_docid: data.customerPN ? data.customerPN[1] : "",
        });
        setCurStatus("Creating license plate");

        await createLicensePlate({
          quantity: parseFloat(data.quantity ? data.quantity[1] : "0"),
          product_id: product?.id,
          production_order_id: newOrder.id,
        });
        setCurStatus("Syncing data");

        // add MT's code
        setQrCode(prev =>
          editDataMatrix(prev, "MT", `${product.id}-${newOrder.docid}`)
        );
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    }

    // only go ahead to call the function if the MT (Momentrack code) tag number is tehre
    if (
      Object.entries(parsedData)?.find(el => el[0].includes("(MT)")) ===
        undefined &&
      !!qrCode.trim()
    ) {
      createProductionOrder();
    } else {
      setLoading(false);
    }
  }, [notify, qrCode]);

  useEffect(() => {
    if (qrCode) {
      setparsedData(parseDataMatrix(qrCode));
    }
  }, [qrCode]);

  useEffect(() => {
    if (location.state?.datamatrix_string) {
      setQrCode(location.state?.datamatrix_string);
    }
  }, [location.state?.datamatrix_string]);

  const toolbarContent = useMemo(
    () => (
      <Container disableGutters maxWidth="sm">
        <QrCodeSearchInput
          defaultValue={location.state?.datamatrix_string}
          placeholder="Scan/Paste id to lookup..."
          onSearch={v => history.push(`/${v}`)}
        />
      </Container>
    ),
    [history, location.state?.datamatrix_string]
  );

  useMainToolbar(toolbarContent);
  return (
    <Box pt={10}>
      <Container maxWidth="sm">
        <Box mb={5} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Datamatrix
          </Typography>
          <Typography variant="body2">
            Start by scanning a datamatrix code to display information about the
            product
          </Typography>
        </Box>

        {!isObjectEmpty(parsedData) && (
          <Box style={{maxWidth: "4.5in", margin: "0 auto 40px auto"}}>
            <Card>
              {loading ? (
                <>
                  <CardContent
                    style={{
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box textAlign="center" py={2}>
                      <CircularProgress size={48} />
                    </Box>
                    <Typography>{curStatus}...</Typography>
                  </CardContent>
                </>
              ) : error ? (
                <CardContent>
                  <ErrorDisplay error={error} />
                </CardContent>
              ) : (
                <>
                  <CardContent>
                    {Object.entries(parsedData)
                      .filter(el => !el[0].includes("MT"))
                      .map(([key, value]) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.1rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <span
                            style={{fontWeight: 500, marginRight: "0.5rem"}}
                          >
                            {key}:
                          </span>
                          {key.includes("(Q)") ? (
                            <TextField
                              placeholder="0"
                              defaultValue={value || 0}
                              value={parsedData["(Q) Quantity"] || "0"}
                              onChange={e => {
                                e.persist();
                                setparsedData(prev => ({
                                  ...prev,
                                  "(Q) Quantity": e.target?.value || "0",
                                }));
                                setQrCode(prev =>
                                  editDataMatrix(
                                    prev,
                                    "(Q) Quantity",
                                    e.target.value || "0"
                                  )
                                );
                              }}
                              style={{width: "10ch", fontWeight: 700}}
                              inputProps={{
                                style: {
                                  fontWeight: 700,
                                  fontSize: "1rem",
                                },
                              }}
                            />
                          ) : (
                            <span style={{fontWeight: 700, fontSize: "1rem"}}>
                              {value}
                            </span>
                          )}
                          {!key.includes("(MT)") && (
                            <p style={{width: "100%", margin: "0.5rem 0 0 0"}}>
                              <img
                                style={{height: "40px"}}
                                src={`https://barcode.tec-it.com/barcode.ashx?data=${value}&code=Code39&dmsize=Default`}
                                alt="barcode"
                              />
                            </p>
                          )}
                        </div>
                      ))}
                  </CardContent>
                  <Divider light />
                  <CardActions
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      alt="place qr code"
                      src={`https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${
                        Object.entries(parsedData)
                          ?.find(el => el[0].includes("MT"))?.[1]
                          ?.split("-")[1] || ""
                      }&logo=momenttrack`}
                      style={{width: "0.75in"}}
                    />
                    <div style={{fontWeight: 600}}>
                      <p>RoHS Compliant</p>
                      <p>Seal Date: {moment().format("DD/MM/YYYY")}</p>
                    </div>
                    <img
                      alt="place qr code"
                      src={`https://barcode.tec-it.com/barcode.ashx?data=${qrCode}&code=MobileSemaUrl`}
                      style={{width: "0.75in"}}
                    />
                  </CardActions>
                </>
              )}
            </Card>
          </Box>
        )}

        <Grid
          container
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <MultiCodeScanner
              onResult={(res: string) => setQrCode(res)}
              label={
                !isObjectEmpty(parsedData)
                  ? "Scan Another Code"
                  : "Scan Datamatrix Code"
              }
              showScanButton
              //   disabled={loading}
              ButtonProps={{
                variant: "contained",
                color: "primary",
              }}
            />
          </Grid>
          {!isObjectEmpty(parsedData) && !error && (
            <Grid item>
              <Button
                size="large"
                disabled={generatingLabel}
                onClick={async () => {
                  try {
                    setgeneratingLabel(true);
                    let pdfUrl = await generateDatamatrixLabel(
                      parsedData,
                      qrCode
                    );
                    window.open(pdfUrl, "_blank");
                  } catch (error) {
                    notify(error as Error);
                  } finally {
                    setgeneratingLabel(false);
                  }
                }}
                color="primary"
                variant="outlined"
                startIcon={<Icon>print</Icon>}
              >
                {!generatingLabel ? "Print Label" : "Generating label..."}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default DataMatrixTemplate;
