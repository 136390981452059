import axios from 'axios';
const authurl = "https://api.3hd.us/app/digikey.Digikeyauth"
const sentqrcode = "https://api.3hd.us/app/digikey.Digikeyinsert"
const mouserurl = "https://api.3hd.us/app/digikey.Mouserinsert"
export async function Accesstoken(authcode) {
    try{
      const datas = {
        code : authcode
      }
      const response1 = await axios.post(authurl, datas, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      });
      if(!response1)
        throw new Error('Failed');
      
      return (response1);
    }
    catch(err){
      console.dir(err);
      throw err;
    }
}
export async function sendtoapinode(matrix, accesstoken, currentUser) {
  try{
    const datas = {
      qrcode : matrix,
      accesstoken : accesstoken,
      currentUser : currentUser.person_id
    }
    const response1 = await axios.post(sentqrcode, datas, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
      }
    });
    if(!response1)
      throw new Error('Failed');
    
    return (response1);
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}
export async function sendapimouser(matrix, currentUser) {
  try{
    const datas = {
      qrcode : matrix,
      currentUser : currentUser.person_id
    }
    const response1 = await axios.post(mouserurl, datas, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
      }
    });
    if(!response1)
      throw new Error('Failed');
    
    return (response1);
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}