import React, { ReactElement, useEffect } from "react";
import { Place } from "../../system/places";
import usePlace from "./use-place";

export interface PlaceProps {
  id?: number;
  onGetPlaceInfo?: (place: Place) => void;
  children: (place: Place | null, loading: boolean) => ReactElement;
}

const PlaceWrap: React.FC<PlaceProps> = (props) => {
  const [place, loading] = usePlace(props.id);

  useEffect(() => {
    if (!place || !props.onGetPlaceInfo) return;
    props.onGetPlaceInfo(place);
    // eslint-disable-next-line
  }, [place]);

  return props.children(place, loading);
};

export default PlaceWrap;
