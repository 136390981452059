import React, { useState, useRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useNotify from "../core/hooks/use-notify";
import {
  loadEmployee,
  loadLocation,
  loadPrinter,
  setCheckinAuth,
  loadCompanyProfile,
} from "../core/redux/actions/checkin-auth-actions";
import QrCodeScanner from "../core/components/QrCodeScanner";
import GuestTrackLogo from "../assets/images/guest-track-logo.png";
import { useAuthentication } from "../core/providers/AuthenticationProvider";
import { getAllPlaces } from "../core/system/places";

const Login = (props) => {
  const { loadId, setCheckinAuth, loadCompanyProfile, loading, history } =
    props;

  const notify = useNotify();
  const adminQrScanner = useRef();
  const [authData, setAuthData] = useState({});
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const userAgent = window.navigator.userAgent;
  const [person] = useAuthentication();
  const isLoggedIn = Boolean(person);
  const [places, setPlaces] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const fetchPlaces = async () => {
    try {
      setLoadingData(true);
      const places = await getAllPlaces();
      setPlaces(places);
    } catch (err) {
      notify(err.message);
    }
    setLoadingData(false);
  };

  const fetchEmployee = async (id) => {
    const url = `3hd.us/${id}`;
    try {
      const idData = await loadId(url, "access_rights");
      setAuthData((data) => ({ ...data, access_rights: idData }));
    } catch (err) {
      notify(err.message);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchEmployee(person.person_id);
    fetchPlaces();
    // eslint-disable-next-line
  }, [person]);

  useEffect(() => {
    if (!selectedPlace) return;
    setAuthData((data) => ({ ...data, location: selectedPlace }));
  }, [selectedPlace]);

  const qrResultHandler = (idType) => async (result) => {
    try {
      const idData = await loadId(result, idType);
      setAuthData((data) => ({ ...data, [idType]: idData }));
    } catch (err) {
      notify(err.message);
    }
  };

  const continueLoginHandler = () => {
    const company_code = authData.access_rights.company_code;

    loadCompanyProfile(company_code).then((profile) => {
      setTimeout(() => setCheckinAuth(authData), 0);
      const logo = new Image();
      logo.src = profile.company_logo;
    });
  };

  const adminConsoleAuthHandler = async (person_url) => {
    try {
      let access_rights = authData.access_rights;

      if (!access_rights && person_url)
        access_rights = await loadId(person_url, "access_rights");
      else if (!access_rights) return adminQrScanner.current.openScanner();

      setCheckinAuth({ access_rights });
      history.push("/guest-track/console");
    } catch (err) {
      notify(err.message);
    }
  };

  const placesOptions = useMemo(() => {
    if (!places) return null;
    return [...places].sort((a, b) => a.name.localeCompare(b.name));
  }, [places]);

  const idBadgeLoaded = !!authData.access_rights;
  const locationLoaded = !!authData.location;
  const printerLoaded = !!authData.licence_plate;
  const canContinue = idBadgeLoaded && locationLoaded && printerLoaded;

  return (
    <div className="guest-track-login-page">
      <Container maxWidth="md">
        <Container maxWidth="sm" style={{ marginLeft: 0, padding: 0 }}>
          <img
            src={GuestTrackLogo}
            className="main-logo"
            alt="Guest track logo"
          />
        </Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm>
            <Typography variant="h4" gutterBottom>
              Setup
            </Typography>
            <Stepper orientation="vertical" className="login-steps">
              <Step active completed={idBadgeLoaded}>
                <StepLabel onClick={() => console.log("clicked")}>
                  {isLoggedIn ? "ID badge" : "Scan ID badge"}
                </StepLabel>
                <StepContent>
                  {idBadgeLoaded && (
                    <div className="loaded-info">
                      <Typography variant="caption">
                        <i>{authData.access_rights.person_url || null}</i>
                      </Typography>
                      <Typography variant="h6">{`${
                        authData.access_rights.person_first || null
                      } ${
                        authData.access_rights.person_last || null
                      }`}</Typography>
                      <Typography variant="subtitle2">
                        {authData.access_rights.company_name || null}
                      </Typography>
                    </div>
                  )}
                  {!isLoggedIn && (
                    <QrCodeScanner
                      label="Scan"
                      onResult={qrResultHandler("access_rights")}
                      disabled={!!loading}
                    />
                  )}
                </StepContent>
              </Step>
              <Step active={idBadgeLoaded} completed={locationLoaded}>
                <StepLabel>
                  {isLoggedIn ? "Select location" : "Scan location ID"}
                </StepLabel>
                <StepContent>
                  {locationLoaded && !isLoggedIn && (
                    <div className="loaded-info">
                      <Typography variant="caption">
                        <i>{authData.location.location_url}</i>
                      </Typography>
                      <Typography variant="h6">
                        {authData.location.name}
                      </Typography>
                    </div>
                  )}
                  {places && (
                    <>
                      <Autocomplete
                        id="station-selector"
                        options={placesOptions}
                        getOptionLabel={(option) => option.name}
                        value={selectedPlace}
                        onChange={(ev, val) => setSelectedPlace(val)}
                        getOptionSelected={(opt, val) => val.id === opt.id}
                        disabled={loadingData || !!loading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search..."
                            variant="outlined"
                          />
                        )}
                      />
                    </>
                  )}
                  {!isLoggedIn && (
                    <QrCodeScanner
                      label="Scan"
                      onResult={qrResultHandler("location")}
                      disabled={!!loading}
                    />
                  )}
                </StepContent>
              </Step>
              <Step active={locationLoaded} completed={printerLoaded}>
                <StepLabel>Scan printer ID</StepLabel>
                <StepContent>
                  {printerLoaded && (
                    <div className="loaded-info">
                      <Typography variant="caption">
                        <i>{authData.licence_plate.printer_url}</i>
                      </Typography>
                      <Typography variant="h6">
                        {authData.licence_plate.printer_name}
                      </Typography>
                    </div>
                  )}
                  <QrCodeScanner
                    label="Scan"
                    onResult={qrResultHandler("licence_plate")}
                    disabled={!!loading || loadingData}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={6} sm={4}>
            {userAgent !== "replenish" && (
              <>
                <Link
                  to="/"
                  component={({ navigate, ...props }) => (
                    <Button
                      disabled={!!loading}
                      variant="outlined"
                      color="primary"
                      size="large"
                      {...props}
                    />
                  )}
                >
                  Exit
                </Link>

                <div>
                  <br />
                </div>
              </>
            )}

            {canContinue && (
              <Button
                disabled={!!loading}
                onClick={continueLoginHandler}
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
              >
                Continue ...
              </Button>
            )}

            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>

            <Button
              disabled={!!loading}
              variant="text"
              size="medium"
              onClick={() => adminQrScanner.current.openScanner()}
            >
              <b>Admin Console</b>
            </Button>

            <QrCodeScanner
              ref={adminQrScanner}
              facingMode="user"
              onResult={adminConsoleAuthHandler}
              disabled={!!loading}
              hidden
            />

            <br />

            <Button
              disabled={!!loading}
              variant="text"
              size="medium"
              color="primary"
              onClick={() => setInfoDialogOpen(true)}
            >
              <i>Don't have QR codes?</i>
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* BEGIN: info dialog */}
      <Dialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Don't have QR codes?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The GuestTrack system is meant for clients of MomentTrack only. If
            you are a MomentTrack client, please contact your representative to
            receive the necessary QRs. If you are not, feel free to reach out to
            us in a browser at 3hd.us if you are interested in using this
            system. For a demo, visit 3hd.us/guestTrackDemoNumber00001 in a
            browser to download the necessary QR codes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="secondary"
            variant="outlined"
            onClick={() => setInfoDialogOpen(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.checkinAuth.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadId: (id, type) => {
      switch (type) {
        case "access_rights":
          return dispatch(loadEmployee(id));
        case "location":
          return dispatch(loadLocation(id));
        case "licence_plate":
          return dispatch(loadPrinter(id));
        default:
          throw new Error("Unknown id type.");
      }
    },
    setCheckinAuth: (authData) => dispatch(setCheckinAuth(authData)),
    loadCompanyProfile: (companyCode, companyName) =>
      dispatch(loadCompanyProfile(companyCode, companyName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
