
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetConsoleData } from '../../core/redux/actions/admin-console-actions';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from '../../core/components/PageHeader';
import CurrentGuests from './CurrentGuests';
import NdaTemplates from './NdaTemplates';
import EmailTemplates from './EmailTemplates';
import SecuritySettings from './SecuritySettings';

export default function AdminConsole({ match, location, history }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(()=>()=>dispatch(resetConsoleData()), [dispatch]);

  const goToHome = useCallback(()=>{history.replace('/guest-track')}, [history]);

  useEffect(()=>{
    const urlPath = location.pathname || '';
    let activeTab;

    if(urlPath.endsWith('/current-guests'))
      activeTab = 0;
    else if(urlPath.endsWith('/nda-templates'))
      activeTab = 1;
    else if(urlPath.endsWith('/email-templates'))
      activeTab = 2;
    else if(urlPath.endsWith('/security-settings'))
      activeTab = 3;
    else
      return;

    setActiveTab(activeTab);

  }, [location.pathname]);

  return (
    <div className="admin-console-page">
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <IconButton size="medium" color="primary" onClick={()=>goToHome()} >
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </IconButton>
          </Grid>
          <Grid item xs>
            <PageHeader
              title="Admin Console"
              subtitle=""
            />
          </Grid>
        </Grid>

        <div><br /><br /></div>

        <Paper square>
          <Tabs value={activeTab} variant="scrollable" scrollButtons="on" >
            <Tab label="Current Guests" component={Link} to="./current-guests" />
            <Tab label="NDA Templates" component={Link} to="./nda-templates" />
            <Tab label="Email Templates" component={Link} to="./email-templates" />
            <Tab label="Security settings" component={Link} to="./security-settings" />
          </Tabs>
        </Paper>

        <div style={{ marginBottom: 16 }} />
        <Switch>
          <Route path={match.path + '/current-guests'} component={CurrentGuests} />
          <Route path={match.path + '/nda-templates'} component={NdaTemplates} />
          <Route path={match.path + '/email-templates'} component={EmailTemplates} />
          <Route path={match.path + '/security-settings'} component={SecuritySettings} />
          <Redirect to={match.path + '/current-guests'} />
        </Switch>
      </Container>
    </div>
  );
}
