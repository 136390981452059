import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import QrCodeSearchInput from "../core/components/QrCodeSearchInput";

const LandingPage: React.FC = () => {
  const history = useHistory();

  return (
    <Container maxWidth="sm">
      <Box pt={10} />
      <Typography variant="h2" align="center" gutterBottom>
        Welcome!
      </Typography>
      <QrCodeSearchInput
        placeholder="Scan/Enter QR Code to lookup..."
        onSearch={v => history.push(`/${v}`)}
      />
    </Container>
  );
};

export default LandingPage;
