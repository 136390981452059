import React, {useMemo} from "react";
import {firebaseService} from "../core/system/legacy-services/firebase";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import QrCodeSearchInput from "../core/components/QrCodeSearchInput";
import SearchTemplate from "./SearchTemplate";
import {Typography} from "@material-ui/core";
import {useMainToolbar} from "../MainLayout";

/**
 * BEGIN: "Search" component
 */
const Search = props => {
  const {match} = props;
  const idToSearch = match.params.momenttrackId;

  const [state, setState] = React.useState({
    loading: true,
    errorMessage: "",
    responseData: null,
  });
  const history = useHistory();

  React.useEffect(() => {
    if (idToSearch === "move_all") {
      setState(state => ({
        ...state,
        loading: false,
      }));
      return;
    }

    async function loadData() {
      try {
        setState(state => ({...state, loading: true}));
        const data = await firebaseService.getData(idToSearch);

        setState(state => ({
          ...state,
          responseData: data,
          errorMessage: "",
          loading: false,
        }));
      } catch (err) {
        setState(state => ({
          ...state,
          responseData: null,
          errorMessage: err.message,
          loading: false,
        }));
      }
    }

    loadData();
  }, [idToSearch]);

  const {loading, errorMessage, responseData} = state;
  const showError = !loading && !!errorMessage;
  const showData = !loading && !errorMessage;

  const toolbarContent = useMemo(
    () => (
      <Container disableGutters maxWidth="sm">
        <QrCodeSearchInput
          placeholder="Scan/Paste id to lookup..."
          defaultValue={idToSearch}
          onSearch={v => history.push(`/${v}`)}
        />
      </Container>
    ),
    [history, idToSearch]
  );

  useMainToolbar(toolbarContent);

  return (
    <div className="search-page">
      <div>
        {loading && (
          <Box pt={10} textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {showError && (
          <Box pt={10} textAlign="center">
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )}

        {showData && <SearchTemplate id={idToSearch} data={responseData} />}
      </div>
    </div>
  );
};

export default Search;
