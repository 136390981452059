import React, { ReactElement, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import parseMomenttrackId from "../core/system/utils/parse-momenttrack-id";
import { Redirect } from "react-router-dom";
import QrCodeSearchInput from "../core/components/QrCodeSearchInput";
import UnknownTemplate from "./UnknownTemplate";
import LicensePlateTemplate from "./LicensePlateTemplate";
import CustomerEngagementTemplate from "./CustomerEngagementTemplate";
import DocumentTemplate from "./DocumentTemplate";
import PlaceTemplate from "./PlaceTemplate";
import PersonTemplate from "./PersonTemplate";
import { useAuthentication } from "../core/providers/AuthenticationProvider";
import { useMainToolbar } from "../MainLayout";

const IdLookup: React.FC = () => {
	const { momenttrackId } = useParams<{ momenttrackId: string }>();
	const idInfo = parseMomenttrackId(momenttrackId);
	const history = useHistory();
	const [currentUser] = useAuthentication();

	let template: ReactElement;

	switch (idInfo.type) {
		case "unknown":
			template = <UnknownTemplate momenttrackId={momenttrackId} />;
			break;
		case "person":
			template = <PersonTemplate personId={momenttrackId} />;
			break;
		case "license_plate":
			template =
				currentUser !== null ? (
					<LicensePlateTemplate licensePlateId={momenttrackId} />
				) : (
					<CustomerEngagementTemplate qrCode={momenttrackId} />
				);
			break;
		case "document":
			template = <DocumentTemplate docid={momenttrackId} />;
			break;
		case "place":
			template = <PlaceTemplate placeId={momenttrackId} />;
			break;
		default:
			template = <Redirect to={`/${momenttrackId}/legacy`} />;
	}
	const toolbarContent = useMemo(
		() => (
			<Container disableGutters maxWidth="sm">
				<QrCodeSearchInput
					placeholder="Scan/Paste id to lookup..."
					defaultValue={idInfo.id}
					onSearch={v => history.push(`/${v}`)}
				/>
			</Container>
		),
		[history, idInfo.id]
	);

	useMainToolbar(toolbarContent);

	return template;
};

export default IdLookup;
