
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import unknownQrCodeImage from '../assets/images/unknown_qr_code.png';

const UnknownTemplate: React.FC<{momenttrackId?: string}> = ({momenttrackId}) => {
  return (
    <Container maxWidth="sm">
      <Box pt={10} mb={4} textAlign="center">
        <img alt="Unknown qr code" src={unknownQrCodeImage} style={{ maxWidth: '100%' }} />
      </Box>
      <Typography variant="h5" gutterBottom align="center">Unknown qr code</Typography>
      <Typography color="textSecondary" align="center">Could no identify type of this QR code.</Typography>
      <Typography color="textSecondary" align="center" style={{fontFamily: 'monospace'}}>{process.env.REACT_APP_QR_BASENAME || '3hd.us'}/{momenttrackId || '...???'}</Typography>
    </Container>
  );
};

export default UnknownTemplate;
