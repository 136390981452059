import {lookupProductionOrder} from "../production-orders";
import {lookupTraining} from "../litmon";
import getDatamatrixLabel from "./datamatrix-template/datamatrix-template";
import htmlToPdf from "../utils/html-to-pdf";

export const lookupDocument = (docid: string) => {
  return lookupProductionOrder(docid).catch(() => lookupTraining(docid));
};

export const generateDatamatrixLabel = (
  parsedData: Record<string, string>,
  qrCode: string
): Promise<string> => {
  const labelsHtml = getDatamatrixLabel(parsedData, qrCode);

  return htmlToPdf(labelsHtml, {
    pdf_mode: "portrait",
    pdf_name: `datamatrix-label`,
    page_width: "101.6mm",
    // page_height: "152.6mm",
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  });
};
