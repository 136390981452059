
import React, { useRef, useState, useEffect } from 'react';
import { QrCodeInputProps } from './types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import QrCodeScanner from '../QrCodeScanner';
import styles from './styles';

const useStyles = makeStyles(styles);

const QrCodeInput: React.FC<QrCodeInputProps> = ({
  placeholder,
  disabled,
  onChange,
  autoFocus

}) => {
  const classes = useStyles();
  const scanner = useRef<any>(null);
  const textField = useRef<any>(null);
  const [val, setVal] = useState('');

  useEffect(()=>{
    const immId = setImmediate(()=>autoFocus && textField.current && textField.current.focus());
    return ()=>clearImmediate(immId);
  }, [autoFocus]);

  return (
    <form
      onSubmit={(ev)=>{
        ev.preventDefault();
        onChange && onChange(val);
        setVal('');
      }}
    >
      <TextField
        inputRef={textField}
        value={val}
        onChange={ev=>setVal(ev.target.value)}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        inputProps={{ tabIndex: 2 }}
        InputProps={{
          className: classes.root,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                edge="start"
                onClick={()=>scanner.current?.openScanner()}
                disabled={disabled}
                tabIndex={1}
              >
                <Icon>qr_code</Icon>
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                edge="end"
                disabled={!val.length || disabled}
                tabIndex={3}
              >
                <Icon>done</Icon>
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <QrCodeScanner
        ref={scanner}
        hidden
        onResult={onChange}
        disabled={disabled}
      />
    </form>
  );
}

export default QrCodeInput;
