
import React from 'react';
import { MainLayoutApi } from './types';
import MainSideNav from '../MainSideNav';
import Layout, { Header, Content, Aside, AsideApi } from '@momenttrack/react/base/Layout';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MainToolbar from '../MainToolbar';
import { useState, useRef, useMemo } from 'react';
import context from './context';

// const mainSideNavTrigger = (collapsed: boolean) => {
//   if(collapsed)
//     return <IconButton edge="start"><Icon>menu</Icon></IconButton>;
//   else
//     return <IconButton edge="start"><Icon>menu_open</Icon></IconButton>;
// };

const MainLayout: React.FC = props => {
  const triggerContainer = useRef(null);
  const asideApi = useRef<AsideApi>(null);
  const [toolbarContent, setToolbarContent] = useState<React.ReactNode>(null);

  const api: MainLayoutApi = useMemo(()=>({
    toolbarContent: c => setToolbarContent(c)
  }), []);

  function handleMenuItemClick() {
    asideApi.current?.collapse();
  }

  return (
    <context.Provider value={api}>
      <Layout>
        <Aside
          ref={asideApi}
          width={300}
          variant="drawer"
          triggerContainer={()=>triggerContainer.current}
          trigger={null}
        >
          <MainSideNav onItemClick={handleMenuItemClick} />
        </Aside>
        <Layout>
          <Header>
            <MainToolbar
              contentStart={(
                <IconButton
                  edge="start"
                  onClick={ev=>{
                    asideApi.current?.expand();
                  }}
                ><Icon>menu</Icon></IconButton>
              )}
            >{ toolbarContent }</MainToolbar>
          </Header>
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </context.Provider>
  );
};

export default MainLayout;
