import React from "react";
import {ThemeProvider, StylesProvider} from "@material-ui/core/styles";
import CssBaseLine from "@material-ui/core/CssBaseline";
import AuthenticationProvider from "./core/providers/AuthenticationProvider";
import NotificationsProvider from "./core/providers/NotificationsProvider";
import ConfirmDialogProvider from "./core/providers/ConfirmDialogProvider";
import Icons from "./core/config/icons";
import Root from "./Root";
import lightTheme from "./core/themes/main-light";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

Icons();

const App: React.FC = () => {
  return (
    <AuthenticationProvider>
      <NotificationsProvider>
        <ConfirmDialogProvider>
          <StylesProvider>
            <ThemeProvider theme={lightTheme}>
              <CssBaseLine />
              <Root />
            </ThemeProvider>
          </StylesProvider>
        </ConfirmDialogProvider>
      </NotificationsProvider>
    </AuthenticationProvider>
  );
};

export default App;
