
import React, { useCallback } from 'react';
import { firebaseService } from '../../../../core/system/legacy-services/firebase';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import MagicText from '../../../../core/components/MagicText';

const ActivityLogs = function(props) {
  const {
    logs = [],
    windowHeight = 500
  } = props;

  const getPersonName = useCallback(async (personId)=>{
    const person = await firebaseService.getPerson(personId);
    return person.person_first + ' ' + person.person_last;
  }, []);

  return (
    <Box>
      <Typography variant="h6"><b>Activity logs</b></Typography>

      <div><br/></div>

      <Box maxHeight={windowHeight} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        {logs.length > 0 && (
          <Grid container direction="column-reverse" spacing={3}>
            {logs.map((log, i)=>(
              <Grid key={''+i} item>
                <Typography variant="subtitle1">
                  <Link
                    underline="always"
                    component={RouterLink}
                    target="_BLANK"
                    to={`/${log.personId}`}
                  ><MagicText magicInput={log.personId} magicFunction={getPersonName}>{ log.personId }</MagicText></Link> {log.actionText}</Typography>

                <Typography variant="caption" color="textSecondary">@{moment.unix(log.timestampUnix).format('MMM DD, YYYY - hh:mm A')}</Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {!logs.length && (
          <Typography variant="body2" color="textSecondary">No logs available at the moment.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ActivityLogs;
