
import moment from 'moment/moment';
import Checkin from './Checkin';

function Guest(data={}) {
  Object.assign(this, {
    // user_type: "guest" | Do not set as default to avoid ambiguity while creating guest from available data.
    user_type: '',
    access_rights: '',
    first_name: '',
    last_name: '',
    email: '',
    picture: '',
    country_citizenship: '',
    us_citizen: '',
    visiting_as: '',
    company_name: '',
    person_visiting: '',
    escort_required: false,
    signed_nda: '',
    timestamp_nda: 0,
    check_in_current: null,
    check_ins: []

  }, data);

  if(!(this.timestamp_nda instanceof Date)){
    // Assume its unix "number of seconds"
    this.timestamp_nda = moment.unix(this.timestamp_nda).toDate();
  }
  this.timestamp_nda.toJSON = function(){ return moment(this).unix(); }
  this.timestamp_nda.toString = function(){ return moment(this).format('ddd, MMMM Do - hh:mm a') }

  this.check_in_current = Checkin(this.check_in_current);
  this.check_ins = (this.check_ins || []).map(c=>Checkin(c));
}

Guest.prototype.get = function(prop) {
  switch(prop){
    case 'id':
      return this.access_rights.includes('/') ? this.access_rights.split("/")[1] : this.access_rights;
    case 'full_name':
      return this.first_name + ' ' + this.last_name;
    default:
      return this[prop];
  }
};

Guest.prototype.applySearch = function(keyword='') {
  return JSON.stringify(this).toLowerCase().includes(keyword.toLowerCase());
};

const proxyHandler = {
  set: function(obj, prop, value) {
    switch(prop){
      case 'us_citizen':
        // Ignore direct assignment.
        return true;

      case 'country_citizenship':
        obj['us_citizen'] = (/^US$/i).test(value) ? true : false;
        obj[prop] = value;
        break;

      default:
        obj[prop] = value;
    }

    return true;
  }
};

function GuestProxy(...args) {
  return new Proxy(new Guest(...args), proxyHandler);
}

export default GuestProxy;
