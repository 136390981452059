import apiClient, {publicApiClient} from "../ordertrack-client";
import {
  LicensePlate,
  LicensePlateData,
  LicensePlateActivityLog,
  LicensePlateLookupResult,
} from "./types";
import {ExportToCsv} from "export-to-csv";
import {IPageData} from "../../../IdLookup/LicensePlateTemplate/types";
import htmlToPdf, {getPdfOptions} from "../utils/html-to-pdf";
import getPlaceTemplate from "./license-plates-templates/license-plates-template";
import {lifetimeClient} from "../ordertrack-client/public-api-client";

const csvExporter = new ExportToCsv({
  filename: "license-plate-activity-logs",
  showLabels: true,
  useKeysAsHeaders: true,
});

export const lookupLicensePlate = async (
  licensePlateId: string
): Promise<LicensePlateLookupResult> => {
  const res = await publicApiClient.get("/license_plates/lookup", {
    params: {lp_id: licensePlateId},
  });

  return res.data.data;
};

// check if lifetime customer engagement is available
export const lookupLifetimeEngagement = async (
  licensePlateId: string
): Promise<LicensePlateLookupResult> => {
  const res = await lifetimeClient.get(`/${licensePlateId}/fetch/template`);

  return res.data.data;
};

export const getLicensePlate = async (
  licensePlateId: number
): Promise<LicensePlate> => {
  const res = await apiClient.get(`/license_plates/${licensePlateId}`);
  return res.data.data;
};
export const getPublicLicensePlate = async (
  licensePlateId: number
): Promise<LicensePlate> => {
  const res = await publicApiClient.get(`/license_plates/${licensePlateId}`);
  return res.data.data;
};

export const updateLicensePlate = async (
  licensePlateId: number,
  data: LicensePlateData
): Promise<LicensePlate> => {
  const res = await apiClient.put(`/license_plates/${licensePlateId}`, data);
  return res.data.data;
};

export const moveLicensePlate = async (
  licensePlateId: number,
  destPlaceId: number
) => {
  const res = await apiClient.post(`license_plates/move`, {
    license_plate_id: licensePlateId,
    dest_location_id: destPlaceId,
  });
  return res.data.data;
};

export const moveAll = async (locationFromId: number, locationToId: number) => {
  const res = await apiClient.post(`license_plates/move_all`, {
    source_id: locationFromId,
    dest_id: locationToId,
  });
  return res.data;
};

export const addLicensePlateNotes = async (
  licensePlateId: number,
  message: string
) => {
  const res = await apiClient.post(`license_plates/${licensePlateId}/comment`, {
    message,
  });
  console.log(res);
};

export const getLicensePlateActivityLogs = async (
  licensePlateId: number
): Promise<LicensePlateActivityLog[]> => {
  const res = await apiClient.get(
    `license_plates/${licensePlateId}/activities`
  );
  return res.data;
};
export const getLicensePublicPlateActivityLogs = async (
  licensePlateId: number
): Promise<LicensePlateActivityLog[]> => {
  const res = await publicApiClient.get(
    `license_plates/${licensePlateId}/activities`
  );
  return res.data;
};

export const downloadLicensePlateActivityLogs = (
  logs: LicensePlateActivityLog[]
) => {
  csvExporter.generateCsv(
    logs.map(item => ({
      timestamp: item.created_at,
      activity: item.activity,
      person: item.user.first_name,
      person_qr: `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
        item.user.person_id
      }`,
      place: item.meta?.location.name || "",
      place_qr:
        !!item.meta && !!item.meta.location.beacon_id
          ? `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
              item.meta.location.beacon_id
            }`
          : "",
      notes: item.message || "",
    }))
  );
};

export const generateSheets = (pageData: IPageData): Promise<string> => {
  const labelsHtml = getPlaceTemplate(pageData, 30);

  const pdfOptions = getPdfOptions(
    `license-plate-report-${pageData.licensePlateId}`,
    "A4"
  );

  return htmlToPdf(labelsHtml, pdfOptions);
};
