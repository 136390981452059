
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useContext } from 'react';
import layoutContext from './context';

const Footer: React.FC<BoxProps> = props => {
  const { options } = useContext(layoutContext);

  return options.noFooter ? <></> : (
    <Box
      component="footer"
      flexBasis={0}
      {...props}
    />
  );
};

export default Footer;
