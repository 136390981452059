import {Box, Typography} from "@material-ui/core";
import unknownQrCodeImage from "../../assets/images/unknown_qr_code.png";

const ErrorDisplay = ({error}: {error: Error}) => {
  return (
    <Box my={10} px={4} style={{maxWidth: "100%"}}>
      <Box mb={4} textAlign="center">
        <img
          alt="Unknown qr code"
          src={unknownQrCodeImage}
          style={{maxWidth: "100%", height: "300px"}}
        />
      </Box>
      <Typography variant="h5" gutterBottom align="center">
        Oops Error!
      </Typography>
      <Typography color="textSecondary" align="center">
        {error.message}
      </Typography>
    </Box>
  );
};
export default ErrorDisplay;
