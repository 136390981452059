import React from "react";
import Link from "@material-ui/core/Link";
import {LogItem} from "./types";

type NormalizedLogItem = LogItem & {
  product: {part_number: string; description: string};
};

const columnDefs = [
  {
    field: "description",
    headerName: "Product description",
    valueGetter: (log: NormalizedLogItem) => log.product.description || "--",
  },
  {
    field: "part_number",
    headerName: "PART #",
    valueGetter: (log: NormalizedLogItem) => log.product.part_number || "--",
  },
  {
    field: "external_serial_number",
    headerName: "ASSET #",
    valueGetter: (log: NormalizedLogItem) => log.external_serial_number || "--",
  },
  {
    field: "lp_id",
    headerName: "Product QR",
    renderCell: (log: NormalizedLogItem) => {
      if (!log.lp_id) return "";

      return (
        <Link href={`/${log.lp_id}`} target="_BLANK">
          {`${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/`}...
          {log.lp_id.slice(-5)}
        </Link>
      );
    },
  },
  {
    field: "location",
    headerName: "Place",
    renderCell: (log: NormalizedLogItem) => {
      if (!log.location) return "";

      return (
        <Link href={`/${log.location.beacon_id}`} target="_BLANK">
          {log.location.name}
        </Link>
      );
    },
  },
];

export default columnDefs;
