
import apiClient from '../api-client';
import { momentTrackService } from '../moment-track';

export async function getProductionOrderStatus(documentId, userId) {
  const idInfo = momentTrackService.parseId(documentId);

  // return [
  //   {"id": "5hZhGC9DPR4", "name": "SMT start", "person": "Red O ", "Quantity": 10, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "aICZ9j2yBbO", "name": "QA 1", "person": "Red O ", "Quantity": 15, "oldest_item": "2929", "oldest_item_licence": "3hd.us/55d53d77d8ba440dbecA29140", "average_duration": 6495594},
  //   {"id": "aGCAxEi2ZHx", "name": "Testing", "person": "Hugo Tapia", "Quantity": 35, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "lQqEDYgqYWr", "name": "2nd QA", "person": "Red O ", "Quantity": 9, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "55vbQuQ2KRK", "name": "Final QA", "person": "Hugo Tapia", "Quantity": 12, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "aD4dbzbRuTF", "name": "Shipping Out", "person": "Hugo Tapia", "Quantity": 19, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0}
  // ];

  // return [
  //   {"id": "aICZ9j2yBbO", "name": "QA 1", "person": "Red O ", "Quantity": 8, "oldest_item": "2929", "oldest_item_licence": "3hd.us/55d53d77d8ba440dbecA29140", "average_duration": 6495594},
  //   {"id": "hcoHdUoSBaL", "name": "Disposition", "person": "Hugo Tapia", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "8Km3QVPysdW", "name": "Rework", "person": "Red O ", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "aD4dbzbRuTF", "name": "Shipping Out", "person": "Hugo Tapia", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "lQqEDYgqYWr", "name": "2nd QA", "person": "Red O ", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "aGCAxEi2ZHx", "name": "Testing", "person": "Hugo Tapia", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "55vbQuQ2KRK", "name": "Final QA", "person": "Hugo Tapia", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0},
  //   {"id": "5hZhGC9DPR4", "name": "SMT start", "person": "Red O ", "Quantity": 0, "oldest_item": "", "oldest_item_licence": "", "average_duration": 0}
  // ];

  const res = await apiClient.post('getworkorder.getworkorder', { workorder: idInfo.id, userid: userId });

  if(!res.data)
    throw new Error('Data not found.');

  return res.data;
}

export async function getMovementLogs(licencePlate) {
  // const idInfo = momentTrackService.parseId(licencePlate);
  return [];

  // return [
  //   {when: 'Jan 1 2020 1:56 pm MST', activity: 'Move 1 auto submit', location: 'Quality Assurance Station', location_url: '3hd.us/5hZhGC9DPR4', person: 'Jared Eggett', person_url: '3hd.us/bhTdJb28tUd9S8O6H', linked: '', notes: ''},
  //   {when: 'Jan 1 2020 1:54 pm MST', activity: 'Linked', location: '', location_url: '', person: 'Jared Eggett', person_url: '3hd.us/bhTdJb28tUd9S8O6H', linked: '3hd.us/licenseplatehereblah', notes: 'part number XYZ-PDQ'},
  //   {when: 'Jan 1 2020 1:52 pm MST', activity: 'Made one auto submit ', location: '', location_url: '', person: 'Jared Eggett', person_url: '3hd.us/bhTdJb28tUd9S8O6H', linked: '3hd.us/6c15d261c6524a73bc6d2d9e438caa1d', notes: 'part number 5678 '},
  //   {when: 'Jan 1 2020 1:50 pm MST', activity: 'Move Many', location: 'Manufacturing QR station 2', location_url: '3hd.us/5hZhGC9DPR4', person: 'Jared Eggett', person_url: '3hd.us/bhTdJb28tUd9S8O6H', linked: '', notes: ''},
  //   {when: 'Jan 1 2020 1:49 pm MST', activity: 'Made Many', location: '', location_url: '', person: 'Jared Eggett', person_url: '3hd.us/bhTdJb28tUd9S8O6H', linked: '3hd.us/6c15d261c6524a73bc6d2d9e438caa1d', notes: 'part Number 1234 '},
  // ];
}
