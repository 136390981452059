
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { getProductionOrderStatus } from '../../../../core/system/legacy-services/production-track';
import { useAuthentication } from '../../../../core/providers/AuthenticationProvider';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
// import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
// import StationIcon from '@material-ui/icons/PinDrop';
import PieChart from '../../../../core/components/PieChart';

const ProductionOrderDocument = props => {
  const {
    documentUrl,
    data

  } = props;

  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [currentUser] = useAuthentication();
  const location = useLocation();

  const quantityPieSeries = useMemo(()=>{
    let totalAssigned = 0;

    return [{
      name: 'Quantity',
      colorByPoint: true,
      type: 'pie',
      data: (orderStatus || []).map(station=>{
        totalAssigned += parseInt(station.Quantity);

        return {
          name: station.name,
          y: station.Quantity
        }
      }).concat([{ name: 'Not started', y: parseInt(data.quantity || data.qty) - totalAssigned, color: '#eeeeee' }])
    }];

  }, [orderStatus, data]);

  const loadReportData = useCallback(async ()=>{
    try{
      setLoading(true);
      setOrderStatus(await getProductionOrderStatus(documentUrl, currentUser.person_id));
      setLoading(false);
    }
    catch(err){
      alert(err.message);
      setLoading(false);
    }

  }, [documentUrl, currentUser]);

  useEffect(()=>{
    if(currentUser)
      loadReportData();

  }, [loadReportData, currentUser]);

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper style={{ height: '100%' }}>
              <Box p={2} display="flex" bgcolor="grey.200">
                <Box mr={2}>
                  <img
                  src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg"
                  style={{ maxHeight: 48 }}
                  alt="Production order document"
                />
                </Box>
                <Box>
                  <Typography variant="h5">Production order</Typography>
                  <Typography variant="caption">3hd.us/...{documentUrl.slice(-5)}</Typography>
                </Box>
              </Box>

              <Box px={2} py={1}>
                <Grid container spacing={4} alignItems="flex-start">
                  <Grid item>
                    <Typography variant="overline" color="textSecondary">Part #</Typography>
                    <Typography variant="h6">{ data.part_number }</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="overline" color="textSecondary">Quantity</Typography>
                    <Typography variant="h6">{data.quantity || data.qty}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="overline" color="textSecondary">Description</Typography>
                    <Typography variant="body2">{data.description || '--'}</Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />
              {loading && <LinearProgress />}

              {!loading && !currentUser && (
                <Box p={2} mt={2} textAlign="center">
                  <Typography color="textSecondary" variant="caption" component="p" gutterBottom>Login to view status</Typography>
                  <Link component={RouterLink} underline="none" to={{ pathname: '/login', state: { referrer: location } }}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                    
                    >
                      Login
                    </Button>
                  </Link>
                </Box>
              )}

              {!!currentUser && !!orderStatus && (
                <Box p={2} mt={2}>
                  <PieChart
                    series={quantityPieSeries}
                  />
                </Box>
              )}
            </Paper>
          </Grid>
          
          {!!currentUser && !!orderStatus && (
            <Grid item xs={12} sm>
              <Grid container spacing={2}>
                {orderStatus.map((station, i)=>(
                  <Grid key={i + '' + station.id} item xs={12} sm={6} md={4} lg={2}>
                    <Card>
                      <CardHeader
                        // avatar={<Avatar><StationIcon /></Avatar>}
                        title={station.name}
                        subheader={`3hd.us/...${station.id.slice(-5)}`}
                        titleTypographyProps={{ variant: 'h6' }}
                        subheaderTypographyProps={{ color: 'textSecondary' }}
                      />
                      <CardContent>
                        <Typography variant="overline" color="textSecondary">Quantity</Typography>
                        <Typography variant="h2">{ station.Quantity }</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Card>
                    <CardHeader
                      // avatar={<Avatar><StationIcon /></Avatar>}
                      title="Rework"
                      subheader={`3hd.us/...${'8Km3QVPysdW'.slice(-5)}`}
                      titleTypographyProps={{ variant: 'h6' }}
                      subheaderTypographyProps={{ color: 'textSecondary' }}
                    />
                    <CardContent>
                      <Typography variant="overline" color="textSecondary">Quantity</Typography>
                      <Typography variant="h2">0</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Card>
                    <CardHeader
                      // avatar={<Avatar><StationIcon /></Avatar>}
                      title="Disposition"
                      subheader={`3hd.us/...${'hcoHdUoSBaL'.slice(-5)}`}
                      titleTypographyProps={{ variant: 'h6' }}
                      subheaderTypographyProps={{ color: 'textSecondary' }}
                    />
                    <CardContent>
                      <Typography variant="overline" color="textSecondary">Quantity</Typography>
                      <Typography variant="h2">0</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Card>
                    <CardHeader
                      // avatar={<Avatar><StationIcon /></Avatar>}
                      title="Unstarted"
                      subheader="--"
                      titleTypographyProps={{ variant: 'h6' }}
                      subheaderTypographyProps={{ color: 'textSecondary' }}
                    />
                    <CardContent>
                      <Typography variant="overline" color="textSecondary">Quantity</Typography>
                      <Typography variant="h2">50</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductionOrderDocument;
