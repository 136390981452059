import {IPageData} from "../../../../IdLookup/DocumentTemplate/ProductionOrderDocumentTemplate";

const styles = `
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
        background-color: #f5f5f5;
      }

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }

      button {
        padding: 0;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .page-container {
        width: 8.27in;
        padding: 0.5in 0.22in;
        vertical-align: top;
      }

      .overview {
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        background-color: #fff;
      }

      .overview__header {
        padding: 16px;
        background-color: rgb(238, 238, 238);
      }

      .overview__img {
        max-height: 48px;
        margin-right: 10px;
      }

      .overview__name {
        display: inline-block;
      }

      .overview-list {
        padding: 8px 16px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .overview-list__item {
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 40px) / 3);
        min-width: 15%;
      }

      .overview-list__item:not(:last-child) {
        margin-right: 10px;
      }

      .overview-list__name {
        color: rgba(0, 0, 0, 0.54);
      }

      .overview-list__value {
        font-size: 12px;
      }

      .overview svg {
        width: 100%;
      }

      .locations__paper {
        display: inline-block;
        padding: 16px;
        width: 30%;
        margin-bottom: 20px;
        background-color: #fff;
      }

      .locations__paper:not(:last-child) {
        margin-right: 20px;
      }

      .locations__text {
        color: rgba(0, 0, 0, 0.54);
      }

      .locations__number {
        font-size: 24px;
      }

      .section {
        padding: 8px 0;
        width: 100%;
        background-color: #fff;
      }

      .section__title {
        padding: 0 8px;
      }

      table {
        border-spacing: 0;
        width: 100%;
        max-width: 100%;
      }

      .thead {
        background-color: #f5f5f5;
      }

      th {
        padding: 10px 10px;
        text-align: left;
        font-weight: 700;
      }

      tr {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      td {
        padding: 10px 10px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      td:nth-child(4),
      td:nth-child(5) {
        color: #ec1c24;
      }

      .logs-table td:nth-child(4),
      .logs-table td:nth-child(8) {
        min-width: 1in;
      }`;

const template = (pageData: IPageData, logsPerSheet: number = 50) => {
  const {productionOrder, orderStatusReport, distributionMap, chartSvg} =
    pageData;

  const product = orderStatusReport.product;
  const logsNormalized = orderStatusReport.lineitems.map(log => ({
    ...log,
    product,
  }));

  return `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Production Order Report</title>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/1.0.0/modern-normalize.min.css"
    />
    <style>${styles}</style>
  </head>
  <body>
    <div class="page-container">
      <section class="overview">
        <div class="overview__header">
          <img
            src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg"
            alt="Production order document"
            class="overview__img"
          />
          <div class="overview__name">
            <h2>Production order</h2>
            <a href="${productionOrder.docid}"
              >3hd.us/...${productionOrder.docid.slice(-5)}</a
            >
          </div>
        </div>
        <ul class="overview-list">
          <li class="overview-list__item">
            <p class="overview-list__name">Part #</p>
            <p class="overview-list__value">${
              productionOrder.product.part_number
            }</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Quantity</p>
            <p class="overview-list__value">${
              productionOrder?.requested_qty || "0"
            }</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Description</p>
            <p class="overview-list__value">${
              productionOrder.product.description || "--"
            }</p>
          </li>
        </ul>
        <div>
        ${chartSvg}
        </div>
      </section>
      <div class="locations">
      ${Object.keys(distributionMap)
        .map(
          stationName => `<section class="locations__paper">
          <h3 class="locations__title">${stationName}</h3>
          <p class="locations__text">QUANTITY</p>
          <p class="locations__number"><b>${distributionMap[stationName].total}</b></p>
        </section>`
        )
        .join("")}
      </div>
    </div>
    <div class="page-container">
    <section class="section">
      <h2 class="section__title">Logs</h2>
      <table class="logs-table">
        <tbody>
        <tr class="thead">
          <th>Product description</th>
          <th>PART #</th>
          <th>ASSET #</th>
          <th>Product QR</th>
          <th>Place</th>
        </tr>
        ${logsNormalized
          .map(
            log => `
            <tr>
            <td>${log.product.description || "--"}</td>
            <td>${log.product.part_number || "--"}</td>
            <td>${log.external_serial_number || "--"}</td>
            <td><a href="3hd.us/${log.lp_id}"
            >3hd.us/...${log.lp_id?.slice(-5) || "--"}</a>
            </td>
            <td>
            <a href="3hd.us/${log.location?.beacon_id}">${
              log.location?.name || "--"
            }</a>
            </td>        
            </tr>`
          )
          .join("")}
        </tbody>
      </table>
    </section>
  </div>

  </body>
</html>
`;
};

export default template;
