import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import DataGrid from "../../../core/components/DataGrid";
import useNotify from "../../../core/hooks/use-notify";
import {
  getLicensePlateActivityLogs,
  downloadLicensePlateActivityLogs,
  LicensePlateActivityLog,
  getLicensePublicPlateActivityLogs,
} from "../../../core/system/license-plates";
import {
  LicensePlateActivityLogsProps,
  LicensePlateActivityLogsApi,
} from "./types";
import columnDefs from "./column-defs";

const LicensePlateActivityLogs: React.ForwardRefRenderFunction<
  LicensePlateActivityLogsApi,
  LicensePlateActivityLogsProps
> = (props, ref) => {
  const notify = useNotify();
  const [logs, setLogs] = useState<LicensePlateActivityLog[]>([]);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      insertLog: log => setLogs(currLogs => [log, ...currLogs]),
    }),
    []
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setLogs([]);
        let activityLogs: LicensePlateActivityLog[];
        if (props.isPublic) {
          activityLogs = await getLicensePublicPlateActivityLogs(
            props.licensePlateId
          );
        } else {
          activityLogs = await getLicensePlateActivityLogs(
            props.licensePlateId
          );
        }
        setLogs(currLogs =>
          currLogs.concat(
            activityLogs.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
          )
        );
        setLoading(false);
      } catch (err) {
        notify(err as Error);
        setLoading(false);
      }
    })();
  }, [props.licensePlateId, notify, props.isPublic]);

  useEffect(() => {
    if (logs.length === 0) return;
    props.onGetLogs(logs);
    // eslint-disable-next-line
  }, [logs]);

  return (
    <div>
      <Box
        px={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Logs</Typography>
        <Box>
          {!props.isPublic && (
            <IconButton
              aria-label="share via mail"
              color="secondary"
              onClick={props.onShare}
            >
              <EmailOutlinedIcon />
            </IconButton>
          )}
          <Button
            color="secondary"
            startIcon={<Icon>file_download</Icon>}
            onClick={() => downloadLicensePlateActivityLogs(logs)}
            disabled={logs.length === 0}
          >
            Download CSV
          </Button>
        </Box>
      </Box>
      <DataGrid
        rows={logs}
        columns={columnDefs(props.isPublic)}
        noPagination
        windowHeight="calc(100vh - 88px)"
        disableSelectionOnClick
      />

      {loading && (
        <Box p={2}>
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
};

export default forwardRef(LicensePlateActivityLogs);
