
import React from 'react';
import { MomenttrackIdTagProps } from './types';
import Typography from '@material-ui/core/Typography';

const MomenttrackIdTag: React.FC<MomenttrackIdTagProps> = props => {
  return (
    <Typography component="span" variant="h6">
      <i>~ <b>{props.id.slice(-5)}</b></i>
    </Typography>
  );
};

export default MomenttrackIdTag;
