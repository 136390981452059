
import React, { useRef } from 'react';
import { Notify } from './types';
import context from './context';
import NotificationsCenter, { NotificationsCenterApi } from '../../components/NotificationsCenter';

const NotificationsProvider: React.FC = props => {
  const notificationsCenterApi = useRef<NotificationsCenterApi>(null);
  const notify: Notify = message => (notificationsCenterApi.current?.notify || window.alert)(message);

  return (
    <context.Provider value={notify}>
      {props.children}
      <NotificationsCenter ref={notificationsCenterApi} />
    </context.Provider>
  );
};

export default NotificationsProvider;
