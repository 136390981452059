
import React from 'react';
import { MainToolbarProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthentication } from '../core/providers/AuthenticationProvider';
import PersonBadge from '../core/components/PersonBadge';
import styles from './styles';
import momenttrackLogoM from '../assets/images/logo-m.png';

const useStyles = makeStyles(styles);

const MainToolbar: React.FC<MainToolbarProps> = props => {
  const {
    contentStart,
    contentEnd,

    ...toolbarProps
  } = props;

  const person = useAuthentication()[0];
  const classes = useStyles();

  return (
    <AppBar position="relative" color="inherit" elevation={2}>
      <Toolbar {...toolbarProps}>
        {!!contentStart && (
          <Box>{ contentStart }</Box>
        )}
        <Box px={1} lineHeight={0}>
          <Link component={RouterLink} to="/" underline="none">
            <img src={momenttrackLogoM} alt="Momenttrack logo" className={classes.logo}/>
          </Link>
        </Box>
        <div className={classes.contentContainer}>{ props.children }</div>
        {!!contentEnd && (
          <Box ml={2}>{ contentEnd }</Box>
        )}
        {!!person && (
          <Hidden xsDown>
            <Box>
              <PersonBadge
                {...person}
                hiddenText
              />
            </Box>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MainToolbar;
