import { createContext } from "react";
import { AuthenticationContext } from "./types";

const context = createContext<AuthenticationContext>([
	null,
	false,
	{
		login: (e, p) => Promise.resolve(false),
		logout: () => {},
		updateProfile: () => {},
		updatePassword: () => {},
		isLoggedIn: undefined,
	},
]);
export default context;
