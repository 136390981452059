
import { ReactNode, useContext, useEffect } from 'react';
import mainLayoutContext from './context';

const useMainToolbar = (content: ReactNode) => {
  const layout = useContext(mainLayoutContext);
  
  useEffect(() => {
    layout.toolbarContent(content);
    return () => layout.toolbarContent(null);

  }, [layout, content]);
};

export default useMainToolbar;
