
import React from 'react';
import clsx from 'clsx';
import Resumable from 'resumablejs';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VideoUploader = React.forwardRef((props, ref) => {
  const dropAreaRef = React.useRef();
  const browseButtonRef = React.useRef();
  const selectedFileRef = React.useRef(null);
  const resolveUploadRef = React.useRef(null);
  const rejectUploadRef = React.useRef(null);

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);

  const {
    className,
    disabled=false,
    required=false,
    uploadTargetUri

  } = props;

  React.useImperativeHandle(ref, () => ({
    startUpload: () => {
      const fileChosen = !!resumable.files.length;

      if(!fileChosen && !required)
        return Promise.resolve('');
      else if(!fileChosen)
        return Promise.reject(new Error('Please choose a video to upload.'));

      return new Promise((resolve, reject)=>{
        resolveUploadRef.current = resolve;
        rejectUploadRef.current = reject;
        resumable.upload();
      });
    }
  }));

  const resumable = React.useMemo(() => {
    return new Resumable({
      target: uploadTargetUri,
      maxFiles: 1,
      fileType: ['mp4','mpg','mpeg','wmv','mov','avi','flv','mkv','webm'],
      maxChunkRetries: 10,
      prioritizeFirstAndLastChunk: true,
      // totalChunksParameterName: 'total_chunks',
      // chunkNumberParameterName: 'chunk',
      // identifierParameterName: 'filename',
      // fileParameterName: 'file'
    });

  }, [uploadTargetUri]);

  React.useEffect(()=>{
    resumable.assignBrowse(browseButtonRef.current);
    resumable.assignDrop(dropAreaRef.current);
    resumable.on('fileAdded', file => {
      selectedFileRef.current = file;
      setSelectedFile(file);
    });
    resumable.on('uploadStart', () => {
      resumable.unAssignDrop(dropAreaRef.current);
    });
    resumable.on('complete', () => {
      const selectedFile = selectedFileRef.current;
      const resolveUpload = resolveUploadRef.current;
      resolveUpload(selectedFile.serverResponse.url);
      resumable.assignDrop(dropAreaRef.current);
    });
    resumable.on('progress', () => {
      setProgress(Math.round(resumable.progress() * 100));
    });
    resumable.on('fileSuccess', (file, response)=>{
      console.log(response);
      file.serverResponse = JSON.parse(response);
    });
    resumable.on('error', msg=>{
      const rejectUpload = rejectUploadRef.current;
      rejectUpload(new Error(msg));
    });

  }, [resumable]);

  const uploading = resumable && resumable.isUploading();

  return (
    <div className={clsx('video-uploader', className)}>
      <div ref={dropAreaRef} className="input-area">
        <Typography variant="body2" className="label">{(uploading || progress===100) ? `Uploading ${progress}%...` : 'Drop video here... (or)'}</Typography>
        <Button
          ref={browseButtonRef}
          size="large"
          variant="contained"
          startIcon={<FontAwesomeIcon icon="video" className="icon"/>}
          disabled={uploading || disabled}
        >Choose {!!selectedFile && 'a different '}video</Button>

        {!!selectedFile && <div className="file-info">
          <Typography variant="body2">{selectedFile.fileName}</Typography>
          <Typography variant="caption">{Math.round(selectedFile.size/1024/1024).toLocaleString()} MB</Typography>
        </div>}
      </div>
      <LinearProgress variant="determinate" value={progress} color="secondary" className="progress-bar"/>
    </div>
  );
});

export default VideoUploader;
