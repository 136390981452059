
import React, { FormEvent, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateTrainingQrs } from '../../../core/system/litmon';

const TrainingLabelsGeneratorDialog: React.FC<DialogProps> = props => {
  const [qty, setQty] = useState(30);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [generatingLabels, setGeneratingLabels] = useState(false);

  const handleGenerateLabels = async (ev: FormEvent) => {
    try{
      ev.preventDefault();
      if(qty < 1 || qty > 300) throw new Error('Quantity should be between 1 and 300');

      setGeneratingLabels(true);
      const pdfUrl = await generateTrainingQrs(qty);
      setPdfUrl(pdfUrl);
    }
    catch(err){
      alert((err as Error).message);
    }
    finally{
      setGeneratingLabels(false);
    }
  };

  return (
    <Dialog
      {...props}
      maxWidth={!pdfUrl ? props.maxWidth : 'md'}
    >
      <DialogTitle>Generate training labels</DialogTitle>
      <DialogContent>
        {!pdfUrl && (
          <FormControl>
            <FormLabel>How many labels?</FormLabel>
            <Input
              type="number"
              value={qty || ''}
              disabled={generatingLabels}
              inputProps={{ min: 1, max: 300 }}
              onChange={ev=>setQty(+ev.target.value)}
              error={qty < 1}
            />
            <FormHelperText>Max: 300</FormHelperText>
          </FormControl>
        )}
        {!!pdfUrl && (
          <iframe
            title="Training QRs (empty)"
            src={pdfUrl}
            width="100%"
            style={{border: 'none', height: '70vh'}}
          />
        )}
      </DialogContent>
      <DialogActions>
        {generatingLabels && (
          <CircularProgress size={24} />
        )}
        {props.onClose && (
          <Button
            onClick={()=>props.onClose && props.onClose({}, 'escapeKeyDown')}
            disabled={generatingLabels}
          >Cancel</Button>
        )}
        {!pdfUrl && (
          <Button
            onClick={handleGenerateLabels}
            disabled={generatingLabels}
            variant="contained"
            color="primary"
          
          >Generate</Button>
        )}
        {!!pdfUrl && (
          <Button
            onClick={()=>setPdfUrl(null)}
            disabled={generatingLabels}
            variant="contained"
            color="primary"
          
          >Regenerate</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TrainingLabelsGeneratorDialog;
