import React, { useState, useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrainingEditor from "../TrainingEditor";
import QrCodeScanner from "../../core/components/QrCodeScanner";
import TrainingLabelsGeneratorDialog from "./TrainingLabelsGeneratorDialog";
import useNotify from "../../core/hooks/use-notify";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";
import parseMomentTrackId from "../../core/system/utils/parse-momenttrack-id";
import { lookupTraining, TrainingDocument } from "../../core/system/litmon";

const HomePage: React.FC = () => {
	const notify = useNotify();
	const [currUser] = useAuthentication();
	const [loading, setLoading] = useState(false);
	const [docid, setDocid] = useState<string | null>(null);
	const [trainingDoc, setTrainingDoc] = useState<TrainingDocument>();
	const [editorOpen, setEditorOpen] = useState(false);
	const [showLabelsDialog, setShowLabelsDialog] = useState(false);

	const currTraining = useMemo(() => {
		if (!trainingDoc || trainingDoc.organization_id !== currUser?.organization_id)
			return;

		return trainingDoc;
	}, [trainingDoc, currUser]);

	const handleQrScanResult = useCallback(
		async (result: string) => {
			try {
				const parsed = parseMomentTrackId(result);
				if (parsed.type !== "document")
					throw new Error('Invalid QR. Please scan a valid "document" QR code.');

				setDocid(parsed.id);
				setTrainingDoc(undefined);
				setLoading(true);
				const trainingDoc = await lookupTraining(parsed.id).catch(() => {
					setEditorOpen(true);
					return undefined;
				});
				setTrainingDoc(
					trainingDoc?.pick_type === "TRAINING" ? trainingDoc : undefined
				);
			} catch (err) {
				notify(err as Error);
			} finally {
				setLoading(false);
			}
		},
		[notify]
	);

	return (
		<Box pt={10}>
			<Container maxWidth="sm">
				<Box mb={5} textAlign="center">
					<Typography variant="h4" gutterBottom>
						Learning in the moment
					</Typography>
					<Typography variant="body2">
						Create training: Start by scanning a training document QR code to
						<br />
						link the training with.
					</Typography>
				</Box>

				{loading && (
					<Box textAlign="center" py={2}>
						<CircularProgress size={24} />
					</Box>
				)}

				{!!trainingDoc && (
					<Box mb={5}>
						{!currTraining && (
							<Typography color="error">
								This QR code has already been linked with another training. Please scan
								a different label.
							</Typography>
						)}
						{!!currTraining && (
							<Box>
								<Card>
									<CardHeader
										avatar={
											<Avatar variant="square">
												<Icon>school</Icon>
											</Avatar>
										}
										title={currTraining.title}
										subheader={
											<Chip
												icon={<Icon fontSize="small">person</Icon>}
												label={`${trainingDoc.user.first_name} ${trainingDoc.user.last_name}`}
												size="small"
											/>
										}
										titleTypographyProps={{ variant: "h6" }}
									/>
									<CardContent>
										<Typography variant="body2">
											This QR code <code>`3hd.us/...{docid?.slice(-5)}`</code> has already
											been linked with a training. You can choose to edit the training or
											to scan a different label.
										</Typography>
									</CardContent>
									<Divider light />
									<CardActions>
										<Button
											onClick={() => setEditorOpen(true)}
											color="primary"
											startIcon={<Icon>edit</Icon>}
										>
											Make changes
										</Button>
									</CardActions>
								</Card>
							</Box>
						)}
					</Box>
				)}

				<Grid
					container
					direction="column"
					spacing={3}
					alignItems="center"
					justifyContent="center"
				>
					<Grid item>
						<QrCodeScanner
							onResult={handleQrScanResult}
							label={!trainingDoc ? "Scan label" : "Scan different label"}
							disabled={loading}
							ButtonProps={{
								variant: "contained",
								color: !trainingDoc ? "primary" : "secondary",
							}}
						/>
					</Grid>
					<Grid item>
						<Link href="#" onClick={() => setShowLabelsDialog(true)}>
							Get empty labels
						</Link>
					</Grid>
				</Grid>

				<Dialog open={editorOpen} fullScreen>
					<DialogTitle>Learning in the moment</DialogTitle>
					<Divider />
					<DialogContent>
						<Box pt={2} pb={10}>
							<Container>
								{!!docid && (
									<TrainingEditor
										docid={docid}
										trainingDocument={trainingDoc}
										onCancel={() => setEditorOpen(false)}
									/>
								)}
							</Container>
						</Box>
					</DialogContent>
				</Dialog>

				<TrainingLabelsGeneratorDialog
					open={showLabelsDialog}
					onClose={(ev, reason) =>
						reason !== "backdropClick" && setShowLabelsDialog(false)
					}
					disableEscapeKeyDown
					maxWidth="sm"
					fullWidth
				/>
			</Container>
		</Box>
	);
};

export default HomePage;
