
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: ({ width, rootWidth }: any) => ({
    width: rootWidth,
    height: '100%',
    position: 'relative',
    transition: 'width 300ms',

    '& > *:first-child': {
      width,
      height: '100%',
      transition: 'transform 300ms'
    }
  }),

  drawer: ({ anchor, isBelow }: any) => ({
    position: isBelow ? 'fixed' : 'absolute',
    top: 0,
    ...(anchor === 'right' ? { right: 0 } : { left: 0 }),
    zIndex: theme.zIndex.drawer
  }),

  backdrop: ({ isBelow }: any) => ({
    position: isBelow ? 'fixed' : 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer,
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: 'all 300ms'
  }),

  collapsed: ({ collapsedWidth, anchor }: any) => ({
    width: collapsedWidth,

    '& > *:first-child': anchor === 'left' ? {
      transform: `translateX(-100%)`
    } : {
      transform: `translateX(100%)`
    }
  }),

  triggerWrapper: ({ floatingTrigger, anchor }: any) => ({
    ...(floatingTrigger ? {
      position: 'absolute',
      top: 0,
      ...(anchor === 'right' ? { right: '100%' } : { left: '100%' })

    } : {}),
    display: 'inline-block',
    lineHeight: 0
  })
});

export default styles;
