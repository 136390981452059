
import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const PageHeader = ({ title, subtitle, loading }) => {
  return (
    <div className="page-header">
      <Typography variant="h4" className="title">{title} {!!loading && <CircularProgress size={24} />}</Typography>
      {!!subtitle && <Typography variant="subtitle2" className="subtitle">{subtitle}</Typography>}
    </div>
  );
};

export default PageHeader;
