
import apiClient from '../api-client';
import { ExportToCsv } from 'export-to-csv';

const csvExporter = new ExportToCsv({
  filename: 'station-report-productiontrack',
  showLabels: true,
  useKeysAsHeaders: true
});

export async function getStations() {
  const res = await apiClient.get('getstations.getstations');
  if(!res.data)
    throw new Error('Nothing found.');

  return res.data;
}

export async function getStationReport(stationId) {
  const res = await apiClient.post('getstationid.getstations', { stationid: stationId });
  if(!res.data)
    throw new Error('Nothing found.');

  return res.data;
}

export function downloadStationLogs(stationLogs) {
  csvExporter.generateCsv(stationLogs.map(item=>({
    part_number: item.part_number,
    qty: item.qty,
    person_name: item.person_name,
    arrived: item.arrived
  })));
}
