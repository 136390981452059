
import moment from 'moment/moment';
import {
  createGuest,
  getGuest,
  getEmployee,
  searchEmployees,
  getNdas,
  checkIn,
  checkOut
} from '../../system/legacy-services/guest-track';
import Guest from '../../system/legacy-services/models/Guest';

export const ACTION_TYPES = {
  SET_LOADING: 'SET_CHECKIN_FORM_LOADING',
  SET_EMPLOYEES_LIST: 'SET_COMPANY_EMPLOYEES_LIST',
  SET_INITIAL_DATA: 'SET_INITIAL_FORM_DATA',
  SET_GUEST: 'SET_CHECK_IN_GUEST',
  UPDATE_GUEST: 'UPDATE_CHECK_IN_GUEST',
  SET_NDA: 'SET_GUEST_NDA',
  SET_SIGNATURE: 'SET_SIGNATURE',
  RESET_ALL: 'RESET_ALL',
  RESET_NDA: 'RESET_NDA'
};

export function setLoading(msg='') {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: msg
  };
}

export function setEmployeesList(payload=[]) {
  return {
    type: ACTION_TYPES.SET_EMPLOYEES_LIST,
    payload
  };
}

function setGuest(guest) {
  return {
    type: ACTION_TYPES.SET_GUEST,
    payload: guest
  };
}

export function updateGuest(key, value) {
  return {
    type: ACTION_TYPES.UPDATE_GUEST,
    payload: {
      key,
      value
    }
  };
}

export function setInitialData(payload) {
  return {
    type: ACTION_TYPES.SET_INITIAL_DATA,
    payload
  };
}

export function resetForm() {
  return {
    type: ACTION_TYPES.RESET_ALL
  };
}

export function setSignature(signature) {
  return {
    type: ACTION_TYPES.SET_SIGNATURE,
    payload: signature
  };
}

export function resetNDA() {
  return {
    type: ACTION_TYPES.RESET_NDA
  };
}

export function loadGuest(person_url) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.companyProfile.company_code;
      dispatch(setLoading('Fetching information...'));
      
      const guestData = await getGuest(person_url, company_code);

      const guest = new Guest(guestData);
      dispatch(setInitialData(guestData));
      dispatch(setGuest(guest));

      return guest;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function loadOrCreateGuest(email) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.companyProfile.company_code;

      dispatch(setLoading('Fetching information...'));

      const guestData = await createGuest(email, company_code);

      dispatch(setInitialData(guestData));
      dispatch(setGuest(new Guest(guestData)));
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function getEmployeeInfo(person_url) {
  return async (dispatch, getState) => {
    const company_code = getState().checkinAuth.companyProfile.company_code;
    return getEmployee(person_url, company_code);
  };
};

export function searchEmployee(query) {
  return async (dispatch, getState) => {
    const company_code = getState().checkinAuth.companyProfile.company_code;
    return searchEmployees(query, company_code);
  };
}

export function loadNDA() {
  return async (dispatch, getState) => {
    try{
      const state = getState();
      const company_code = state.checkinAuth.companyProfile.company_code;
      const { guest, initialData } = state.checkinForm;

      const ndaAge = moment().diff(moment.unix(initialData.timestamp_nda), 'days'); // days.

      if(
        ndaAge <= 365 &&
        guest.us_citizen === initialData.us_citizen &&
        guest.visiting_as === initialData.visiting_as
      ){
        return dispatch({
          type: ACTION_TYPES.SET_NDA,
          payload: false // Intentionally set to false instead of null to lock the guest form inputs after nda has been checked.
        });
      }

      dispatch(setLoading('Loading NDA...'));
      const ndas = await getNdas(company_code);

      let nda_type, nda_body;

      switch(guest.visiting_as){
        case 'Customer':
          nda_type = guest.us_citizen ? 'Basic' : 'Foreign Basic'; break;
        case 'Vendor/Guest':
          nda_type = guest.us_citizen ? 'Normal' : 'Foreign Normal'; break;
        default:
          nda_type = 'default';
      }

      nda_body = ndas[nda_type] || ndas['default'] || 'Not found!';

      dispatch({
        type: ACTION_TYPES.SET_NDA,
        payload: {
          nda_body,
          nda_type
        }
      });
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  }
}

export function performCheckin() {
  return async (dispatch, getState) => {
    try{
      const state = getState();
      const {
        access_rights,
        location,
        licence_plate
      } = state.checkinAuth;
      const {
        guest,
        guest_status,
        nda,
        updatesFlags,
        employees
      } = state.checkinForm;
      const {
        person_visiting,
        signed_nda,
        timestamp_nda,
        check_in_current,
        check_ins,
        escort_required,

        ...guestData
      } = guest;
      const company_code = access_rights.company_code;
      const employee = employees.find(emp=>emp.person_url === person_visiting);

      const checkInDetails = {
        person_visiting,
        admin_emails: [access_rights.email_person, employee.email_person],
        guest_status,
        location: location.location_url,
        printer: licence_plate.printer_url
      };

      dispatch(setLoading('Thank you for checking in. Your ID badge is being printed.'));

      if(guest_status === 'new'){
        checkInDetails['nda_type'] = nda.nda_type;
        checkInDetails['signature'] = nda.signature;
        checkInDetails['guest'] = guestData;
      }
      else{
        checkInDetails['access_rights'] = guestData.access_rights;
        const update_fields = {};

        const fields2Update = Object.keys(updatesFlags).reduce((fields, key)=>{
          if(updatesFlags[key]){
            fields.push(key);
            if(key === 'country_citizenship')
              fields.push('us_citizen');
          }
          return fields;
        }, []);

        if(fields2Update.length){
          checkInDetails['guest_status'] = 'update';
          checkInDetails['update_fields'] = update_fields;
          fields2Update.forEach(field=>update_fields[field] = guestData[field]);
        }

        if(nda){
          checkInDetails['guest_status'] = 'update';
          checkInDetails['update_fields'] = update_fields;
          update_fields['nda_type'] = nda.nda_type;
          update_fields['signature'] = nda.signature;
        }
      }
      
      await checkIn(checkInDetails, company_code);
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}

export function checkout(person_url) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.companyProfile.company_code;
      dispatch(setLoading('Checking out...'));

      await checkOut(person_url, company_code);
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading(''));
    }
  };
}
