
export default function HttpError(message, statusText='Error') {
  Error.call(this, message);
  
  this.name = 'HttpError';
  this.message = message;
  this.statusText = statusText;

  // Error.captureStackTrace(this);
}

HttpError.prototype = Object.create(Error.prototype);
