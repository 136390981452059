import moment from "moment";
import {IPageData} from "../../../../IdLookup/PlaceTemplate/PlaceReport";
import {IPlaceActivityLog} from "../types";

const template = (pageData: IPageData, logsPerSheet: number = 18) => {
  const totalSheets = Math.ceil(pageData.logs.length / logsPerSheet);
  const sheets = Array(totalSheets)
    .fill(undefined)
    .map((_, i) =>
      pageData.logs?.slice(i * logsPerSheet, i * logsPerSheet + logsPerSheet)
    );

  const getExtSerialNumber = (log: string | null) => {
    if (!log) return "--";
    if (log.length < 15) return log;

    const divider = Math.ceil(log.length / 2);
    return log?.slice(0, divider) + " " + log?.slice(divider, log.length);
  };

  const checkIfLocationResides = (log: IPlaceActivityLog) => {
    const licensePlates = pageData.reportData.license_plates;
    if (!licensePlates) return false;
    const lpIds = new Set(licensePlates.map(lp => lp.id));

    return lpIds.has(log.license_plate_id);
  };

  return `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Place Report</title>
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/1.0.0/modern-normalize.min.css"
  />
       <style>
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
        background-color: #f5f5f5;
      }

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }

      button {
        padding: 0;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .page-container {
        width: 8.27in;
        height: 11.69in;
        padding: 0.5in 0.22in;
      }

      .header {
        margin-bottom: 15px;
        width: 100%;
      }

      .overview-primary {
        display: flex;
      }

      .overview-primary__title {
        display: inline-block;
      }

      .qr-code {
        width: 0.75in;
        margin-right: 20px;
      }

      .overview-list {
        width: 100%;
      }

      .overview-list__item {
        display: inline-block;
         vertical-align: top;
        width: calc((100% - 60px) / 4);
        min-width: 20%;
      }

      .overview-list__item:not(:last-child) {
        margin-right: 20px;
      }

      .overview-list__name {
        color: rgba(0, 0, 0, 0.54);
      }

      .overview-list__value {
        font-size: 14px;
      }

      .section {
        padding: 15px;
        width: 100%;
        background-color: #fff;
      }

      .section__content svg {
        width: 70%;
      }

      table {
        border-spacing: 0;
        width: 100%;
        max-width: 100%;
      }

      thead {
        background-color: #f5f5f5;
      }

      th {
        padding: 10px 5px;
        text-align: left;
        font-weight: 700;
      }

      tr {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      td {
        padding: 10px 5px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      .logs-table td:nth-child(4),
      .logs-table td:nth-child(8) {
        min-width: 1in;
      }
    </style>
  </head>
  <body>
    <div class="page-container">
      <header class="header">
        <div class="overview-primary">
      <img alt="place qr code"
        src="https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${
          pageData.placeQR
        }"
        class="qr-code" />
          <div class="overview-primary__title">
            <p>PLACE</p>
            <h1>${pageData.reportData.name}</h1>
          </div>
        </div>
        <ul class="overview-list">
          <li class="overview-list__item">
            <p class="overview-list__name">DATA AS OF</p>
            <p class="overview-list__value">${moment().format(
              "MMM DD, YYYY - hh:mma"
            )}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">AVERAGE DURATION</p>
            <p class="overview-list__value">${
              pageData.reportData.average_duration
                ? moment
                    .duration(pageData.reportData.average_duration, "s")
                    .humanize()
                : "--"
            }</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">QTY IN STATION</p>
            <p class="overview-list__value">${pageData.qtyPresent}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">OLDEST ITEM</p>
            <p class="overview-list__value">${
              pageData.reportData?.oldest_license_plate?.product.part_number ||
              "--"
            }</p>
          </li>
        </ul>
      </header>
        <section class="section">
           <h2>Summary</h2>
           <div class="section__content">
            ${pageData.chartSvg}
            <table>
              <thead>
                <tr>
                  <th>PART #</th>
                  <th>QTY</th>
                  <th>DESCRIPTION</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                ${pageData.distByPartNumber
                  .map(
                    (dist, i) =>
                      `<tr>
                <td>${dist.part_number}</td>
                <td>${dist.quantity}</td>
                <td>${dist.product.description}</td>
                <td>${(dist.percentage * 100).toFixed(2)}</td>
              </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
        </section>
            </div>
            ${sheets
              .map(
                (sheet, index) => `
                <div class="page-container">
                <section class="section">
                ${index === 0 ? "<h2>Logs</h2>" : ""}
                  <table class="logs-table">
                  <thead>
              <tr>
                <th>EXT. SN</th>
                <th>PART NUMBER</th>
                <th>PRODUCT QR</th>
                <th>DESCRIPTION</th>
                <th>QUANTITY</th>
                <th>CURRENTLY RESIDES</th>
                <th>PERSON</th>
                <th>ARRIVED</th>
              </tr>
            </thead>
            <tbody>
                 ${sheet
                   .map(
                     log =>
                       `<tr>
                <td>${getExtSerialNumber(
                  log.license_plate.external_serial_number
                )}</td>
                <td>${log.product.part_number}</td>
                <td>3hd.us/...${log?.license_plate?.lp_id?.slice(-5)}</td>
                <td>${log.product.description || "--"}</td>
                <td>${log.license_plate.quantity}</td>
                <td>${checkIfLocationResides(log) ? "&#10003;" : "x"}</td>
                <td>${log.user.first_name}</td>
                <td>${moment(log.arrived_at)
                  .local()
                  .format("DD MMM, YYYY [at] hh:mma")}</td>
              </tr>`
                   )
                   .join("")}
                   </tbody>
          </table>
         </section>
         </div>
            `
              )
              .join("")}
  </body>
</html>`;
};

export default template;
