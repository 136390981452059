
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import styles from './styles';

const useStyles = makeStyles(styles);

function getIcon(variant) {
  switch(variant){
    case 'warning': return WarningIcon;
    case 'error': return ErrorIcon;
    case 'success': return DoneIcon;
    case 'info':
    default: return InfoIcon;
  }
}

const InfoMessage = function(props) {
  const {
    children,
    variant = 'info', // info | warning | error | success
    Icon = getIcon(variant)

  } = props;

  const classes = useStyles(props);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Icon className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography className={classes.message}>{ children }</Typography>
      </Grid>
    </Grid>
  );
};

export default InfoMessage;
