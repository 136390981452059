
import { useState, useEffect } from 'react';
import { getStationReport } from '../../core/system/legacy-services/wms';

function useStationReport(stationId) {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(()=>{
    (async ()=>{
      try{
        setLoading(true);
        const report = await getStationReport(stationId);
        setLoading(false);
        setMessage('');
        setReport(report);
      }
      catch(err){
        setMessage(err.message);
        setLoading(false);
      }
    })();
    
  }, [stationId]);

  return [report, loading, message];
}

export default useStationReport;
