
import { EventEmitter } from 'events';
import { PubSubMessage } from './types';

const emitter = new EventEmitter();

const generateId = (()=>{
  let lastId = 0;
  return ()=>++lastId;
})();

export const subscribe = <T>(topic: string, messageHandler: (message: PubSubMessage<T>)=>void) => {
  emitter.on(topic, messageHandler);
  return function unsubscribe(){ emitter.off(topic, messageHandler); }
};

export const publish = <T>(topic: string, data: T, meta: {[key: string]: any} = {}) => {
  emitter.emit(topic, {
    id: generateId(),
    meta,
    data
  });
};
