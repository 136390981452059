
import React from 'react';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RichTextEditor from '../../core/components/RichTextEditor';

const dynamicFieldsMap = {
  'first_name': 'First name',
  'last_name': 'Last name',
  'email': 'Email address',
  'country_citizenship': 'Country of citizenship',
  'check_in_current.time_in': 'Checkin time',
  'timestamp_nda': 'NDA signing date',
  'location.name': 'Location'
};

const MenuListButton = ({ fieldsMap, onSelect=()=>null, disabled }) => {
  const buttonEl = React.useRef();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const menuClickHandler = key => ev => {
    ev.stopPropagation();
    onSelect(key);
    setMenuOpen(false);
  };

  return (
    <>
      <IconButton innerRef={buttonEl} onClick={()=>setMenuOpen(true)} disabled={disabled}>
        <FontAwesomeIcon icon="plus" size="sm" />
      </IconButton>
      <Popper anchorEl={buttonEl.current} open={menuOpen} style={{zIndex: 99}}>
        <Paper>
          <ClickAwayListener onClickAway={()=>setMenuOpen(false)}>
            <MenuList>
              {Object.keys(fieldsMap).map(key=>(
                <MenuItem key={key} onClick={menuClickHandler(key)}>{fieldsMap[key]}</MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

const EmailTemplateEditingPanel = ({ template={}, label="Email template", onSave=()=>null, disabled, expanded, onExpansionChange=()=>null }) => {
  const [subject, setSubject] = React.useState(template.subject || '');
  const [subjectCursorIndex, setSubjectCursorIndex] = React.useState(0);
  const [body, setBody] = React.useState(template.body || '');
  const [editMode, setEditMode] = React.useState(false);

  async function handleSave() {
    const success = await onSave({ subject, body });
    setEditMode(!success);
  }

  function handleSubjectChange(ev) {
    let cursorIndex = ev.target.selectionStart;
    let subject = ev.target.value;
    const dynamicValueRegExp = /\$\{\w+(\.\w+)?\}/ig;
    let matchArr;

    while((matchArr = dynamicValueRegExp.exec(subject)) !== null){
      const start = matchArr.index;
      const end = start + matchArr[0].length;

      if(cursorIndex > start && cursorIndex < end){
        subject = subject.replace(matchArr[0], '');
        cursorIndex = start;
        break;
      }
    }

    ev.target.value = subject;
    ev.target.setSelectionRange(cursorIndex, cursorIndex);

    setSubject(subject);
    setSubjectCursorIndex(cursorIndex);
  }

  const handleSubjectCursorMove = React.useCallback(ev=>{
    if(typeof ev.target.selectionStart !== 'undefined'){
      setSubjectCursorIndex(ev.target.selectionStart);
    }

  }, []);

  const handleDynamicFieldInsert = React.useCallback((field)=>{
    setSubject(subject.substring(0, subjectCursorIndex) + '${' + field + '}' + subject.substring(subjectCursorIndex));
    const newCursorIndex = subjectCursorIndex + field.length + 3;
    setSubjectCursorIndex(newCursorIndex);

  }, [subjectCursorIndex, subject]);

  return (
    <Accordion expanded={expanded} onChange={onExpansionChange} className="email-template-editing-panel">
      <AccordionSummary expandIcon={<FontAwesomeIcon icon="chevron-down" size="xs" />}>
        <b>{label}</b>
      </AccordionSummary>

      <AccordionDetails>
        <Box flexGrow={1}>
          <div className="panel-body">
            <Typography variant="body2" className="label">Subject</Typography>
            <TextField
              variant="outlined"
              color="secondary"
              fullWidth
              value={subject}
              onKeyDown={handleSubjectCursorMove}
              onClick={handleSubjectCursorMove}
              onFocus={handleSubjectCursorMove}
              onChange={handleSubjectChange}
              disabled={disabled || !editMode}
              InputProps={{
                endAdornment: (
                  <MenuListButton
                    fieldsMap={dynamicFieldsMap}
                    onSelect={handleDynamicFieldInsert}
                    disabled={disabled || !editMode}
                  />
                )
              }}
            />

            <div><br /></div>

            <Typography variant="body2" className="label">Body</Typography>

            {editMode && (
              <RichTextEditor
                EditorProps={{
                  editorClassName: 'editor-body'
                }}
                disabled={disabled}
                contentToEdit={template.body}
                onChange={newBody=>setBody(newBody)}
                dynamicFieldsMap={dynamicFieldsMap}
              />
            )}
            {!editMode && (
              <div dangerouslySetInnerHTML={{ __html: template.body }} />
            )}
          </div>
        </Box>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {editMode && (
          <>
            <Button
              size="small"
              color="primary"
              onClick={()=>setEditMode(false)}
              disabled={disabled}
            >Cancel</Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={disabled}
            >Save</Button>
          </>
        )}
        {!editMode && (
          <Button
            size="small"
            color="secondary"
            onClick={()=>setEditMode(true)}
            disabled={disabled}
          >Edit</Button>
        )}
      </AccordionActions>
    </Accordion>
  );
};

export default EmailTemplateEditingPanel;
