import React, {useState, useEffect} from "react";
import {PlaceTemplateProps} from "./types";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useHistory, useLocation, Link as RouterLink} from "react-router-dom";
import {useAuthentication} from "../../core/providers/AuthenticationProvider";
import {lookupPlace, IPlaceLookupResult} from "../../core/system/places";
import PlaceReport from "./PlaceReport";

const PlaceTemplate: React.FC<PlaceTemplateProps> = ({placeId}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const currentLocation = useLocation();
  const authenticated = !!useAuthentication()[0];
  const [placeLookup, setPlaceLookup] = useState<IPlaceLookupResult | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const lookupResult = await lookupPlace(placeId);
        setPlaceLookup(lookupResult);
        setLoading(false);
      } catch (err) {
        history.replace(`/${placeId}/legacy`);
      }
    })();
  }, [placeId, authenticated, history]);
  return (
    <>
      {loading && (
        <LinearProgress
          style={{position: "fixed", left: 0, right: 0, zIndex: 100, height: 6}}
          variant="query"
        />
      )}
      <Box py={3}>
        <Container maxWidth={false}>
          {authenticated && !!placeLookup && (
            <PlaceReport
              placeId={placeLookup.id}
              placeQR={placeId}
              setLoading={setLoading}
            />
          )}
          {!authenticated && !loading && (
            <Typography align="center">
              Please{" "}
              <Link
                underline="always"
                component={RouterLink}
                to={{pathname: "/login", state: {referrer: currentLocation}}}
              >
                login
              </Link>{" "}
              to verify identity.
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PlaceTemplate;
