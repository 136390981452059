
import axios from 'axios';

const FIREBASE_GET_URL = process.env.REACT_APP_FIREBASE_GET_URL;
const FIREBASE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL;

export function cleanId(id) {
  if(!id)
    return null;

  return id.includes('/') ? id.split("/")[1] : id;
}

export async function getPartDetails(partNo, companyId) {
  try{
    const response = await axios.get(`${FIREBASE_GET_URL}/company/${companyId}/part_number/${partNo}/description.json`);
    if(!response.data)
      throw new Error('Part details not found.');

    const keys = Object.keys(response.data);

    if(keys && keys.length > 0)
      return response.data[keys[0]];
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}

export async function getPersonActivityLog(personId) {
  try{
    const response = await axios.get(`${FIREBASE_BASE_URL}/access_rights/${cleanId(personId)}/log.json`);
    if(!response.data)
      throw new Error('Log not found.');

    return Object.keys(response.data).map(timestampUnix=>({
      timestampUnix,
      url: response.data[timestampUnix]
    }));
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}

export async function getActivities(companyCode) {
  try{
    const response = await axios.get(`${FIREBASE_BASE_URL}/company/${companyCode}/activities.json`);
    if(!response.data)
      throw new Error('Activities not found.');

    return Object.keys(response.data).map(activityId=>response.data[activityId]);
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}

export async function sendActivityToPrinter(activity_url, person_url) {
  try{
    await axios.post(`https://hooks.zapier.com/hooks/catch/1257677/ofkrk26`, {activity_url, person_url}, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }
  catch(err){
    console.dir(err);
    throw err;
  }
}
