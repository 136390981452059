import axios from "axios";

export const MomenttrackIdsMap = {
  activity: 9,
  location: 11,
  person: 17,
  licence_plate: 25,
  document: 32,
  transaction: 36,
  8: "activity",
  9: "activity",
  11: "location",
  17: "person",
  25: "licence_plate",
  32: "document",
  36: "transaction",
};

export default class MomentTrackService {
  constructor(urlPrefix = "3hd.us/") {
    this.urlPrefix = urlPrefix;
  }

  parseId(id) {
    if (!id || typeof id !== "string") throw new Error("Invalid id.");

    const parsedId =
      (id || "")
        .trim()
        .replace(/^\/|\/$/g, "")
        .split("/")
        .pop() || "";
    const type = MomenttrackIdsMap[parsedId.length];

    if (!type) throw new Error("Could not identify type of this id.");

    return {
      id: parsedId,
      url: this.urlPrefix + parsedId,
      type,
    };
  }

  printPdf(pdfUrl, title = "Print job from momenttrack") {
    return axios.post(
      "https://k2vpki1p5i.execute-api.us-west-1.amazonaws.com/prod/printNode",
      {
        printer_url: "3hd.us/b23JFAFladW8KeD5GxFX9jeTK",
        title,
        contentType: "pdf_uri",
        content: pdfUrl,
        expireAfter: 600,
        passkey: "jaredeggettiscool",
      }
    );
  }
}
