import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import EmailIcon from "../../../assets/images/email.png";
import CloseIcon from "@material-ui/icons/Close";
import useNotify from "../../../core/hooks/use-notify";
import {generateCertficatePDF} from "../../../core/system/litmon";
import moment from "moment";
import sendEmail from "../../../core/system/utils/send-email";
import certificate_email_template from "../../../core/system/litmon/label-templates/certificate-email-template";
import {useAuthentication} from "../../../core/providers/AuthenticationProvider";
import {useParams} from "react-router-dom";
import {getBaseUrl} from "../../../core/system/utils";
const useStyles = makeStyles(styles);

function TrainingCertModal({
  editorOpen,
  setEditorOpen,
  title,
  hr_email,
}: {
  editorOpen: boolean;
  setEditorOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  hr_email: string;
}) {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [person] = useAuthentication();
  const {momenttrackId} = useParams<{momenttrackId: string}>();
  const handleSendEmail = async () => {
    try {
      setloading(true);
      const pdf_url = await generateCertficatePDF({
        email:
          email === person?.email
            ? person.first_name + " " + person.last_name
            : email,
        date: moment(new Date()).format("DD MMMM YYYY"),
        title: title,
      });
      await sendEmail({
        email: [email],
        cc: [hr_email],
        subject: `${title} Certification for ${email}, Video: ${getBaseUrl()}/${momenttrackId}`,
        body: certificate_email_template({
          title,
          email,
          pdf_url,
        }),
      });
      notify("Your certificate has been sent to your email address.");
      setEditorOpen(false);
      setAgreedToTerms(false);
    } catch (err) {
      notify(err as Error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (person?.email) {
      setEmail(person.email);
    }
  }, [person?.email]);

  return (
    <Dialog
      maxWidth="xs"
      onClose={() => {
        setEditorOpen(false);
        setAgreedToTerms(false);
      }}
      open={editorOpen}
    >
      <IconButton
        onClick={() => {
          setEditorOpen(false);
          setAgreedToTerms(false);
        }}
        className={classes.modalCloseBtn}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.emailModal} py={4}>
          <img src={EmailIcon} alt="Send mail icon" />
          <Typography variant="h5">Congratulations!</Typography>
          <Typography variant="body1">
            You have successfully completed the training video, please enter
            your email below and we'll send the certificate of completion
          </Typography>
          <form
            className={classes.emailForm}
            onSubmit={async ev => {
              ev.preventDefault();
              handleSendEmail();
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              color="secondary"
              type="email"
              value={email}
              disabled={loading}
              onChange={ev => setEmail(ev.target.value)}
              placeholder="Enter Email Address"
              inputProps={{
                tabIndex: 8,
                height: "45px",
              }}
            />
            <FormControlLabel
              color="primary"
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  color="primary"
                  value={agreedToTerms}
                  onClick={() => setAgreedToTerms(!agreedToTerms)}
                />
              }
              label="I hereby certify that I have watched this video in its entirety."
            />
            <Button
              type="submit"
              variant={loading ? "text" : "contained"}
              color="primary"
              tabIndex={7}
              style={{height: 45}}
              fullWidth
              disabled={
                loading || agreedToTerms === false || !!email.trim() === false
              }
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TrainingCertModal;
