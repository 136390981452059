
import React from 'react';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const Loader = ({label, show, outTransitionDelay=0, variant='standard'}) => {
  const timer = React.useRef(null);
  const [ showHideSmoothly, setShowHideSmoothly ] = React.useState(!!show);
  const [text, setText] = React.useState(label);

  React.useEffect(()=>{
    if(!show)
      timer.current = setTimeout(()=>setShowHideSmoothly(false), outTransitionDelay);
    else
      setShowHideSmoothly(true);

    return ()=>{
      if(timer.current){
        clearTimeout(timer.current);
        timer.current = null;
      }
    }

  }, [show, outTransitionDelay]);

  React.useEffect(()=>{
    if(label !== text && show){
      setText(label);
    }
  }, [label, text, show]);

  return (
    <Fade in={showHideSmoothly} timeout={1000}>
      <div className="loading-indicator">
        <Container maxWidth="sm">
          <Typography variant={variant === 'large' ? 'h4' : 'subtitle2'} className="label-text"><i>{ text || '...' }</i></Typography>
          <CircularProgress size={variant === 'large' ? 48 : 32} />
        </Container>
      </div>
    </Fade>
  );
};

export default Loader;
