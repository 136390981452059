
import React from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useNotify from '../core/hooks/use-notify';

import {
  loadOrCreateGuest,
  loadGuest,
  resetForm,
  performCheckin

} from '../core/redux/actions/checkin-form-actions';
import CheckinForm from './CheckinForm';
import FormTextField from '../core/components/FormTextField';
import QrCodeScanner from '../core/components/QrCodeScanner';

const CheckIn = props => {
  const {
    loading,
    guest,
    loadOrCreateGuest,
    loadGuest,
    resetForm,
    performCheckin,
    history,
    lastActivity
  
  } = props;

  const emailInput = React.useRef();
  const notify = useNotify();
  const timer = React.useRef();

  const goToHome = React.useCallback(function() {
    history.replace('/guest-track');
    
  }, [history]);

  React.useEffect(()=>()=>resetForm(), [resetForm]);

  React.useEffect(()=>{
    function resetTimer() {
      if(timer.current)
        clearTimeout(timer.current);
  
      const duration = 1000 * 60 * 2;
      timer.current = setTimeout(goToHome, duration);
    }

    if(!loading){
      resetTimer();
      window.document.addEventListener('scroll', resetTimer);
    }

    return ()=>{
      window.document.removeEventListener('scroll', resetTimer);
      if(timer.current)
        clearTimeout(timer.current);
    };
    
  }, [lastActivity, loading, goToHome]);

  async function handleCheckinFormSubmission() {
    try{
      await performCheckin();
      // Reset checkin form.
      resetForm();

      history.replace('/guest-track');
      notify('Checkin successful!');
    }
    catch(err){
      notify(err.message);
    }
  }

  async function handleEmailSubmission(ev) {
    try{
      ev.preventDefault();
      const email = emailInput.current.value.trim().toLowerCase();

      if(!email)
        return;

      const emailRegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
      
      if(!emailRegExp.test(email))
        throw new Error('Invalid email address.');

      await loadOrCreateGuest(email);
    }
    catch(err){
      notify(err.message);
    }
  }

  async function handleGuestBadgeScan(person_url) {
    try{
      const guest = await loadGuest(person_url);
      emailInput.current.value = guest.email;
    }
    catch(err){
      notify(err.message);
    }
  }

  return (
    <div className="guest-track-check-in-page">
      <Container maxWidth="sm">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs="auto">
            <IconButton size="medium" color="primary" onClick={()=>goToHome()} disabled={!!loading}>
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="h4">Check in</Typography>
          </Grid>
        </Grid>

        <div><br/><br/><br/></div>


        <div className="form-header">
          <form onSubmit={handleEmailSubmission}>
            <FormTextField
              inputRef={emailInput}
              type="email"
              placeholder="Enter email address..."
              onChange={resetForm}
              onBlur={handleEmailSubmission}
              autoFocus
              required
              disabled={!!loading}
            />
          </form>

          <div>
            <br/><br/>
            <i>(or)</i>
            <br/><br/>
          </div>

          <QrCodeScanner
            label="Scan ID badge"
            facingMode="user"
            disabled={!!loading}
            onResult={handleGuestBadgeScan}
          />
        </div>

        {!!guest && (
          <CheckinForm
            onSubmit={handleCheckinFormSubmission}
          />
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.checkinForm.loading,
    guest: state.checkinForm.guest,
    lastActivity: state.checkinForm.lastActivity
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadOrCreateGuest: email=>dispatch(loadOrCreateGuest(email)),
    loadGuest: personUrl=>dispatch(loadGuest(personUrl)),
    resetForm: ()=>dispatch(resetForm()),
    performCheckin: ()=>dispatch(performCheckin())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
