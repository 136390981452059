
import { useState, useEffect } from 'react';
import { firebaseService } from '../system/legacy-services/firebase';

const useLocation = function(locationId) {
  const [location, setLocation] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(!locationId)
      return;
      
    let effective = true;

    (async ()=>{
      try{
        setLoading(true);
        const location = await firebaseService.getLocation(locationId);
        if(effective){
          setLocation(location);
          setMessage('');
          setLoading(false);
        }
      }
      catch(err){
        if(effective){
          setLocation(null);
          setMessage(err.message);
          setLoading(false);
        }
      }
    })();

    return ()=>{ effective = false; };

  }, [locationId]);

  return [location, loading, message];
}

export default useLocation;
