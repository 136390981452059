
import React from 'react';
import TextField from '@material-ui/core/TextField';

const FormTextField = React.forwardRef(({label, required, ...otherProps}, ref)=>{
  return (
    <div className="form-text-field">
      {!!label && <label>{label}{required && ' *'}</label>}
      <TextField
        ref={ref}
        variant="outlined"
        fullWidth
        color="secondary"
        required={required}
        {...otherProps}
      />
    </div>
  );
});

export default FormTextField;
