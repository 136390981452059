
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HomePage from './HomePage';

/**
 * BEGIN: "LearningInTheMoment" component
 */
const LearningInTheMoment: React.FC = props => {
  return (
    <Switch>
      <Route path="/" component={HomePage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default LearningInTheMoment;
