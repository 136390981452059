import {
  faSync,
  faSignOutAlt,
  faEdit,
  faTrashAlt,
  faSpinner,
  faPlus,
  faPlusCircle,
  faLock,
  faUser,
  faSearch,
  faTrash,
  faVideo,
  faQrcode,
  faImage,
  faIdCardAlt,
  faSchool,
  faBars,
  faChevronLeft,
  faTimes,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"

const Icons = () => {
  return library.add(
    faSignOutAlt,
    faEdit,
    faTrashAlt,
    faSpinner,
    faPlus,
    faPlusCircle,
    faLock,
    faUser,
    faSearch,
    faTrash,
    faVideo,
    faQrcode,
    faImage,
    faIdCardAlt,
    faSchool,
    faBars,
    faChevronLeft,
    faTimes,
    faChevronDown,
    faSync
  )
}

export default Icons