
import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function getAlternateVideoUrl(url) {
  if(typeof url !== 'string' || !url.trim() || url.includes('/uploads') < 0)
    return url;

  return url.replace('/uploads', '/uploads_compressed');
}

const LitmonDocument = props => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { data } = props;

  const hasSteps = data.steps && Array.isArray(data.steps);

  return (
    <Container>
      <div className='litmon-document'>
        <video controls className="video">
          <source src={data.video} />
          <source src={getAlternateVideoUrl(data.video)} />
          Your browser does not support the video tag.
        </video>
        <Grid container>
          <Grid item xs md={10}>
            <Typography variant="h4" gutterBottom>{ data.title }</Typography>
            {/* <Typography variant="body2">
              TRAINING description can go here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vivamus porta magna at augue ultricies, vulputate euismod quam elementum.
              Aenean nec ipsum eros. Mauris maximus dapibus efficitur.
            </Typography> */}
          </Grid>
        </Grid>

        <div className="info-container">
          <Tabs value={0}
            onChange={(ev, newVal) => setTabIndex(newVal)}
            variant="fullWidth"
            className="info-tabs"
          >
            <Tab label="Steps to remember" />
          </Tabs>

          <Box hidden={tabIndex !== 0} textAlign="center">
            {!hasSteps && (
              <Typography
                variant="body1"
                className="info-message-container"
              >
                No steps listed.
              </Typography>
            )}

            {hasSteps && data.steps.map((step, index) => (
              <div key={`step-${index+1}`} className="step-row">
                <Grid container direction={(index % 2 === 0) ? 'row' : 'row-reverse'} justifyContent="center">
                  <Grid item md={4} />
                  <Grid item>
                    <div className="step-stop">
                      <span className="line"/>
                      <div className="step-index">
                        <Typography variant="h5">{index + 1}</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs md={4}>
                    <Box align={(index % 2 === 0) ? 'left' : 'right'} className="step-content">
                      <Typography variant="h6" gutterBottom>{step}</Typography>
                      {/* <Typography variant="body2">
                        STEP description can go here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vivamus porta magna at augue ultricies, vulputate euismod quam elementum.
                      </Typography> */}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Box>
        </div>
      </div>
    </Container>
  );
}

export default LitmonDocument;
