
import React, { useState, useEffect, useImperativeHandle, useCallback, forwardRef } from 'react';
import { firebaseService } from '../core/system/legacy-services/firebase';
import { cleanId, sendActivityToPrinter } from '../core/system/legacy-services/firebase-old';
import { makeStyles } from '@material-ui/core/styles';
import useNotify from '../core/hooks/use-notify';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CheckBox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import InfoMessage from '../core/components/InfoMessage';

const useStyles = makeStyles(theme=>({
  root: ({selected, message})=>({
    minHeight: '100%',
    border: `1px solid ${selected ? theme.palette.secondary.main : theme.palette.grey[400]}`,
    opacity: selected || message ? 1 : 0.6,
    backgroundColor: selected ? theme.palette.grey[200] : theme.palette.common.white,
    cursor: 'pointer',
    transition: 'all 0.25s',

    [`&:hover`]: {
      opacity: 0.85,
      backgroundColor: theme.palette.grey[100]
    }
  })
}));

const ActivityCard = function(props, ref) {
  const {
    activityUrl,
    personUrl,
    selected = false,
    onChange

  } = props;

  const [activity, setActivity] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const disabled = loading || props.disabled || !activity;

  const sendToPrinter = useCallback(async ()=>{
    try{
      setLoading(true);
      setMessage({type: 'info', msg: 'Sending to printer...'});

      await sendActivityToPrinter(activityUrl, personUrl);
      setMessage({type: 'success', msg: 'Sent to printer.'});
    }
    catch(err){
      setMessage({type: 'error', msg: err.message});
      notify(err.message);
    }
    finally{
      setLoading(false);
    }
  }, [activityUrl, personUrl, notify]);

  useImperativeHandle(ref, ()=>({ sendToPrinter }), [sendToPrinter]);

  useEffect(()=>{
    async function loadActivity(){
      try{
        setLoading(true);
        const activity = await firebaseService.getActivity(activityUrl);
        setActivity(activity);
      }
      catch(err){
        setMessage({type: 'error', msg: err.message});
      }
      finally{
        setLoading(false);
      }
    }

    loadActivity();
  }, [activityUrl]);

  const classes = useStyles({...props, message});

  return (
    <Paper square elevation={0} className={classes.root} onClick={(ev)=>!disabled && onChange(ev, !selected)}>
      <Box p={2}>
        <Grid container spacing={1}>
          {!!activity && <Grid item xs>
            <Typography variant="h6" color={selected ? 'secondary' : 'textPrimary'}>{activity.activityTitle || '--'}</Typography>
            <Link
              target="_BLANK"
              href={cleanId(activityUrl)}
              onClick={ev=>ev.stopPropagation()}
            >{activityUrl}</Link>
          </Grid>}
          <Grid item>
            {!!activity && !loading && (
              <CheckBox
                edge="end"
                checked={selected}
                onChange={onChange}
                disabled={disabled}
              />
            )}
            {loading && <CircularProgress size={24} color="secondary" />}
          </Grid>
        </Grid>
      </Box>
      {!!activity && (
        <Box p={2}>
          <Typography variant="subtitle2" color="textSecondary">{activity.activityDescription || '--'}</Typography>
        </Box>
      )}
      {!!message && (
        <Box p={2}>
          <InfoMessage variant={message.type}>{message.msg}</InfoMessage>
        </Box>
      )}
    </Paper>
  );
};

export default forwardRef(ActivityCard);
