import randomize from "randomatic";

export type MomenttrackIdType =
  | "document"
  | "license_plate"
  | "activity"
  | "place"
  | "person"
  | "transaction"
  | "datamatrix"
  | "unknown";

export interface MomenttrackIdInfo {
  id: string;
  url: string;
  type: MomenttrackIdType;
}

export const MomenttrackIdsMap: {[key: string]: MomenttrackIdType | number} = {
  "8": "activity",
  "9": "activity",
  "11": "place",
  "17": "person",
  "25": "license_plate",
  "32": "document",
  "36": "transaction",
  "0": "unknown",
  activity: 9,
  place: 11,
  person: 17,
  license_plate: 25,
  document: 32,
  transaction: 36,
  unknown: 0,
};

export const generateIds = (type: MomenttrackIdType, qty: number) => {
  if (qty < 1 || !qty) return [];

  return Array(qty)
    .fill("")
    .map(() => randomize("A0", MomenttrackIdsMap[type as string] as number));
};

// copy to clipboard
export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

const parseMomenttrackId = (id: string): MomenttrackIdInfo => {
  const parsedId =
    (id || "")
      .trim()
      .replace(/^\/|\/$/g, "")
      .split("/")
      .pop() || "";
  let type: MomenttrackIdType = "unknown";
  if (parsedId.startsWith("[)>")) {
    type = "datamatrix";
  } else {
    type =
      (MomenttrackIdsMap[parsedId.length.toString()] as MomenttrackIdType) ||
      false ||
      "unknown";
  }

  return {
    id: parsedId,
    url: `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
      type === "datamatrix" ? id : parsedId
    }`,
    type,
  };
};

export default parseMomenttrackId;
