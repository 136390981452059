
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 100,
    fontSize: 20
  }
});

export default styles;
