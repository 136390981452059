import {createStyles} from "@material-ui/core/styles";

const styles = theme =>
  createStyles({
    root: {
      position: "relative",
    },
    table: {
      tableLayout: "auto",
      width: "100%",
      marginBottom: theme.spacing(6),
      minWidth: 1000,
    },
    tableCell: {
      // whiteSpace: 'nowrap',
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "top",
    },
    loadingOverlay: {
      position: "absolute",
      zIndex: 999,
      backgroundColor: "rgba(255,255,255,0.5)",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circularProgressContainer: {
      padding: theme.spacing(3),
      backgroundColor: "rgba(255,255,255,0.9)",
      borderRadius: 1000,
      lineHeight: 0,
      boxShadow: theme.shadows[2],
    },
  });

export default styles;
