
import React from 'react';
import moment from 'moment/moment';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';

export default function GuestInfoCard({ guest, onCheckout=()=>null, disabled }) {
  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={guest.picture} />}
        title={guest.name}
        subheader={guest.email}
      />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Check in time</TableCell>
                <TableCell><i>{moment.unix(guest.time_in).format('ddd, MMMM Do - hh:mm a')}</i></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Check in location</TableCell>
                <TableCell><i>{guest.location}</i></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Visiting</TableCell>
                <TableCell><i>{guest.visiting}</i></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Escort required</TableCell>
                <TableCell><i>{guest.escort_required ? 'Yes' : 'No'}</i></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          disabled={disabled}
          onClick={onCheckout}
        >Checkout</Button>
      </CardActions>
    </Card>
  );
};
