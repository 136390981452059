
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadNdas, updateNda } from '../../core/redux/actions/admin-console-actions';
import useNotify from '../../core/hooks/use-notify';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinearProgress from '../../core/components/LinearProgress';
import NdaEditingPanel from './NdaEditingPanel';

export default function NdaTemplates() {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { loading: { ndas: loading }, ndas } = useSelector(state=>state.adminConsole);
  const [expandedPanel, setExpandedPanel] = useState('');

  const reload = useCallback(()=>{
    dispatch(loadNdas())
      .catch(notify)
    ;
  }, [notify, dispatch]);

  useEffect(()=>{
    if(ndas === null)
      reload();
  }, [reload, ndas]);

  const ndaSaveHandler = ndaType => async updatedNda => {
    dispatch(updateNda(ndaType, updatedNda))
      .then(message=>{
        notify(message);
        return true;
      })
      .catch(err=>{
        notify(err.message);
        return false;
      })
    ;
  };

  const panelExpansionHandler = panelName => (ev, isExpanded) => {
    setExpandedPanel(isExpanded ? panelName : false);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item><Typography variant="h5">NDA Templates</Typography></Grid>
        <Grid item><IconButton onClick={reload}><FontAwesomeIcon icon="sync" /></IconButton></Grid>
      </Grid>
      <LinearProgress show={loading} />
      <div style={{ marginBottom: 16 }} />

      {!!ndas && Object.keys(ndas).map(ndaType=>(
        <NdaEditingPanel
          key={ndaType}
          nda={ndas[ndaType]}
          label={ndaType}
          disabled={loading}
          expanded={expandedPanel === ndaType}
          onExpansionChange={panelExpansionHandler(ndaType)}
          onSave={ndaSaveHandler(ndaType)}
        />
      ))}
    </div>
  );
}
