import React, {forwardRef} from "react";
import {FileUploaderProps, FileUploaderRef} from "../types";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";
import FileUploader from "../FileUploader";

const FILE_TYPES = [
  "mp4",
  "mpg",
  "mpeg",
  "wmv",
  "mov",
  "avi",
  "flv",
  "mkv",
  "webm",
];

const VideoUploader: React.ForwardRefRenderFunction<
  FileUploaderRef,
  FileUploaderProps & {disabled?: boolean}
> = ({fileTypes = FILE_TYPES, disabled, ...props}, ref) => {
  return (
    <FileUploader
      inputId="video-uploader"
      filetype="video/*"
      ref={ref}
      {...props}
    >
      {({
        fileInput, // JSX.Element for the file input
        selectedFile, // Selected file or null
        selectedFileURL, // URL of the selected file or null
        isUploading, // Boolean indicating upload status
        progress, // Upload progress as a number
        clearSelection, // Function to clear the file selection
      }) => (
        <>
          <Paper variant="outlined">
            <Box style={{position: "relative"}} height={400} bgcolor="grey.100">
              {fileInput}
              {(selectedFileURL || props.initialValue) && (
                <video
                  key={selectedFileURL || props.initialValue}
                  controls
                  style={{
                    width: "100%",
                    maxHeight: 400,
                    zIndex: 10,
                    position: "relative",
                  }}
                >
                  <source src={selectedFileURL || props.initialValue} />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
            <Box p={2} textAlign="center" bgcolor="grey.100">
              <Typography variant="body2" gutterBottom>
                {isUploading || progress === 100
                  ? `Uploading ${progress}%...`
                  : "Drop video here... (or)"}
              </Typography>

              <Button
                size="large"
                component="label"
                htmlFor="video-uploader"
                variant={!selectedFile ? "contained" : "outlined"}
                color="secondary"
                startIcon={<Icon>movie</Icon>}
                disabled={isUploading || disabled}
              >
                Choose {!!selectedFile && "a different "}video
              </Button>

              <Box mt={1}>
                {!selectedFile ? (
                  <>
                    <Typography
                      style={{wordWrap: "break-word"}}
                      variant="body2"
                    >
                      {props.initialValue || "No video selected."}
                    </Typography>
                    <Typography variant="caption">
                      Max file size: 100 MB
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      {selectedFile.name}{" "}
                      <IconButton
                        size="small"
                        onClick={clearSelection}
                        disabled={disabled || isUploading}
                      >
                        <Icon color="error">clear</Icon>
                      </IconButton>
                    </Typography>
                    <Typography variant="caption">
                      {(selectedFile.size / 1024 / 1024)
                        .toFixed(2)
                        .toLocaleString()}{" "}
                      MB
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{backgroundColor: "#ffffff"}}
              color="secondary"
            />
          </Paper>
        </>
      )}
    </FileUploader>
  );
};

export default forwardRef(VideoUploader);
