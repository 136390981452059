
import {
  getCurrentGuests,
  getNdas,
  getEmailTemplates,
  getSecurityAlertEmail,
  updateNdas,
  updateEmailTemplates,
  updateSecurityAlertEmail,
  checkOut
} from '../../system/legacy-services/guest-track';

export const ACTION_TYPES = {
  SET_LOADING: 'GUEST_TRACK_SET_LOADING',
  SET_SECURITY_EMAIL: 'GUEST_TRACK_SET_SECURITY_EMAIL',
  SET_NDA_TEMPLATES: 'GUEST_TRACK_SET_NDA_TEMPLATES',
  SET_EMAIL_TEMPLATES: 'GUEST_TRACK_SET_EMAIL_TEMPLATES',
  SET_CURRENT_GUESTS: 'GUEST_TRACK_SET_CURRENT_GUESTS',
  REMOVE_GUEST: 'GUEST_TRACK_REMOVE_GUEST',
  RESET_DATA: 'GUEST_TRACK_RESET_DATA'
};

function setLoading(key, value) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: {[key]: value} // e.g. {security_alert_email: true}
  };
}

function setSecurityEmail(email) {
  return {
    type: ACTION_TYPES.SET_SECURITY_EMAIL,
    payload: email
  };
}

function setNdas(ndas) {
  return {
    type: ACTION_TYPES.SET_NDA_TEMPLATES,
    payload: ndas
  };
}

function setEmailTemplates(templates) {
  return {
    type: ACTION_TYPES.SET_EMAIL_TEMPLATES,
    payload: templates
  };
}

function setCurrentGuests(guests) {
  return {
    type: ACTION_TYPES.SET_CURRENT_GUESTS,
    payload: guests
  };
}

export function removeGuest(access_rights) {
  return {
    type: ACTION_TYPES.REMOVE_GUEST,
    payload: access_rights
  };
}

export function resetConsoleData() {
  return {
    type: ACTION_TYPES.RESET_DATA
  };
}

export function loadCurrentGuests() {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('current_guests', true));

      const guests = await getCurrentGuests(company_code);

      dispatch(setCurrentGuests(guests));
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('current_guests', false));
    }
  };
}

export function loadNdas() {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('ndas', true));

      const ndas = await getNdas(company_code);

      dispatch(setNdas(ndas));
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('ndas', false));
    }
  };
}

export function loadEmailTemplates() {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('email_templates', true));

      const templates = await getEmailTemplates(company_code);

      dispatch(setEmailTemplates(templates));
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('email_templates', false));
    }
  };
}

export function loadSecurityEmail() {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('security_alert_email', true));

      const email = await getSecurityAlertEmail(company_code);

      dispatch(setSecurityEmail(email));
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('security_alert_email', false));
    }
  };
}

export function updateNda(type, data) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('ndas', true));

      const message = await updateNdas({[type]: data}, company_code);
      dispatch(setNdas({[type]: data}));

      return message;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('ndas', false));
    }
  };
}

export function updateEmailTemplate(type, data) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('email_templates', true));

      const message = await updateEmailTemplates({[type]: data}, company_code);
      dispatch(setEmailTemplates({[type]: data}));

      return message;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('email_templates', false));
    }
  };
}

export function updateSecurityEmail(email) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('security_alert_email', true));

      const message = await updateSecurityAlertEmail(email, company_code);
      dispatch(setSecurityEmail(email));

      return message;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('security_alert_email', false));
    }
  };
}

export function checkoutGuest(access_rights) {
  return async (dispatch, getState) => {
    try{
      const company_code = getState().checkinAuth.access_rights.company_code;
      dispatch(setLoading('current_guests', true));

      const message = await checkOut(access_rights, company_code);
      dispatch(removeGuest(access_rights));

      return message;
    }
    catch(err){
      throw err;
    }
    finally{
      dispatch(setLoading('current_guests', false));
    }
  };
}
