import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { ProductionOrderDetailsTemplateProps } from "./types";
import { cardStyles } from "../style";
import { makeStyles } from "@material-ui/core/styles";

const BooleanMap: any = {
  true: "Yes",
  false: "No",
};
const useCardStyles = makeStyles(cardStyles);
const ProductionOrderDetailsTemplate: React.FC<
  ProductionOrderDetailsTemplateProps
> = props => {
  const { template, org_template } = props;
  const classes = useCardStyles();
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    // const sortedTemplate = Object.entries(template).sort(([, a], [, b]) => {
    //   return a.order - b.order;
    // });
    if (template && org_template) {
      const tabSeparatedValues = Object.entries(template)
        .map(([key, item]) => {
          if (
            item.toString() === "true" ||
            item.toString() === "false"
          ) {
            return "%09";
          } else if (
            org_template
              ? org_template[key].type === "select"
              : false
          ) {
            return `${item}%0D%0A`;
          } else return item;
        })
        .join("%09");
      setQrCode(tabSeparatedValues);
    }
  }, [org_template, template]);
  return (
    <Box px={2} py={1}>
      <Typography variant="h5">Order Details</Typography>

      <Box display="flex" alignItems={"center"} flexWrap={"wrap"}>
        <div className={classes.details_wrap}>
          {(org_template && template) && Object.entries(org_template)
            .map(([key, value]) => (
              <div>
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  {value.label}:
                </Typography>

                <Typography variant="body2">
                  {template[key].toString() === "true" ||
                    template[key].toString() === "false"
                    ? BooleanMap[template[key].toString()]
                    : template[key].toString()}
                </Typography>
              </div>
            ))}
        </div>
        <Box className={classes.qr_box} sx={{ mx: "auto" }}>
          <img
            alt="place qr code"
            src={`https://api.3hd.us/app/qrcode_gen.create?data=${qrCode}`}
            style={{ width: "3in" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductionOrderDetailsTemplate;
