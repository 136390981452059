
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthentication } from '../core/providers/AuthenticationProvider';
import AuthenticationForm from '../core/forms/AuthenticationForm';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './styles';

const useStyles = makeStyles(styles);

const Login = () => {
  const authForm = useRef(null);
  const classes = useStyles();
  const [, busy, {login}] = useAuthentication();

  const handleLogin = ({ email, password }) => login(email, password);

  return (
    <div className={classes.root}>
      <Container disableGutters maxWidth="xs">
        <Paper square>
          {busy && <LinearProgress />}
          <Box p={3}>
            <div className={classes.formHeader}>
              <Typography variant="h4">Authenticate</Typography>
              <Typography variant="body2" color="textSecondary">Login below to access the system</Typography>
            </div>

            <div className={classes.formContainer}>
              <AuthenticationForm
                ref={authForm}
                onSubmit={handleLogin}
                disabled={busy}
              />
            </div>

            <Button className={classes.submitBtn}
              onClick={()=>authForm.current?.submitForm()}
              disabled={busy}
              size="large"
              variant="contained"
              color="primary"
              fullWidth
            >Login</Button>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
