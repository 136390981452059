
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadCurrentGuests, checkoutGuest } from '../../core/redux/actions/admin-console-actions';
import useNotify from '../../core/hooks/use-notify';
import LinearProgress from '../../core/components/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GuestInfoCard from './GuestInfoCard';

export default function CurrentGuests() {
  const notify = useNotify();
  const dispatch = useDispatch();
  const {loading: {current_guests: loading}, current_guests} = useSelector(state=>state.adminConsole);
  const [searchQuery, setSearchQuery] = useState('');

  const reload = useCallback(()=>{
    dispatch(loadCurrentGuests())
      .catch(notify)
    ;
  }, [notify, dispatch]);

  useEffect(()=>{
    if(current_guests === null)
      reload();
  }, [reload, current_guests]);

  const getFilteredRecords = useCallback(()=>{
    if(!searchQuery)
      return current_guests || [];

    return (current_guests || []).filter(g=>JSON.stringify(g).toLowerCase().includes(searchQuery.toLowerCase()));

  }, [searchQuery, current_guests]);

  const handleCheckout = useCallback((guest)=>{
    if(window.confirm(`Checkout guest "${guest.name}".`)){
      dispatch(checkoutGuest(guest.access_rights))
        .then(notify)
        .catch(notify)
      ;
    }
    else{
      notify('Canceled.')
    }
  }, [notify, dispatch]);

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item><Typography variant="h5">Current Guests</Typography></Grid>
        <Grid item><IconButton onClick={reload}><FontAwesomeIcon icon="sync" /></IconButton></Grid>
      </Grid>
      <LinearProgress show={loading} />
      <div style={{ marginBottom: 16 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            placeholder="Search here..."
            variant="outlined"
            color="secondary"
            fullWidth
            value={searchQuery}
            onChange={ev=>setSearchQuery(ev.target.value)}
            disabled={loading}
          />
        </Grid>
      </Grid>

      <div><br /></div>

      {!loading && (!current_guests || !current_guests.length) && (
        <Typography variant="body2">No guests have currently checked in!</Typography>
      )}

      <Grid container spacing={2}>
        {getFilteredRecords().map((guest)=>(
          <Grid key={guest.access_rights} item xs={12} sm={6} md={4}>
            <GuestInfoCard
              guest={guest}
              onCheckout={()=>handleCheckout(guest)}
              disabled={loading}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
