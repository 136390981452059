
import React, { useState, useRef } from 'react';
import { firebaseService } from '../core/system/legacy-services/firebase';
import { getActivities } from '../core/system/legacy-services/firebase-old';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import QrCodeScanner from '../core/components/QrCodeScanner';
import InfoMessage from '../core/components/InfoMessage';
import ActivityCard from './ActivityCard';
import PrintIcon from '@material-ui/icons/Print';
import useNotify from '../core/hooks/use-notify';

const PrintActivities = function(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [activities, setActivities] = useState(null);
  const [selectionMap, setSelectionMap] = useState({});
  const refsMap = useRef({});
  const notify = useNotify();

  function handleSelection(id, selected) {
    setSelectionMap(map=>({ ...map, [id]: !!selected }));
  }

  async function handleSelectionSubmit() {
    try{
      const activityIds = Object.keys(selectionMap);
      if(!activityIds.length)
        throw new Error('Select activities.');

      setLoading(true);
      await Promise.all(activityIds.map(id=>selectionMap[id] && refsMap.current[id].sendToPrinter()));
      setSelectionMap({});
    }
    catch(err){
      notify(err.message);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleIdScan(id) {
    try{
      setLoading(true);
      const user = await firebaseService.getPerson(id);
      const activities = await getActivities(user.company_code);

      setUser(user);
      setActivities(activities);
    }
    catch(err){
      notify(err.message);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Box pt={3} pb={20}>
      <Container>
        <Paper>
          <Box p={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="h6">Print activities</Typography>
              </Grid>
              <Grid item>
                <QrCodeScanner
                  label="SCAN ID"
                  onResult={handleIdScan}
                  ButtonProps={{
                    variant: 'contained',
                    color: "primary",
                    size: "small"
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {loading && <LinearProgress color="secondary"/>}
          <Divider />
          {!!user && (
            <>
              <Box p={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar />
                  </Grid>
                  <Grid item>
                    <Typography>{user.person_first} {user.person_last}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">{user.email_person}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box px={2} pb={2}>
                <InfoMessage Icon={BusinessCenterIcon} variant="info">{user.Company_name}</InfoMessage>
              </Box>
              <Divider />
            </>
          )}
          <Box p={2}>
            {!loading && !activities && <InfoMessage>Scan id badge to proceed.</InfoMessage>}
            {!!activities && (
              <>
                <Box py={2}>
                  <Typography variant="subtitle1" gutterBottom>SELECT ACTIVITIES TO PRINT.</Typography>
                </Box>

                <Grid container spacing={2}>
                  {activities.map((activity, i)=>(
                    <Grid item key={''+i} xs={12} sm={6} md={4}>
                      <ActivityCard
                        ref={activityRef=>refsMap.current[activity.activity_url] = activityRef}
                        personUrl={user.person_url}
                        activityUrl={activity.activity_url}
                        selected={!!selectionMap[activity.activity_url]}
                        disabled={loading}
                        onChange={(ev, selected)=>handleSelection(activity.activity_url, selected)}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      size="large"
                      variant="contained"
                      fullWidth
                      color="secondary"
                      startIcon={<PrintIcon />}
                      disabled={loading}
                      onClick={handleSelectionSubmit}
                    >SEND TO PRINTER</Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default PrintActivities;
