
import React from 'react';
import { ProtectedRouteComponent, LocationState } from './types';
import { Route, Redirect, useLocation } from 'react-router-dom';

const ProtectedRoute: ProtectedRouteComponent = ({ allow, redirectPath, ...routeProps }) => {
  const currentLocation = useLocation<LocationState>();

  if(allow)
    return <Route {...routeProps} />;
  else{
    const redirectTo = redirectPath && ({
      pathname: redirectPath,
      state: {
        referrer: currentLocation
      }
    });

    return (
      <Redirect to={redirectTo || currentLocation.state?.referrer || '/'} />
    );
  }
};

export default ProtectedRoute;
