import moment from "moment";

export function removeKeyFromObject(
  obj: Record<string, string>,
  keyToRemove: string
) {
  const {[keyToRemove]: _, ...rest} = obj;
  return rest;
}

const getDatamatrixLabel = (
  parsedData: Record<string, string>,
  qrCode: string
) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Datamatrix Label</title>
        <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
        }
        .container {
            color: rgba(0, 0, 0, 0.87);
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.08);
            width: 100%;
            max-width: 4in;
            margin: 20px auto;
            border-spacing: 0;
        }
        .content {
            background-color: white;
            padding: 0px 20px;
        }
        .footer {
            text-align: center;
            font-size: 14px;
        }
        .footer td {
            padding: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
        }
        .qr-code {
            width: 55px;
        }
        </style>
    </head>
    <body>
        <table class="container">
        <tr>
            <td colspan="3" class="content">
            ${Object.entries(
              removeKeyFromObject(parsedData, "(MT) Momentrack's code")
            )
              .map(
                ([key, value], i) =>
                  `<p style="font-size: 13px; ${
                    i > 0 ? "margin-top: -10px" : ""
                  }">
                <span style="padding-right: 0.4rem; display: inline; min-width: max-content"
                    >${key.split("\n").join("")}: </span
                ><strong>${value.split("\n").join("")}</strong>
                </p>
                <p>
                    <img style="height: 30px; margin: -8px 0 0 0" src="https://barcode.tec-it.com/barcode.ashx?data=${value}&code=Code39">
                </p>
                `
              )
              .join("")}
            </td>
        </tr>
        <tr class="footer">
        <td>
        <img
        alt="momentrack qr code"
        src="https://api.3hd.us/app/qrcode_gen.create?data=3hd.us/${
          Object.entries(parsedData)
            ?.find(el => el[0].includes("MT"))?.[1]
            ?.split("-")[1] || ""
        }&logo=momenttrack"
        class="qr-code"
        />
        </td>
        <td>
            <p>RoHS Compliant</p>
            <p>Seal Date: ${moment().format("DD/MM/YYYY")}</p>
            </td>
            <td>
            <img
                alt="QR Code 2"
                src="https://barcode.tec-it.com/barcode.ashx?data=${qrCode}&code=MobileSemaUrl"
                class="qr-code"
            />
        </td>
        </tr>
        </table>
    </body>
    </html>
 `;
};

export default getDatamatrixLabel;
