
import React, { useState, useMemo, useImperativeHandle, forwardRef, useCallback } from 'react';
import moment from 'moment';
import { firebaseService } from '../../../../core/system/legacy-services/firebase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';
import MagicText from '../../../../core/components/MagicText';

const ReportDialog = function(props, ref) {
  const { document, documentUrl, activityLogs = [] } = props;

  const [open, setOpen] = useState(false);
  const ticketStatus = (()=>{
    if(document.activeTicket){
      if(document.unknownCustomer)
        return { status: 'pending', title: 'Pending ticket' };
      else
        return { status: 'putaway', title: 'Putaway ticket' };
    }
    else{
      if(document.voidedby)
        return { status: 'void', title: 'Void ticket' };
      else
        return { status: 'closed', title: 'Closed ticket' };
    }
  })();

  const personsInvolved = useMemo(()=>{
    return activityLogs.map(log=>log.personId).filter((val, index, self)=>self.indexOf(val) === index);
  }, [activityLogs]);

  const getPersonName = useCallback(async (personId)=>{
    const person = await firebaseService.getPerson(personId);
    return person.person_first + ' ' + person.person_last;
  }, []);

  useImperativeHandle(ref, ()=>{
    function openDialog() {
      setOpen(true);
    }

    return { openDialog };
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={()=>setOpen(false)}
    >
      <DialogTitle><b>Report</b></DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">STATUS</Typography>
            <Typography variant="body1">{ ticketStatus.title }</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">TICKET</Typography>
            <Typography variant="body1">3hd.us/...{ documentUrl.slice(-5) }</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">CREATED BY</Typography>
            <Typography>
              <Link
                variant="body1"
                component={RouterLink}
                target="_BLANK"
                to={`//${document.person_url}`}
                underline="always"
              >
                <MagicText magicInput={document.person_url} magicFunction={getPersonName}>
                  3hd.us/...{ document.person_url.slice(-5) }
                </MagicText>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">CREATED AT</Typography>
            <Typography variant="body2">{ moment.unix(document.timestamp).format('MMM DD, YYYY - hh:mm A') }</Typography>
          </Grid>
          {typeof document.conversion === 'object' && (
            <>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">CONVERTED BY</Typography>
                <Typography>
                  <Link
                    variant="body1"
                    component={RouterLink}
                    target="_BLANK"
                    to={`//${document.conversion.person_url}`}
                    underline="always"
                  >
                    <MagicText magicInput={document.conversion.person_url} magicFunction={getPersonName}>
                      3hd.us/...{ document.conversion.person_url.slice(-5) }
                    </MagicText>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">CONVERTED AT</Typography>
                <Typography variant="body2">{ moment.unix(document.conversion.timestamp).format('MMM DD, YYYY - hh:mm A') }</Typography>
              </Grid>
            </>
          )}
          {typeof document.closedby === 'object' && (
            <>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">CLOSED BY</Typography>
                <Typography>
                  <Link
                    variant="body1"
                    component={RouterLink}
                    target="_BLANK"
                    to={`//${document.closedby.person_url}`}
                    underline="always"
                  >
                    <MagicText magicInput={document.closedby.person_url} magicFunction={getPersonName}>
                      3hd.us/...{ document.closedby.person_url.slice(-5) }
                    </MagicText>
                  </Link>
                </Typography>
              
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">CLOSED AT</Typography>
                <Typography variant="body2">{ moment.unix(document.closedby.timestamp).format('MMM DD, YYYY - hh:mm A') }</Typography>
              </Grid>
            </>
          )}
          {typeof document.voidedby === 'object' && (
            <>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">VOIDED BY</Typography>
                <Typography>
                  <Link
                    variant="body1"
                    component={RouterLink}
                    target="_BLANK"
                    to={`//${document.voidedby.person_url}`}
                    underline="always"
                  >
                    <MagicText magicInput={document.voidedby.person_url} magicFunction={getPersonName}>
                      3hd.us/...{ document.voidedby.person_url.slice(-5) }
                    </MagicText>
                  </Link>
                </Typography>
              
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">VOIDED AT</Typography>
                <Typography variant="body2">{ moment.unix(document.voidedby.timestamp).format('MMM DD, YYYY - hh:mm A') }</Typography>
              </Grid>
            </>
          )}
        </Grid>

        <Box mt={5}>
          <Typography variant="h6" ><b>Persons involved</b></Typography>

          <div><br /></div>

          <Grid container direction="column" spacing={2}>
          {personsInvolved.map(personId=>(
            <Grid key={personId} item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Avatar><PersonIcon /></Avatar>
                </Grid>
                <Grid item>
                  <Typography>
                    <Link
                      variant="body1"
                      component={RouterLink}
                      target="_BLANK"
                      to={`/${personId}`}
                      underline="always"
                    >
                      <MagicText magicInput={personId} magicFunction={getPersonName}>
                        3hd.us/{ personId }
                      </MagicText>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={()=>setOpen(false)}
        >Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ReportDialog);
