
import React/*, { useState, useEffect }*/ from 'react';
// import * as firebase from '../../../../services/firebase-old';
// import Moment from 'moment/moment';
// import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
// import CircularProgress from '@material-ui/core/CircularProgress';

function padString(str, outputLength, lastDigitsCount) {
  if(!str || typeof str !== 'string' || str.length < lastDigitsCount)
    return null;

  const starsCount = outputLength - lastDigitsCount;
  return '*'.repeat(starsCount) + str.substring(str.length-lastDigitsCount);
}

const Person = function(props) {
  const { data } = props;
  // const [loading, setLoading] = useState(false);
  // const [activityLog, setActivityLog] = useState(null);

  // useEffect(()=>{
  //   async function loadActivityLog(){
  //     try{
  //       setLoading(true);
  //       const log = await firebase.getPersonActivityLog(data.person_url);
  //       setActivityLog(log);
  //     }
  //     catch(err){
  //       // TODO: Add notifier
  //       // alert(err.message);
  //     }
  //     finally{
  //       setLoading(false);
  //     }
  //   }

  //   loadActivityLog();
  // }, [data]);

  // TODO: Make authenticated template for guest
  if(data.user_type === 'guest'){
    return (
      <Container>
        <Box pt={3}>
          <Paper>
            <Box p={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar />
                </Grid>
                <Grid item>
                  <Typography variant="h6">This is a guest.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box pt={3}>
        <Paper>
          <Box p={2} bgcolor="grey.200">
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar src={data.picture_person} alt="Person photo" />
              </Grid>
              <Grid item>
                <Typography variant="h6">{data.person_first} {data.person_last}</Typography>
                <Typography variant="subtitle2" color="textSecondary">{ data.email_person }</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box p={2}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={3} md={4}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>COMPANY</Typography>
                <Typography>{data.Company_name || '--'}</Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>ROLE</Typography>
                <Typography>{data.role || '--'}</Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>ADDED BY</Typography>
                <Typography>{data.adding_person || '--'}</Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>PREFERRED PRINTER</Typography>
                <Typography>{padString(data.preferred_4x6_printer, 15, 6)}</Typography>
              </Grid>
            </Grid>
          </Box>

          {/* TODO: Uncomment when authentication is in place */}
          {/* <Box px={2}>
            <Box px={2} py={1} bgcolor="grey.100">
              <Typography variant="h6">Activity log</Typography>
            </Box>
          </Box> */}

          {/* <Box p={2}>
            {loading && <CircularProgress />}
            {!loading && !activityLog && <Typography>Not available.</Typography>}
            {!loading && !!activityLog && (
              <List>
                {activityLog.map((log, i)=>(
                  <ListItem key={''+i}>
                    <ListItemText
                      primary={<Link target="_BLANK" component={RouterLink} to={`/${firebase.cleanId(log.url)}`}>{log.url}</Link>}
                      secondary={Moment.unix(log.timestampUnix).format('ddd MMM Do, YYYY - hh:mma')}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box> */}
        </Paper>
      </Box>
    </Container>
  );
};

export default Person;
