import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";
import { useEffect, useState } from "react";

exportingModule(Highcharts);

const useChartSvg = (chartComponent: Highcharts.Chart) => {
  const [chartSvg, setChartSvg] = useState<string | null>(null);

  const getChartAsImage = () => {
    if (!chartComponent) return;
    const chart = chartComponent;
    const svg = chart.getSVG();
    setChartSvg(svg);
  };

  useEffect(() => {
    if (!chartComponent) return;
    getChartAsImage();
    // eslint-disable-next-line
  }, [chartComponent]);

  return [chartSvg];
};

export default useChartSvg;
