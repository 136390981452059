import React, { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllPersons } from "../core/system/persons";
import useNotify from "../core/hooks/use-notify";

const EmployeeSearchBox = ({
  value,
  onChange = () => null,
  required,
  disabled,
}) => {
  const [searching, setSearching] = useState(false);
  const [persons, setPersons] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const notify = useNotify();

  const fetchPersons = async () => {
    try {
      setSearching(true);
      const persons = await getAllPersons();
      setPersons(persons);
    } catch (err) {
      notify(err.message);
    }
    setSearching(false);
  };

  useEffect(() => {
    fetchPersons();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedEmployee) return;
    const personUrl = `3hd.us/${selectedEmployee.person_id}`;
    onChange({ ...selectedEmployee, person_url: personUrl });
  }, [selectedEmployee, onChange]);

  const employeesOptions = useMemo(() => {
    if (!persons) return null;
    const employeesOptions = [...persons].sort((a, b) =>
      a.email.localeCompare(b.email)
    );
    return employeesOptions;
  }, [persons]);

  const isEmployeeNamed = useMemo(() => {
    if (!selectedEmployee) return null;
    return Boolean(selectedEmployee.first_name && selectedEmployee.last_name);
  }, [selectedEmployee]);

  return (
    <div className="employee-search-box">
      {!!selectedEmployee && (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Card
              variant="outlined"
              style={{
                padding: "4px 8px",
              }}
            >
              <CardHeader
                avatar={
                  selectedEmployee.picture_person ? (
                    <Avatar src={selectedEmployee.picture_person} />
                  ) : null
                }
                title={
                  isEmployeeNamed
                    ? `${selectedEmployee.first_name} ${selectedEmployee.last_name}`
                    : selectedEmployee.email
                }
                subheader={isEmployeeNamed ? selectedEmployee.email : ""}
              />
            </Card>
          </Box>
          <Box flexShrink={0} flexBasis="auto" pl={1}>
            <IconButton
              color="primary"
              onClick={() => setSelectedEmployee(null)}
              disabled={disabled}
            >
              <FontAwesomeIcon icon="times" size="sm" />
            </IconButton>
          </Box>
        </Box>
      )}
      {employeesOptions && !selectedEmployee && (
        <div>
          <Autocomplete
            id="employee-search-input"
            options={employeesOptions}
            getOptionLabel={(option) =>
              `${option.first_name ? option.first_name : ""} ${
                option.last_name ? option.last_name : ""
              } ${option.email}`
            }
            value={selectedEmployee}
            onChange={(ev, val) => setSelectedEmployee(val)}
            inputValue={inputValue}
            onInputChange={(ev, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionSelected={(opt, val) => val.id === opt.id}
            disabled={searching || disabled}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Who are you visiting?"
                variant="outlined"
              />
            )}
            renderOption={(option) => (
              <CardHeader
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedEmployee(option)}
                key={option.id}
                avatar={
                  option.picture_person ? (
                    <Avatar src={option.picture_person} />
                  ) : null
                }
                title={
                  option.first_name && option.last_name
                    ? `${option.first_name} ${option.last_name}`
                    : ""
                }
                subheader={option.email}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeeSearchBox;
