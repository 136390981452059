
import React from 'react';
import clsx from 'clsx';
import Resumable from 'resumablejs';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageUploader = React.forwardRef((props, ref) => {
  const dropAreaRef = React.useRef();
  const fileInputRef = React.useRef();
  const selectedFileRef = React.useRef(null);
  const resolveUploadRef = React.useRef(null);
  const rejectUploadRef = React.useRef(null);

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [previewUrl, setPreviewUrl] = React.useState(props.currentUrl || '');
  const [previewStyles, setPreviewStyles] = React.useState({});

  const {
    className,
    disabled=false,
    required=false,
    uploadTargetUri,
    currentUrl='',
    placeholderImageUrl='',
    query,
    renameAs,
    inputProps={ capture: true }

  } = props;

  const resumable = React.useMemo(() => {
    return new Resumable({
      target: uploadTargetUri,
      maxFiles: 1,
      fileType: ['jpeg','jpg','png','tiff'],
      maxChunkRetries: 10,
      prioritizeFirstAndLastChunk: true,
      query,
      // totalChunksParameterName: 'total_chunks',
      // chunkNumberParameterName: 'chunk',
      // identifierParameterName: 'filename',
      // fileParameterName: 'file'
    });

  }, [uploadTargetUri, query]);

  React.useEffect(()=>{
    resumable.assignBrowse(fileInputRef.current);
    resumable.assignDrop(dropAreaRef.current);

    function onFileAdded(file) {
      selectedFileRef.current = file;

      // Prepare filename.
      const nameParts = file.fileName.split('.');
      const extension = nameParts.length > 1 ? '.' + nameParts[nameParts.length - 1] : '';
      const name = renameAs || `${Date.now()}`;
      file.fileName = name + extension;

      const dataUrl = URL.createObjectURL(file.file);
      const image = new Image();
      image.onload = () => {
        const { width, height } = image;

        const cssProperty = width > height ? 'height' : 'width';

        setPreviewStyles({ [cssProperty]: '100%', maxWidth: 'none' });
        setPreviewUrl(dataUrl);
      };

      image.src = dataUrl;
      setSelectedFile(file);
    }

    function onUploadStart() {
      resumable.unAssignDrop(dropAreaRef.current);
    }

    function onComplete() {
      const selectedFile = selectedFileRef.current;
      if(!selectedFile)
        return;

      const resolveUpload = resolveUploadRef.current;
      resolveUpload(selectedFile.serverResponse.url);
      resumable.assignDrop(dropAreaRef.current);
    }

    function onProgress() {
      setProgress(Math.round(resumable.progress() * 100));
    }

    function onFileSuccess(file, response) {
      file.serverResponse = JSON.parse(response);
    }
    
    function onCancel() {
      selectedFileRef.current = null;
      setPreviewUrl(currentUrl);
      setSelectedFile(null);
    }

    function onError(msg) {
      resumable.cancel();
      const rejectUpload = rejectUploadRef.current;
      rejectUpload(new Error(msg || 'Unable to upload image.'));
    }

    resumable.on('fileAdded', onFileAdded);
    resumable.on('uploadStart', onUploadStart);
    resumable.on('complete', onComplete);
    resumable.on('progress', onProgress);
    resumable.on('fileSuccess', onFileSuccess);
    resumable.on('cancel', onCancel);
    resumable.on('error', onError);

  }, [resumable, currentUrl, renameAs]);

  React.useImperativeHandle(ref, () => ({
    isValid: () => {
      const fileChosen = !!resumable.files.length;

      return !required || !!currentUrl || fileChosen;
    },

    startUpload: () => {
      const fileChosen = !!resumable.files.length;
      const isValid = !required || !!currentUrl || fileChosen;

      if(!isValid)
        return Promise.reject(new Error('Please choose an image to upload.'));
      else if(!fileChosen)
        return Promise.resolve(currentUrl)
        

      return new Promise((resolve, reject)=>{
        resolveUploadRef.current = resolve;
        rejectUploadRef.current = reject;
        resumable.upload();
      });
    }
  }));

  const uploading = resumable && resumable.isUploading();

  return (
    <div className={clsx('image-uploader', className)}>
      <div className="preview-window">
        <div ref={dropAreaRef} className="drop-area" >
          <div className="preview-image-holder">
            <img src={previewUrl || placeholderImageUrl} style={previewStyles} alt="Preview"/>
          </div>
        </div>
      </div>
      <div className="info-area">
        {!!(uploading || progress===100) && <Typography variant="body2" className="label">Uploading {progress}%...</Typography>}
        <Button
          variant="outlined"
          size="large"
          startIcon={<FontAwesomeIcon icon="image" className="icon"/>}
          onClick={()=>fileInputRef.current.click()}
          disabled={uploading || disabled}
        >Take {(!!selectedFile || !!currentUrl) && 'a different '}photo</Button>
        <input ref={fileInputRef} type="file" hidden {...inputProps} />

        {/* {!!selectedFile && <div className="file-info">
          <Typography variant="body2">{selectedFile.fileName}</Typography>
          <Typography variant="caption">{Math.round(selectedFile.size/1024/1024).toLocaleString()} MB</Typography>
        </div>} */}
      </div>
      <LinearProgress variant="determinate" value={progress} color="secondary" className="progress-bar"/>
    </div>
  );
});

export default ImageUploader;
