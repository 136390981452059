
import axios from 'axios';
import axiosRetry from 'axios-retry';
import HttpError from './errors/HttpError';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

apiClient.interceptors.response.use(function(res){
  return res;
}, function(err){
  let errMessage;
  if(err.response)
    errMessage = err.response.data.message || 'Oops, something went wrong.';
  else
    errMessage = 'Unable to connect with the server. Check your internet connection or contact the system administrator.';

  return Promise.reject(new HttpError(errMessage));
});

axiosRetry(apiClient, { retries: 5 });
export default apiClient;
