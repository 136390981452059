
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%'
  },
  activeNavListItem: {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `linear-gradient(to right, ${theme.palette.primary.main}, #ffffff)`,
      opacity: 0.1
    },
    '&:hover': { backgroundColor: 'transparent' }
  },
  logo: {
    height: 40
  }
});

export default styles;
