
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 100,
    padding: theme.spacing(1)/2,
    background: `linear-gradient(to left, ${theme.palette.grey['300']}, ${theme.palette.grey['50']})`,
    display: 'flex',
    alignItems: 'center'
  },
  inputBase: {
    flex: 1,
    padding: `0 ${theme.spacing(1)}px`,
    ...(theme.typography.h6),
    fontStyle: 'italic',
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: '0.08em',
    color: theme.palette.grey['600']
  }
});

export default styles;
