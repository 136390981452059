
import React from 'react';
import { PersonBadgeProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import styles from './styles';

const useStyles = makeStyles(styles);

const PersonBadge: React.FC<PersonBadgeProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>
        {`${props.first_name?.charAt(0)}${props.last_name?.charAt(0)}` || <Icon>person</Icon>}
      </Avatar>

      {!props.hiddenText && (
        <div>
          {props.first_name && <Typography variant="body2">{props.first_name || ''} {props.last_name || ''}</Typography>}
          {props.email && <Typography variant="caption" color="textSecondary">{props.email}</Typography>}
        </div>
      )}
    </div>
  );
};

export default PersonBadge;
