import React, {FormEvent, useRef} from "react";
import {QrCodeSearchInputProps} from "./types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import parseMomenttrackId from "../../system/utils/parse-momenttrack-id";
import styles from "./styles";
import MultiCodeScanner from "../MultiCodeScanner";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

const QrCodeSearchInput: React.FC<QrCodeSearchInputProps> = props => {
  const {
    className,
    onSearch = () => {},
    inputRef,
    disabled,

    ...inputBaseProps
  } = props;

  const history = useHistory();

  const _inputRef = useRef<HTMLInputElement>(null);
  const qrScanner = useRef<any>(null);

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    const parsed = parseMomenttrackId(_inputRef.current?.value || "");
    if (parsed.type === "datamatrix") {
      history.push(`/datamatrix`, {
        datamatrix_string: parsed.id.substring(0),
      });
    } else {
      onSearch(parsed.id);
    }
  };

  const handleScanResult = (data: string) => {
    const parsed = parseMomenttrackId(data);

    if (!!_inputRef.current) _inputRef.current.value = parsed.id.substring(0);

    if (parsed.type === "datamatrix") {
      history.push(`/datamatrix`, {
        datamatrix_string: data.substring(0),
      });
    } else {
      onSearch(parsed.id);
    }
  };

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={0} className={clsx(classes.root, className)}>
        <IconButton
          onClick={() => qrScanner.current?.openScanner()}
          disabled={disabled}
        >
          <Icon>qr_code</Icon>
        </IconButton>
        <InputBase
          inputRef={_inputRef}
          className={classes.inputBase}
          disabled={disabled}
          {...inputBaseProps}
        />
        <Fab type="submit" color="primary" size="medium" disabled={disabled}>
          <Icon>search</Icon>
        </Fab>
      </Paper>
      <MultiCodeScanner ref={qrScanner} onResult={handleScanResult} hidden />
    </form>
  );
};

export default QrCodeSearchInput;
