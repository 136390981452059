
import React from 'react';
import useStations from './use-stations';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import StationIcon from '@material-ui/icons/PinDrop';

const ReportsPage = () => {
  const [stations, loading, message] = useStations();

  return (
    <Container>
      {loading && (
        <LinearProgress />
      )}
      <Box py={4}>
        <Typography variant="h4" gutterBottom>Stations</Typography>
        {!!message && (
          <Box py={2}>
            <Typography>{message}</Typography>
          </Box>
        )}
        <div><br/></div>
        <Grid container spacing={2}>
          {stations.map(station=>(
            <Grid key={station.id} item xs={12} sm={4} lg={3}>
              <Link underline="none" component={RouterLink} to={`/wms/stations/${station.id}/report`}>
                <Paper>
                  <Box px={2} py={1} >
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <Avatar>
                          <StationIcon />
                        </Avatar>
                      </Box>
                      <div>
                        <Typography variant="h6">{station.name}</Typography>
                        <Typography variant="caption" color="textSecondary">3hd.us/...{station.id.slice(-5)}</Typography>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ReportsPage;
