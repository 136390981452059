import React, { useState, useMemo } from "react";
import { TrainingEditorProps } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import StepsInput from "../StepsInput";
import {
	FileUploaderRef,
	VideoUploader,
} from "../../core/components/FileUploader";
// import { useAuthentication } from '../../core/providers/AuthenticationProvider';
import { useHistory } from "react-router-dom";
import useNotify from "../../core/hooks/use-notify";
import randomize from "randomatic";
import {
	saveTraining,
	VisibilityType,
	TrainingDocument,
} from "../../core/system/litmon";
import useTrainingInputForm from "./use-training-input-form";
import { parseStringToArray } from "../../core/system/utils";

function getAlternateVideoUrl(url?: string) {
	if (typeof url !== "string" || !url.trim() || !url.includes("/uploads/"))
		return url;

	return url.replace("/uploads/", "/uploads_compressed/");
}

const renameFunc = (currName: string) => {
	const nameParts = currName.split(/[.|\s]+/g);
	const extension = nameParts.length <= 1 ? "" : nameParts.pop();

	// return randomize('a0', 6) + (!extension ? '' : `.${extension}`);

	nameParts.push(randomize("a0", 3));
	return nameParts.join("_") + (!extension ? "" : `.${extension}`);
};

const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;
const TRAINING_FILE_TYPES = [
	"mp4",
	"mpg",
	"mpeg",
	"wmv",
	"mov",
	"avi",
	"flv",
	"mkv",
	"webm",
];

const TrainingEditor: React.FC<TrainingEditorProps> = props => {
	const { docid, trainingDocument, onCancel } = props;

	const videoUploaderRef = React.useRef<FileUploaderRef>(null);
	const history = useHistory();

	const notify = useNotify();

	const [visibility, setVisibility] = useState<VisibilityType>(
		trainingDocument?.visibility || "PUBLIC"
	);
	const [agreedToTerms, setAgreedToTerms] = useState(false);
	const [creatingLitmon, setCreatingLitmon] = useState(false);
	const [uploadingVideo, setUploadingVideo] = useState(false);
	/*const [currPerson] = useAuthentication();*/

	const currTraining = useMemo(() => {
		if (
			!trainingDocument ||
			trainingDocument.organization_id !== trainingDocument?.organization_id
		)
			return;

		return trainingDocument;
	}, [trainingDocument]);

	const trainingForm = useTrainingInputForm({
		initialValues: currTraining,
		onSubmit: async values => {
			try {
				setCreatingLitmon(true);
				let res = await saveTraining({
					...(values as TrainingDocument),
					doc_id: docid,
					visibility,
					pick_type: "TRAINING",
				});

				history.replace(res.doc_id);
			} catch (err) {
				notify(err as Error);
				setCreatingLitmon(false);
			}
		},
	});

	const handleFormSave = async (ev: any) => {
		try {
			ev.preventDefault();

			if (!videoUploaderRef.current?.validate())
				throw new Error("Please select a training video.");

			setUploadingVideo(true);
			const res = await videoUploaderRef.current.startUpload()!;

			trainingForm.setFieldValue("video_url", res?.file_url);
			trainingForm.setFieldValue("thumbnail_url", res?.thumbnail_url);
			trainingForm.submitForm();
			setUploadingVideo(false);
		} catch (err) {
			notify(err as Error);
			setUploadingVideo(false);
		}
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={4} lg={3}>
				<Box mb={4}>
					<Typography variant="h6">Training title</Typography>
					<Typography variant="subtitle2" color="textSecondary">
						Give a title to this training.
					</Typography>

					<Box py={2}>
						<TextField
							placeholder="Learn how to?"
							{...trainingForm.getFieldProps("title")}
							disabled={creatingLitmon || uploadingVideo}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Icon>school</Icon>
									</InputAdornment>
								),
							}}
							error={!!trainingForm.errors["title"]}
							helperText={
								!!trainingForm.errors["title"] &&
								trainingForm.touched["title"] &&
								trainingForm.errors["title"]
							}
							multiline
							fullWidth
						/>
					</Box>
				</Box>

				<Box mb={4}>
					<Typography variant="h6">QR code label</Typography>
					<Typography variant="subtitle2" color="textSecondary">
						This training will be linked to following QR code.
					</Typography>

					<Box py={2}>
						<img
							alt="QR code"
							src={`https://api.3hd.us/app/qrcode_gen.create?data=${
								process.env.REACT_APP_QR_BASENAME || "3hd.us"
							}/${docid}`}
							style={{ maxWidth: "100%" }}
						/>
						<Typography gutterBottom display="block">
							{process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...
							{docid.slice(-5)}
						</Typography>
					</Box>
				</Box>

				<Box>
					<Typography variant="h6">Visibility</Typography>
					<Typography variant="subtitle2" color="textSecondary">
						Choose who can view this training by scanning the QR code.
					</Typography>

					<Box py={2}>
						<RadioGroup
							value={visibility}
							onChange={ev => setVisibility(ev.target.value as VisibilityType)}
						>
							<FormControlLabel
								label={
									<Typography>
										Public -{" "}
										<Typography variant="caption" color="textSecondary">
											Any one can view this training.
										</Typography>
									</Typography>
								}
								value="PUBLIC"
								disabled={uploadingVideo || creatingLitmon}
								control={<Radio />}
							/>
							<FormControlLabel
								label={
									<Typography>
										Internal -{" "}
										<Typography variant="caption" color="textSecondary">
											(This setting is under development) Only people within your company
											can view this training.
										</Typography>
									</Typography>
								}
								value="INTERNAL"
								disabled /** TODO: Temporarily disabled unless application server supports this */
								control={<Radio />}
							/>
						</RadioGroup>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} md>
				<form onSubmit={handleFormSave}>
					<Grid container direction="column" spacing={2} wrap="nowrap">
						<Grid item>
							<Typography variant="h6" gutterBottom>
								Choose training video
							</Typography>
							{/* BEGIN: Video uploader */}
							<VideoUploader
								ref={videoUploaderRef}
								targetUrl={FILE_UPLOAD_URL as string}
								initialValue={getAlternateVideoUrl(
									currTraining?.video_url ||
										trainingForm?.getFieldProps("video_url")?.value
								)}
								rename={renameFunc}
								fileTypes={TRAINING_FILE_TYPES}
								maxFileSize={1048576 * 100} // 100MB
								required
								disabled={creatingLitmon}
								// query={attachmentsConfig}
							/>
							{/* END: Video uploader */}
						</Grid>
						<Grid item>
							<Typography variant="h6" gutterBottom>
								Steps to remember
							</Typography>
							<Paper>
								<Box p={2}>
									<StepsInput
										value={parseStringToArray(
											trainingForm?.values["instructions"] || "[]"
										)}
										onChange={steps => trainingForm.setFieldValue("instructions", steps)}
										disabled={creatingLitmon || uploadingVideo}
									/>
								</Box>
							</Paper>
						</Grid>
						<Grid item>
							<FormControlLabel
								disabled={creatingLitmon || uploadingVideo}
								control={
									<Checkbox
										value={agreedToTerms}
										onClick={() => setAgreedToTerms(!agreedToTerms)}
									/>
								}
								label="I agree to terms and conditions"
							/>
						</Grid>
						<Grid item>
							{onCancel && (
								<>
									<Button
										onClick={onCancel}
										variant="outlined"
										color="secondary"
										size="large"
									>
										Cancel
									</Button>
									&nbsp;&nbsp;&nbsp;
								</>
							)}
							<Button
								type="submit"
								size="large"
								variant="contained"
								color="primary"
								startIcon={<Icon>save</Icon>}
								disabled={!agreedToTerms || creatingLitmon || uploadingVideo}
							>
								Save training
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
};

export default TrainingEditor;
