import React, {useState, useMemo} from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import useNotify from "../../../core/hooks/use-notify";
import DynamicWebForm from "../../../core/components/DynamicWebForm";
import QrCodeInput from "../../../core/components/QrCodeInput";
import {momentTrackService} from "../../../core/system/legacy-services/moment-track";
import {moveAll} from "../../../core/system/license-plates";
import {lookupPlace} from "../../../core/system/places";
import {IMoveAllScannedData} from "./types";

const requirements = [
  {
    type: "location",
    field: "locationFrom",
    label: "Scan location from",
  },
  {
    type: "location",
    field: "locationTo",
    label: "Scan location to",
  },
];

const MoveAllForm: React.FC = () => {
  const initialScannedData = useMemo(() => {
    const initialScannedData = {} as IMoveAllScannedData;
    requirements.forEach(r => (initialScannedData[r.field] = null));
    return initialScannedData;
  }, []);

  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const [scannedData, setScannedData] =
    useState<IMoveAllScannedData>(initialScannedData);
  const notify = useNotify();

  function validateScannedData() {
    for (let i = 0; i < requirements.length; i++) {
      const requirement = requirements[i];
      if (!scannedData[requirement.field])
        throw new Error(`${requirement.label}, please.`);
    }
  }

  function scanDataRemoveHandler(dataKey: string) {
    if (!scannedData) return;
    return () => {
      setScannedData(data => ({
        ...data,
        [dataKey]: null,
      }));
    };
  }

  function scanResultHandler(dataKey: string) {
    const resultType = "location";
    return (scanResult: any) => {
      try {
        const idInfo = momentTrackService.parseId(scanResult);
        if (idInfo.type !== resultType)
          throw new Error(`Invalid ${resultType} Qr.`);
        if (scannedData && scannedData[dataKey]) return;
        setScannedData(oldData => ({
          ...oldData,
          [dataKey]: {id: idInfo.id},
        }));
      } catch (err) {
        notify(err as Error);
      }
    };
  }

  async function getlocationId(id: string) {
    const location = await lookupPlace(id);
    return location.id;
  }

  async function handleTransactActivity() {
    try {
      validateScannedData();
      setLoading(true);

      const locationFromId = await getlocationId(scannedData.locationFrom!.id);
      const locationToId = await getlocationId(scannedData.locationTo!.id);
      const transaction = await moveAll(locationFromId, locationToId);

      notify("Success.");
      setTransaction(transaction);
    } catch (err) {
      notify(err as Error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Container>
      <Box py={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">Move All</Typography>
            <Typography variant="caption" color="textSecondary">
              This is when you want to move all from one location to another
            </Typography>
          </Box>

          <Box p={2}>
            {!transaction && (
              <Grid container spacing={4}>
                {requirements.map((requirement, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`${requirement.field}-${i}`}
                  >
                    <Typography variant="h5" gutterBottom>
                      {requirement.label}
                    </Typography>
                    {!scannedData[requirement.field] && (
                      <QrCodeInput
                        placeholder="Type/Scan QR code..."
                        onChange={scanResultHandler(requirement.field)}
                        disabled={loading}
                      />
                    )}
                    <div>
                      <br />
                    </div>
                    {!!scannedData[requirement.field] && (
                      <Chip
                        label={`3hd.us/...${scannedData[
                          requirement.field
                        ]?.id.slice(-5)}`}
                        color="secondary"
                        variant="outlined"
                        onDelete={scanDataRemoveHandler(requirement.field)}
                      />
                    )}
                  </Grid>
                ))}

                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h5" gutterBottom>
                    Submit
                  </Typography>
                  <DynamicWebForm
                    formFields={[]}
                    onSubmit={handleTransactActivity}
                    disabled={loading}
                  >
                    <div>
                      {loading && (
                        <CircularProgress color="secondary" size={32} />
                      )}
                      {!loading && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                          disabled={loading}
                        >
                          TRANSACT ACTIVITY
                        </Button>
                      )}
                    </div>
                  </DynamicWebForm>
                </Grid>
              </Grid>
            )}
            {!!transaction && (
              <Typography style={{textTransform: "capitalize"}} align="center">
                {transaction?.message}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default MoveAllForm;
