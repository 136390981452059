
import { createStyles } from '@material-ui/core/styles';

const styles = theme => createStyles({
  tebleCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

export default styles;
