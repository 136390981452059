
import React from 'react';
import { AsideProps, AsideApi } from './types';
import Fade from '@material-ui/core/Fade';
import Portal from '@material-ui/core/Portal';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { useState, useLayoutEffect, useCallback, useContext, useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import layoutContext from '../context';
import clsx from 'clsx';
import styles from './styles';

const useStyles = makeStyles(styles);

const defaultTrigger = (isCollapsed: boolean) => {
  if(isCollapsed)
    return <IconButton><MenuIcon /></IconButton>;
  
  return <IconButton><MenuOpenIcon /></IconButton>;
};

const Aside: React.ForwardRefRenderFunction<AsideApi, AsideProps> = (props, ref) => {
  const {
    width = 'auto',
    collapsedWidth = 0,
    collapsed = false,
    onTrigger,
    breakpoint = 'sm',
    onBreakpoint,
    trigger = defaultTrigger,
    triggerContainer,
    anchor = 'left',
    variant = 'standard',
    // disableClickAwayListener = false,
    noBackdrop = false,
    className

  } = props;

  const contentWrapper = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isBelow = useMediaQuery(theme.breakpoints.down(breakpoint), { noSsr: true });
  const [isCollapsed, setIsCollapsed] = useState(collapsed || variant === 'drawer' || isBelow);
  const [rootWidth, setRootWidth] = useState<any>(width);
  const [triggerContainerEl, setTriggerContainerEl] = useState<Element | null>(null);
  const { api } = useContext(layoutContext);

  const isDrawer = variant === 'drawer' || isBelow;
  const showTrigger = trigger !== null && (isCollapsed || isBelow || variant !== 'standard');

  const handleTrigger = useCallback((collapsed: boolean)=>{
    setIsCollapsed(collapsed);
    onTrigger && onTrigger(collapsed);

  }, [onTrigger]);

  useImperativeHandle(ref, ()=>({
    expand: handleTrigger.bind(null, false),
    collapse: handleTrigger.bind(null, true)

  }), [handleTrigger]);

  useLayoutEffect(()=>{
    handleTrigger(collapsed || isDrawer);

  }, [collapsed, isDrawer, handleTrigger]);

  useLayoutEffect(()=>{
    setRootWidth(contentWrapper.current?.offsetWidth || width);

  }, [width]);

  useLayoutEffect(()=>{
    setImmediate(()=>{
      setTriggerContainerEl(typeof triggerContainer === 'function' ? triggerContainer() : triggerContainer || null);
    });
    
  }, [triggerContainer]);

  useLayoutEffect(() => api.registerAside(), [api]);

  useEffect(()=>{
    onBreakpoint && onBreakpoint(isBelow);
  }, [isBelow, onBreakpoint]);

  useEffect(()=>{
    const onCickAway = () => handleTrigger(true);
    if (isDrawer && !isCollapsed)
      window.document.addEventListener('click', onCickAway);

    return () => window.document.removeEventListener('click', onCickAway);
  }, [handleTrigger, isCollapsed, isDrawer]);

  const classes = useStyles({
    isBelow,
    width,
    rootWidth,
    collapsedWidth,
    anchor,
    floatingTrigger: !triggerContainerEl
  });

  const asideEle = (
    <aside
      className={clsx(classes.root, {
        [classes.drawer]: isDrawer,
        [classes.collapsed]: isCollapsed
      })}
      onClick={ev=>{
        ev.nativeEvent.stopImmediatePropagation()
      }}
    >
      <div
        ref={contentWrapper}
        className={className}

      >{props.children}</div>

      {showTrigger && (
        <Portal container={triggerContainerEl} disablePortal={!triggerContainerEl}>
          <div className={classes.triggerWrapper} onClick={()=>handleTrigger(!isCollapsed)}>
            {typeof trigger === 'function' ? trigger(isCollapsed) : trigger}
          </div>
        </Portal>
      )}
    </aside>
  );

  return (
    <>
      {isDrawer && !noBackdrop && (
        <Fade in={!isCollapsed}>
          <div className={classes.backdrop} />
        </Fade>
      )}
      {asideEle}
    </>
  );
};

const C = forwardRef(Aside);
C.displayName = 'Aside';
export default C;
