import axios from "axios";

const publicApiClient = axios.create({
  baseURL: process.env.REACT_APP_ORDERTRACK_API_HOST + "/publicapi",
});

publicApiClient.interceptors.response.use(undefined, err => {
  return Promise.reject(new Error(err.response.data.message));
});

export const lifetimeClient = axios.create({
  baseURL: process.env.REACT_APP_LIFETIME_API_HOST,
});

lifetimeClient.interceptors.response.use(undefined, err => {
  return Promise.reject(new Error(err.response.data.message));
});
publicApiClient.interceptors.response.use(undefined, err => {
  return Promise.reject(new Error(err.response.data.message));
});

export default publicApiClient;
