import apiClient, {publicApiClient} from "../ordertrack-client";
import {
  ProductionOrder,
  ProductionOrderLog,
  ProductionOrderStatusReport,
  ProductionOrderLookupResult,
  PublicViewOrder,
  LicensePlate,
} from "./types";
import {ExportToCsv} from "export-to-csv";
import {IPageData} from "../../../IdLookup/DocumentTemplate/ProductionOrderDocumentTemplate";
import getPlaceTemplate from "./production-orders-templates/production-order-template";
import htmlToPdf, {getPdfOptions} from "../utils/html-to-pdf";

export const lookupProductionOrder = async (
  documentId: string
): Promise<ProductionOrderLookupResult> => {
  const res = await publicApiClient.get("/production_orders/lookup", {
    params: {docid: documentId},
  });

  return {
    pick_type: "PRODUCTIONORDER",
    ...res.data.data,
  };
};
export const getPublicProductionOrder = async (
  orderId: number
): Promise<PublicViewOrder> => {
  const res = await publicApiClient.get(`/production_orders/${orderId}`);
  return res.data;
};

export const getProductionOrder = async (
  orderId: number
): Promise<ProductionOrder> => {
  const res = await apiClient.get(`/production_orders/${orderId}`);
  return res.data.data;
};

export const getProductionOrderStatusReport = async (
  orderId: number,
  params?: {page: number; per_page?: number}
): Promise<ProductionOrderStatusReport> => {
  const res = await apiClient.get(
    `/production_orders/${orderId}/status_report`,
    {
      params: {...params},
    }
  );
  return res.data.data;
};

export const sendProductionOrderLogs = async (body: {
  order_id: number;
  email: string;
}): Promise<{data: {task_id: string}; message: string}> => {
  const res = await apiClient.post(`/reports/orders`, body);
  return res.data;
};

export const downloadProductionOrderLogs = (
  logs: ProductionOrderLog[],
  product: {part_number: string; description: string}
) => {
  const csvExporter = new ExportToCsv({
    filename: "production-order-logs",
    showLabels: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(
    logs.map(item => ({
      product_description: product.description,
      part_number: product.part_number,
      external_serial_number: item.external_serial_number || "",
      product_qr: !!item.lp_id
        ? `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${item.lp_id}`
        : "",
      place: item.location?.name || "",
      place_qr: !!item.location?.beacon_id
        ? `${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
            item.location.beacon_id
          }`
        : "",
    }))
  );
};

export const generateSheets = (pageData: IPageData): Promise<string> => {
  const labelsHtml = getPlaceTemplate(pageData, 50);

  const pdfOptions = getPdfOptions(
    `production-order-report-sheet-${pageData.productionOrder.docid}`,
    "A4"
  );

  return htmlToPdf(labelsHtml, pdfOptions);
};

export async function createOrder(
  product_id: number,
  data: {requested_qty: number; external_docid?: string}
): Promise<ProductionOrder> {
  const res = await apiClient.post("/production_orders", {
    product_id,
    ...data,
  });

  return res.data.data;
}

export async function createLicensePlate(data: {
  quantity: number;
  production_order_id: number;
  product_id?: number;
}): Promise<LicensePlate> {
  const res = await apiClient.post("/license_plates/made", {
    ...data,
  });

  return res.data.data;
}
