
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { momentTrackService } from '../moment-track';
import { subscribe } from '../../pubsub';

const FIREBASE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL;

let authData = {
  access_token: '',
  refresh_token: '',
  org_slug: 'default'
};

function updateAuth(data) {
  Object.assign(authData, data || {
    org_slug: 'default'
  });
}

subscribe('update:authentication', ({ data })=>updateAuth(data));

export default class FirebaseService {
  getFirebaseCollectionName(idType) {
    switch(idType){
      case 'person': return 'access_rights';
      default: return idType;
    }
  }

  /**
   * Queries firebase.
   * @param {string} path 
   */
  async queryFirebase(path) {
    const response = await axios.get(`${FIREBASE_BASE_URL}${path}`);
    return response.data;
  }

  /**
   * Smartly fetches data for given id from firebase
   * @param {string} id 
   */
  async getData(id) {
    const idInfo = momentTrackService.parseId(id);
    const data = await this.queryFirebase(`/${this.getFirebaseCollectionName(idInfo.type)}/${idInfo.id}.json`);
    if(!data)
      throw new Error(`${idInfo.type} not found.`);

    return data;
  }

  /**
   * Fetches person data from firebase.
   * @param {string} personId 
   */
  async getPerson(personId) {
    const idInfo = momentTrackService.parseId(personId);
    if(idInfo.type !== 'person')
      throw new Error('Invalid person id.');

    const data = await this.queryFirebase(`/access_rights/${idInfo.id}.json`);
    if(!data)
      throw new Error('Person not found.');

    return data;
  }

  /**
   * Fetches activity data from firebase.
   * @param {string} activityId 
   */
  async getActivity(activityId) {
    const idInfo = momentTrackService.parseId(activityId);
    if(idInfo.type !== 'activity')
      throw new Error('Invalid activity id.');

    const data = await this.queryFirebase(`/activity/${idInfo.id}.json`);
    if(!data)
      throw new Error('Activity not found.');

    return data;
  }

  /**
   * Fetches licence plate data from firebase.
   * @param {string} licencePlateId 
   */
  async getLicencePlate(licencePlateId) {
    const idInfo = momentTrackService.parseId(licencePlateId);
    if(idInfo.type !== 'licence_plate')
      throw new Error('Invalid licence plate id.');

    const data = await this.queryFirebase(`/licence_plate/${idInfo.id}.json`);
    if(!data)
      throw new Error('Licence plate not found.');

    return data;
  }

  /**
   * Fetches document data from firebase.
   * @param {string} documentId 
   */
  async getDocument(documentId) {
    const idInfo = momentTrackService.parseId(documentId);
    if(idInfo.type !== 'document')
      throw new Error('Invalid document id.');

    const data = await this.queryFirebase(`/document/${idInfo.id}.json`);
    if(!data)
      throw new Error('Document not found.');

    return data;
  }

  /**
   * Fetches location data from firebase.
   * @param {string} locationId 
   */
  async getLocation(locationId) {
    const idInfo = momentTrackService.parseId(locationId);
    if(idInfo.type !== 'location')
      throw new Error('Invalid location id.');

    const data = await this.queryFirebase(`/location/${idInfo.id}.json`);
    if(!data)
      throw new Error('Location not found.');

    return data;
  }

  async createTransaction(data) {
    const transactionID = uuidV4();

    const body = {
      ...data,
      transactionID
    };

    const response = await axios.patch(`${FIREBASE_BASE_URL}/transaction/${transactionID}.json`, body);
    return response.data;
  }

  /**
   * Posts transaction to firebase and executes webhook from activity.
   * @param {string} activityId 
   * @param {object} transactionData 
   */
  async transactActivity(activityId, transactionData) {
    const activityIdInfo = momentTrackService.parseId(activityId);

    if(activityIdInfo.type !== 'activity')
      throw new Error('Invalid activity id.');

    const activity = await this.getActivity(activityId);
    const transaction = await this.createTransaction(transactionData);
    const data = {
      transactionID: transaction.transactionID,
      access_token: authData.access_token,
      org_slug: authData.org_slug
    };
    
    await axios.post(activity.webhook_machine, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return transaction;
  }
}
