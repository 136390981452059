import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import {useState, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import htmlToPdf, {PdfOptions} from "@momenttrack/core/utils/html-to-pdf";

import horizontalTemplate from "./templates/horizontal-label";
import verticalTemplate from "./templates/vertical-label";

const TEMPLATE_OPTIONS: {[key: string]: PdfOptions} = {
  t1: {
    pdf_mode: "portrait",
    page_width: "25.4mm",
    page_height: "50.8mm",
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  },
  t2: {
    pdf_mode: "portrait",
    page_width: "30.48mm",
    page_height: "21.59mm",
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  },
};

const VALID_KEYS = new Set([
  "1234567890123456789012345678901234567890",
  "355911614509036897409564472887018992769",
]);

const GovPropLabelGenerator: React.FC = () => {
  const {search} = useLocation();
  const searchParams = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(search).entries());
  }, [search]);

  const [generating, setGenerating] = useState(false);
  const [pdfUri, setPdfUri] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!searchParams.key || !VALID_KEYS.has(searchParams.key))
      return setError("Invalid key");

    (async () => {
      try {
        setError("");
        setGenerating(true);
        const template = `t${searchParams.t || "1"}`;
        const templateOptions = {
          qr: searchParams.qr,
          asset: searchParams.asset,
        };
        const pdfOptions = TEMPLATE_OPTIONS[template] || TEMPLATE_OPTIONS["t1"];
        const html =
          template === "t1"
            ? verticalTemplate(templateOptions)
            : horizontalTemplate(templateOptions);

        const pdfUri = await htmlToPdf(html, {
          ...pdfOptions,
          pdf_name: `gov-prop-label-${searchParams.asset}`,
        });
        setPdfUri(pdfUri);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setGenerating(false);
      }
    })();
  }, [searchParams]);

  return (
    <Box height="100%" lineHeight={0}>
      {(generating || error) && (
        <Box px={2} py={6} textAlign="center">
          {generating && <CircularProgress size={24} />}
          {error && !generating && (
            <Typography color="error">{error}</Typography>
          )}
        </Box>
      )}

      {pdfUri && (
        <iframe
          title="Gov Prop Label"
          src={`${pdfUri}?t=${Date.now()}`}
          width="100%"
          height="100%"
          style={{border: "none"}}
        />
      )}
    </Box>
  );
};

export default GovPropLabelGenerator;
