
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

const Content: React.FC<BoxProps> = props => (
  <Box
    component="main"
    flex="auto"
    overflow="auto"
    {...props}
  />
);

export default Content;
