
import { ACTION_TYPES } from '../actions/admin-console-actions';

const INITIAL_STATE = {
  loading: {
    security_alert_email: false,
    ndas: false,
    email_templates: false,
    current_guests: false
  },
  security_alert_email: null,
  ndas: null,
  email_templates: null,
  current_guests: null
};

export default function guestTrackReducer(state=INITIAL_STATE, action={}) {
  switch(action.type){
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload
        }
      };

    case ACTION_TYPES.SET_SECURITY_EMAIL:
      return {
        ...state,
        security_alert_email: action.payload
      };

    case ACTION_TYPES.SET_NDA_TEMPLATES:
      return {
        ...state,
        ndas: {
          ...(state.ndas || {}),
          ...action.payload
        }
      };

    case ACTION_TYPES.SET_EMAIL_TEMPLATES:
      return {
        ...state,
        email_templates: {
          ...(state.email_templates || {}),
          ...action.payload
        }
      };

    case ACTION_TYPES.SET_CURRENT_GUESTS:
      return {
        ...state,
        current_guests: action.payload || []
      };

    case ACTION_TYPES.REMOVE_GUEST:
      return {
        ...state,
        current_guests: state.current_guests.filter(guest=>guest.access_rights !== action.payload)
      };

    case ACTION_TYPES.RESET_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
}
