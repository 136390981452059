
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useContext } from 'react';
import layoutContext from './context';

const Header: React.FC<BoxProps> = props => {
  const { options } = useContext(layoutContext);

  return options.noHeader ? <></> : (
    <Box
      component="header"
      flexBasis={0}
      {...props}
    />
  );
};

export default Header;
