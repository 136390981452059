import React from "react";
import {momentTrackService} from "../core/system/legacy-services/moment-track";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Document from "./templates/Documents/Document";
import Location from "./templates/Location";
import Person from "./templates/Person";
import LicencePlate from "./templates/LicencePlate";
import Activity from "./templates/Activity";
import DataMatrixTemplate from "../IdLookup/DataMatrixTemplate";
// import Transaction from './templates/Transaction';

const SearchTemplate = props => {
  const {id, data} = props;
  const idInfo = momentTrackService.parseId(id);

  if (
    data &&
    data.redirect &&
    (typeof data.redirect_url === "string" ||
      typeof data.redirectUrl === "string")
  ) {
    const redirectUrl = data.redirect_url || data.redirectUrl;
    const url = redirectUrl.startsWith("http")
      ? redirectUrl
      : "https://" + redirectUrl;
    window.location.replace(url);
    return (
      <Box pt={10}>
        <Fade in>
          <Typography variant="body1" align="center">
            <i>Redirecting...</i>
          </Typography>
        </Fade>
      </Box>
    );
  }

  switch (idInfo.type) {
    case "document":
      return <Document idInfo={idInfo} data={data} />;
    case "location":
      return <Location idInfo={idInfo} data={data} />;
    case "person":
      return <Person idInfo={idInfo} data={data} />;
    case "datamatrix":
      return <DataMatrixTemplate qrCode={id} />;
    case "licence_plate":
      return <LicencePlate idInfo={idInfo} data={data} />;
    case "activity":
      return <Activity idInfo={idInfo} data={data} />;
    // case 'transaction': return <Transaction idInfo={idInfo} data={data} />;
    default:
      return (
        <Box pt={10} textAlign="center">
          <Typography variant="body1">
            No template has been set up for this ID yet. Please try again later.
          </Typography>
        </Box>
      );
  }
};

export default SearchTemplate;
