import client from "../ordertrack-client/client";

export interface PdfOptions {
  html?: string;
  pdf_mode?: "portrait" | "landscape";
  pdf_name?: string;
  page_size?: "Letter" | "A4";
  page_height?: string;
  page_width?: string;
  margin_top?: string;
  margin_bottom?: string;
  margin_left?: string;
  margin_right?: string;
  disable_smart_shrinking?: null | boolean;
}

const defaultOptions: PdfOptions = {
  disable_smart_shrinking: null,
};

const BASE_URL = process.env.REACT_APP_HTML_2_PDF_URL || "";

export const getPdfOptions = (
  name: string,
  size: "Letter" | "A4"
): PdfOptions => {
  return {
    pdf_mode: "portrait",
    pdf_name: name,
    page_size: size,
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  };
};

async function htmlToPdf(html: string, options?: PdfOptions): Promise<string> {
  const _options = Object.assign(
    {pdf_name: Date.now().toString()},
    defaultOptions,
    options || {}
  );

  const res = await client.post(BASE_URL, {
    ..._options,
    html,
  });
  return res.data?.s3_path;
}

export default htmlToPdf;
