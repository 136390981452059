import React, {useState, useImperativeHandle, forwardRef} from "react";
import {NotificationsCenterComponent, Notification} from "./types";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";

const generateId = (() => {
  let id = 0;
  return () => ++id;
})();

const NotificationsCenter: NotificationsCenterComponent = (props, ref) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function closeNotification(notification: Notification) {
    setNotifications(notifications => {
      const cleanList = notifications.filter(n => n.open);
      const index = cleanList.indexOf(notification);

      if (index > -1)
        cleanList.splice(index, 1, {...notification, open: false});

      return cleanList;
    });
  }

  useImperativeHandle(
    ref,
    () => {
      function notify(message: string | Error) {
        setNotifications(notifications => [
          ...notifications,
          {
            id: generateId().toString(),
            message: message instanceof Error ? message.message : message,
            title: message instanceof Error ? message.name : "",
            severity: message instanceof Error ? "error" : "info",
            type: message instanceof Error ? "modal" : "snackbar",
            duration: 5000,
            open: true,
          },
        ]);
      }

      return {notify};
    },
    []
  );

  return (
    <>
      {notifications.map(notification =>
        notification.type === "snackbar" ? (
          <Snackbar
            key={notification.id}
            open={notification.open}
            autoHideDuration={notification.duration}
            onClose={() => closeNotification(notification)}
            anchorOrigin={{horizontal: "center", vertical: "bottom"}}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => closeNotification(notification)}
              severity={notification.severity}
            >
              {notification.message}
            </MuiAlert>
          </Snackbar>
        ) : (
          <Dialog
            key={notification.id}
            open={notification.open}
            onClose={() => closeNotification(notification)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{notification.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{notification.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeNotification(notification)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )
      )}
    </>
  );
};

export default forwardRef(NotificationsCenter);
