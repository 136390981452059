import React from "react";
import {
	Card,
	CardContent,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import LoadingTable from "./LoadingTable";
import { cardStyles } from "../../../IdLookup/DocumentTemplate/ProductionOrderDocumentTemplate/style";

// Custom styles for Skeleton components
export const useSkeletonStyles = makeStyles(() => ({
	skeleton: {
		borderRadius: "8px",
	},
}));

export function SidebarSkeleton() {
	const classes = useSkeletonStyles();

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
			<Skeleton
				animation="wave"
				variant="rect"
				width={100}
				height={100}
				classes={{ rect: classes.skeleton }}
			/>
			<Skeleton
				animation="wave"
				variant="rect"
				width="100%"
				height={30}
				classes={{ rect: classes.skeleton }}
			/>
			<Skeleton
				animation="wave"
				variant="rect"
				width="100%"
				height={50}
				classes={{ rect: classes.skeleton }}
			/>
			<div
				style={{
					marginTop: "1rem",
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
				}}
			>
				{[...Array(3)].map((_item, index) => (
					<div key={index}>
						<Skeleton
							animation="wave"
							variant="rect"
							width="40%"
							height={10}
							classes={{ rect: classes.skeleton }}
						/>
						<Skeleton
							animation="wave"
							variant="rect"
							width="70%"
							height={20}
							style={{ marginTop: "0.5rem" }}
							classes={{ rect: classes.skeleton }}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

const useCardStyles = makeStyles(cardStyles);
export function CardSkeleton({ cardNumber = 3 }: { cardNumber?: number }) {
	const classes = useSkeletonStyles();
	const card_classes = useCardStyles();

	return (
		<Grid container spacing={2}>
			{[...Array(cardNumber)].map((_, i) => (
				<Grid key={`card_skeleton_${i}`} item xs={12} sm={6} md={4} lg={4} xl={3}>
					<Card className={card_classes.card_root}>
						<CardContent>
							<Typography variant="body2">
								<Skeleton
									animation="wave"
									variant="text"
									width={"70%"}
									style={{ marginTop: "0.5rem" }}
									classes={{ rect: classes.skeleton }}
								/>
							</Typography>
							<div>
								<Typography style={{ width: "30%" }} variant="h4">
									<Skeleton
										animation="wave"
										variant="text"
										classes={{ rect: classes.skeleton }}
									/>
								</Typography>
								<Typography style={{ width: "50%" }} variant="body2">
									<Skeleton
										animation="wave"
										variant="text"
										classes={{ rect: classes.skeleton }}
									/>
								</Typography>
							</div>
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
}

export default function SkeletonLoaderTemplate() {
	const classes = useSkeletonStyles();

	return (
		<Grid container spacing={3}>
			{/* Sidebar Skeleton */}
			<Grid item xs={12} lg={3}>
				<SidebarSkeleton />
			</Grid>

			{/* Main Content Skeleton */}
			<Grid item xs={12} lg={9}>
				<div>
					<Skeleton
						animation="wave"
						variant="rect"
						width="100%"
						height="50vh"
						style={{ marginBottom: "1rem" }}
						classes={{ rect: classes.skeleton }}
					/>
					<LoadingTable />
				</div>
			</Grid>
		</Grid>
	);
}
