import React, { ReactElement, useEffect } from "react";
import { Product } from "../../system/products";
import useProduct from "./use-product";

export interface ProductProps {
  id?: number;
  onGetProductInfo?: (product: Product) => void;
  children: (product: Product | null, loading: boolean) => ReactElement;
}

const ProductWrap: React.FC<ProductProps> = (props) => {
  const [product, loading] = useProduct(props.id);

  useEffect(() => {
    if (!product || !props.onGetProductInfo) return;
    props.onGetProductInfo(product);
    // eslint-disable-next-line
  }, [product]);

  return props.children(product, loading);
};

export default ProductWrap;
