
import React, { useState, useEffect } from 'react';
import { ProductLinkDialogProps } from './types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import useNotify from '../../../core/hooks/use-notify';
import { moveLicensePlate } from '../../../core/system/license-plates';

const ProductLinkDialog: React.FC<ProductLinkDialogProps> = ({
  product,
  place,
  children,
  ...dialogProps

}) => {

  const notify = useNotify();
  const [moving, setMoving] = useState(false);
  const [moveSuccess, setMoveSuccess] = useState(false);

  const handleMove = async () => {
    try{
      if(!place) return;
      setMoving(true);
      await moveLicensePlate(product.id, place.id);
      setMoveSuccess(true);
    }
    catch(err){
      notify(err as Error);
      dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown');
    }
    finally{
      setMoving(false);
    }
  };

  useEffect(()=>{
    if(place)
      setMoveSuccess(false);

  }, [place]);

  return (
    <Dialog
      {...dialogProps}
    >
      <DialogTitle>Move this product</DialogTitle>
      <DialogContent>
        {!moving && !moveSuccess && (
          <DialogContentText>
            You are moving this product to this place "<Link href={`/${place?.beacon_id}`} target="_BLANK" underline="always">{place?.name}</Link>". Do you want to continue?
          </DialogContentText>
        )}
        {moving && !moveSuccess && (
          <DialogContentText>
            Moving this product to this place "<Link href={`/${place?.beacon_id}`} target="_BLANK" underline="always">{place?.name}</Link>". Please wait...
          </DialogContentText>
        )}
        {!moving && moveSuccess && (
          <DialogContentText>
            You have successfully moved this product to this place "<Link href={`/${place?.beacon_id}`} target="_BLANK" underline="always">{place?.name}</Link>".
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {moving && <CircularProgress size={24} />}
        {!moving && (
          <Button
            disabled={moving}
            onClick={()=>dialogProps.onClose?.call(undefined, {}, 'escapeKeyDown')}
          >
            Close
          </Button>
        )}
        {!moving && !moveSuccess && (
          <Button
            color="primary"
            variant="contained"
            endIcon={<Icon>thumb_up</Icon>}
            onClick={handleMove}
          >
            Confirm move
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ProductLinkDialog;
