import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  FormEvent,
} from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  DialogContent,
  Typography,
} from "@material-ui/core";
import {MoveAllDialogComponent} from "./types";
import {Place, getAllPlaces} from "../../../core/system/places";
import {Autocomplete} from "@material-ui/lab";
import {useAuthentication} from "../../../core/providers/AuthenticationProvider";
import {moveAll} from "../../../core/system/license-plates";
import useNotify from "../../../core/hooks/use-notify";
type PlaceSelectOption = {label: string; value: number};
const MoveAllDialog: MoveAllDialogComponent = (
  {onCompleted, children, sourceId},
  ref
) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState<PlaceSelectOption | null>();
  const [places, setPlaces] = useState<Place[] | null>();
  const notify = useNotify();

  useImperativeHandle(
    ref,
    () => ({
      openDialog: () => {
        setOpen(true);
      },
    }),
    []
  );

  const handleDialogClose = () => {
    setOpen(false);
  };

  const authenticated = !!useAuthentication()[0];
  useLayoutEffect(() => {
    if (!authenticated) return;

    let active = true;
    (async () => {
      try {
        setLoading(true);
        const places = await getAllPlaces();

        if (!active) return;
        setPlaces(
          places.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          )
        );
        setLoading(false);
      } catch (err: any) {
        if (!active) return;
      }
    })();

    return () => {
      active = false;
    };
  }, [authenticated]);

  async function handleMoveAllActivity(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (destination?.value) {
      try {
        setLoading(true);
        await moveAll(sourceId, destination?.value);

        notify("Operation in progress.");
        handleDialogClose();
      } catch (err) {
        notify(err as Error);
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Move All</DialogTitle>
      <form onSubmit={handleMoveAllActivity}>
        <DialogContent style={{padding: "0 24px 16px"}}>
          <Box>
            <Typography>Select location to move all</Typography>
            <Autocomplete
              fullWidth
              options={
                places?.map(el => ({label: el.name, value: el.id})) || []
              }
              value={destination}
              onChange={(ev: any, value: PlaceSelectOption | null) => {
                setDestination(value);
              }}
              disabled={loading}
              loading={loading}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <div>
                  <TextField
                    {...params}
                    placeholder="Select Location"
                    variant="outlined"
                  />
                </div>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{padding: "8px 24px 16px"}}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Continue
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default forwardRef(MoveAllDialog);
