
import React from 'react';
import { LayoutProps, LayoutOptions, LayoutApi } from './types';
import Box from '@material-ui/core/Box';
import { useState, useMemo } from 'react';
import layoutContext from './context';

const Layout: React.FC<LayoutProps> = props => {
  const { children, ...boxProps } = props;
  const [options, setOptions] = useState<LayoutOptions[]>([{...props.options}]);
  const [totalAsides, setTotalAsides] = useState(0);

  const api: LayoutApi = useMemo(() => ({
    registerAside: () => {
      setTotalAsides(curr=>++curr);
      return () => setTotalAsides(curr=>--curr);
    },
    options: options => {
      setOptions(currOptions => ([options, ...currOptions]));
      return () => setOptions(currOptions => {
        currOptions.shift();
        return [...currOptions];
      });
    }

  }), []);

  return (
    <layoutContext.Provider value={{api, options: options[0]}}>
      <Box
        component="section"
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection={totalAsides > 0 ? 'row' : 'column'}
        flex="auto"
        position="relative"
        {...boxProps}
      >{children}</Box>
    </layoutContext.Provider>
  );
};

export default Layout;
