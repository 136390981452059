
import React, { useState, useCallback, useMemo } from 'react';
import { firebaseService } from '../../core/system/legacy-services/firebase';
import { cleanId } from '../../core/system/legacy-services/firebase-old';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

const Location = function(props) {
  const { data } = props;
  const [licencePlates, setLicencePlates] = useState(null);
  const [loading, setLoading] = useState(false);

  const totalParts = useMemo(()=>{
    if(!licencePlates)
      return 0;

    return licencePlates.reduce((total, l_p)=>total+l_p.qty, 0);
  }, [licencePlates]);

  const loadMore = useCallback(async ()=>{
    try{
      setLoading(true);
      const document = await firebaseService.getDocument(data.contains_transactions_items);

      // Populate licence_plates info.
      const licencePlateIds = Object.keys(document.contains);
      const licencePlates = [];

      for(let i = 0; i < licencePlateIds.length; i++){
        if(document.contains[licencePlateIds[i]].currently_residing)
          licencePlates.push(await firebaseService.getLicencePlate(licencePlateIds[i]));
      }

      setLicencePlates(licencePlates);
    }
    catch(err){
      alert(err.message);
    }
    finally{
      setLoading(false);
    }

  }, [data]);

  return (
    <Container>
      <div className="location-template">
        <Paper>
          <Box p={2} bgcolor="grey.200">
            <Typography variant="h6"><b>Location</b> - { data.name }</Typography>
            <Typography variant="subtitle2" color="textSecondary">{data.location_id}</Typography>
          </Box>

          <Divider />

          <Box p={2} mb={2}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>ID</Typography>
                <Typography variant="body1">{ data.location_id }</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>NAME</Typography>
                <Typography>{ data.name }</Typography>
              </Grid>
              {!data.parentBeacon && (
                <Grid item xs={6} sm={4} md={3}>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>LOCATION</Typography>
                  <Link target="_BLANK" component={RouterLink} to={`/${cleanId(data.location)}`}>{ data.location }</Link>
                </Grid>
              )}
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>PARENT BEACON</Typography>
                { data.parentBeacon ? (<Link component={RouterLink} to={`/${cleanId(data.location)}`} target="_BLANK">{data.location}</Link>) : 'No' }
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>GPS</Typography>
                <Typography>{ data.gps ? 'Yes' : 'No' }</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box px={2}>
            <Box px={2} py={1} bgcolor="grey.100">
              <Typography variant="h6">Size</Typography>
            </Box>
          </Box>

          <Box p={2} mb={2}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>SIZE</Typography>
                <Typography>{ Math.round(data.sizeCubicMeter * 35.315 * 100) / 100 } <i>ft.<sup>3</sup></i></Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>WIDTH</Typography>
                <Typography>{ Math.round(data.width * 3.281 * 100) / 100 } <i>ft.</i></Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>HEIGHT</Typography>
                <Typography>{ Math.round(data.height * 3.281 * 100) / 100 } <i>ft.</i></Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>DEPTH</Typography>
                <Typography>{ Math.round(data.depth * 3.281 * 100) / 100 } <i>ft.</i></Typography>
              </Grid>
              {/* <Grid item xs={6} sm={4} md={3}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>SIZE UOM</Typography>
                <Typography>{ data.sizeUOM }</Typography>
              </Grid> */}
            </Grid>
          </Box>

          {!licencePlates && !!data.contains_transactions_items && (
            <>
              {!loading && (
                <Box p={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    onClick={loadMore}
                    disabled={loading}
                  >Load more...</Button>
                </Box>
              )}
              {loading && (
                <LinearProgress color="secondary" />
              )}
            </>
          )}

          {!!licencePlates && (
            <>
              <Box px={2}>
                <Box px={2} py={1} bgcolor="grey.100">
                  <Typography variant="h6">Contains</Typography>
                </Box>
              </Box>

              <Box p={2}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><b>LICENCE PLATE</b> <small>(Residing)</small></TableCell>
                            <TableCell><b>PART #</b></TableCell>
                            <TableCell><b>QTY.</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {licencePlates.filter(l_p=>l_p.qty !== 0).map(l_p=>(
                            <TableRow key={l_p.id}>
                              <TableCell>
                                <Link target="_BLANK" component={RouterLink} to={`/${cleanId(l_p.id)}`}>{l_p.id}</Link>
                              </TableCell>
                              <TableCell>{l_p.part_number}</TableCell>
                              <TableCell>{l_p.qty}</TableCell>
                            </TableRow>
                          ))}

                          <TableRow>
                            <TableCell />
                            <TableCell><i>TOTAL PARTS</i></TableCell>
                            <TableCell>{totalParts}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Paper>
      </div>
    </Container>
  );
};

export default Location;
