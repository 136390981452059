import React, {forwardRef, useCallback, useState, useRef} from "react";
import {FileUploaderProps, FileUploaderRef} from "../types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileUploader from "../FileUploader";
import randomize from "randomatic";

const splitFileName = (fileName?: string) => {
  if (!fileName) return fileName;
  const parts = fileName.split(/[.]+/g);
  const ext = parts.length <= 1 ? "" : parts.pop();

  return [parts.join("."), ext];
};

const ClassicUploader: React.ForwardRefRenderFunction<
  FileUploaderRef,
  FileUploaderProps & {disabled?: boolean}
> = ({disabled, ...props}, ref) => {
  const nameInput = useRef<HTMLInputElement>(null);
  const [_fn, setFileName] = useState("");

  const rename = useCallback(
    (currFileName: string) => {
      const noop = () => _fn;
      noop();
      const nameParts = currFileName.split(/[.|\s]+/g);
      const extension = nameParts.length <= 1 ? "" : nameParts.pop();

      return `${nameInput.current?.value}_${randomize("a0", 3)}${
        !extension ? "" : `.${extension}`
      }`;
    },
    [_fn]
  );

  return (
    <FileUploader ref={ref} rename={rename} {...props}>
      {({
        fileInput, // JSX.Element for the file input
        selectedFile, // Selected file or null
        selectedFileURL, // URL of the selected file or null
        isUploading, // Boolean indicating upload status
        progress, // Upload progress as a number
        clearSelection, // Function to clear the file selection
        fileInputRef,
      }) => (
        <Paper elevation={0}>
          <Box style={{padding: "0.5rem 0"}}>
            <Grid container spacing={1}>
              {fileInput}
              {!!selectedFile && (
                <Grid item>
                  <TextField
                    inputRef={nameInput}
                    key={selectedFile.name}
                    onChange={ev => setFileName(ev.target.value)}
                    defaultValue={(splitFileName(selectedFile.name) || [])[0]}
                    helperText={`Size: ${(selectedFile.size / 1024 / 1024)
                      .toFixed(2)
                      .toLocaleString()} MB`}
                    disabled={disabled || isUploading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={clearSelection}
                            disabled={disabled || isUploading}
                            edge="end"
                          >
                            <Icon color="error">clear</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                </Grid>
              )}

              {!isUploading && (
                <Grid item>
                  <Button
                    disabled={isUploading || disabled}
                    size="small"
                    variant={!selectedFile ? "contained" : "text"}
                    color="secondary"
                    onClick={() => fileInputRef?.current?.click()}
                    startIcon={<Icon>attach_file</Icon>}
                    style={{
                      textTransform: "initial",
                      display: isUploading ? "none" : "initial",
                    }}
                  >
                    {!selectedFile ? "Choose" : "Change"} file
                  </Button>
                </Grid>
              )}

              {isUploading && (
                <Grid item>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress variant="determinate" {...props} />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >{`${Math.round(progress)}%`}</Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      )}
    </FileUploader>
  );
};

export default forwardRef(ClassicUploader);
