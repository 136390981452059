
import { ACTION_TYPES } from '../actions/checkin-auth-actions';

const INITIAL_STATE = {
  access_rights: null,
  location: null,
  licence_plate: null,
  companyProfile: null,
  loading: ''
};

export default function checkinAuthReducer(state=INITIAL_STATE, action) {
  switch(action.type){
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      
    case ACTION_TYPES.SET_AUTH:
      return {
        ...state,
        ...action.payload
      };

    case ACTION_TYPES.UNSET_AUTH:
      return {
        ...state,
        access_rights: null,
        location: null,
        licence_plate: null,
        companyProfile: null,
        employees: []
      };

    case ACTION_TYPES.SET_COMPANY_PROFILE:
      return {
        ...state,
        companyProfile: action.payload
      };

    default:
      return state;
  }
};
