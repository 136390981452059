import {LOGO_TEMPLATE} from "../../utils/parse-template";

export interface ICertificateTemplate {
  email: string;
  title: string;
  date: string;
}

const certificate_template = (data: ICertificateTemplate) => {
  return `
  <!DOCTYPE html>
  <html>
    <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta charset="UTF-8">
    <style>
    body,
    html {
      margin: 0;
      padding: 0;
    }

    body {
      color: black;
      font-family: Georgia;
      font-size: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: white;
      position: relative;
    }

    .certificate_wrapper {
      width: 297mm;
      height: 210mm;
      position: relative;
      display: table;
      vertical-align: middle;
      background-color: white;
    }

    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    .top-left-triangle {
      border-width: 150px 150px 0 0;
      border-color: #ec1c24 transparent transparent transparent;
      top: 0;
      border-style: solid;
      left: 0;
    }

    .top-right-triangle {
      top: 0.8rem;
      right: 0.8rem;
      border: 14px solid #ec1c24;
      width: 150px;
      height: 150px;
      border-left: 0;
      border-bottom: 0;
    }

    .bottom-left-triangle {
      bottom: 0.8rem;
      left: 0.8rem;
      border-color: #ec1c24;
      border: 14px solid #ec1c24;
      width: 150px;
      height: 150px;
      border-right: 0;
      border-top: 0;
    }

    .bottom-right-triangle {
      border-width: 0 0 150px 150px;
      border-color: transparent transparent #ec1c24 transparent;
      bottom: 0;
      right: 0;
    }
    .container {
      display: table-cell;
      vertical-align: middle;
      background-color: white;
      z-index: 2000;
      position: absolute;
      padding: 20px;
      top: 3%;
      left: 2.5%;
      width: 95%;
      box-sizing: border-box;
      height: 94%;
    }

    .logo svg {
      width: 150px;
      height: 30px;
    }
    .logo {
      margin-top: 18%;
    }
    .marquee {
      color: #ec1c24;
      font-size: 3rem;
      margin: 20px 0;
    }
    .content {
      width: 100%;
    }
    .person {
      border-bottom: 2px solid black;
      font-size: 2rem;
      font-style: italic;
      margin: 20px auto;
      padding: 10px;
      width: 80%;
    }

    .reason {
      margin: 20px;
    }
    </style>
    </head>
  
    <body>
      <table class="certificate_wrapper">
      <td colspan="2" class="triangle top-left-triangle"></td>
        <tr>
          <td colspan="2" class="container">
          <div class="content">
            <div class="logo">
            ${LOGO_TEMPLATE()}
            </div>
            <div class="marquee">Certificate of Completion</div>
            <p>${data.date}</p>
            <div class="person">${data.email}</div>
            <div class="reason">
              Has successfully completed the training video: <br />
              <b>${data.title}</b>
            </div>
            </div>
          </td>
          <td class="triangle bottom-right-triangle"></td>
          <td class="triangle bottom-left-triangle"></td>
        </tr>
        <td colspan="2" class="triangle top-right-triangle"></td>
      </table>
    </body>
  </html>  
  `;
};

export default certificate_template;
