
import { ExportToCsv } from 'export-to-csv';

function dataToCsv(data: any[], fileName?: string) {
  const exporter = new ExportToCsv({
    filename: fileName || Date.now().toString(),
    showLabels: true,
    useKeysAsHeaders: true
  });

  exporter.generateCsv(data);
}

export default dataToCsv;
