// Function to parse the given data matrix
// Constants for header, tail, and group separator
const header = "[)>\u001e06\x1D";
const tail = "\u001e\u0004";
const groupSeparator = "\x1D";

const parseDataMatrix = (data: string): Record<string, string> => {
  // Mapping of data stream keys to their corresponding names
  const dataStreamMap: Record<string, string> = {
    P: "Customer Part Number",
    "1P": "Supplier Part Number",
    Q: "Quantity",
    "9D": "Date Code",
    "10D": "Date Code",
    "1T": "Lot Code",
    "4L": "Country of Origin",
    S: "Serial Number",
    "33P": "BIN Code",
    "2P": "Revision Number",
    E: "RoHS/CC",
    K: "Customer PO",
    "11K": "Packing List Number",
    "6D": "Ship Date",
    "13Q": "Package count",
    "7Q": "Weight",
    "1V": "Manufacturer",
    "3S": "Package ID",
    "4S": "Package ID",
    "5S": "Package ID",
    "4K": "Customer PO Line",
    MT: "Momentrack's code",
  };

  // Extracting data between header and tail
  const dataWithoutHeaderAndTail = data.substring(
    data.indexOf(header) + header.length,
    data.indexOf(tail)
  );

  // // Splitting data into individual data streams using group separator
  const dataStreamList = dataWithoutHeaderAndTail.split(groupSeparator);

  // Object to store parsed data streams
  let parsedData: Record<string, string> = {};

  for (const dataStreamValue of dataStreamList) {
    // Iterating through dataStreamMap keys to find a match
    for (const key of Object.keys(dataStreamMap)) {
      // If the data stream starts with the current key, parse and store the value
      if (dataStreamValue.startsWith(key)) {
        parsedData[`(${key}) ${dataStreamMap[key]}`] = dataStreamValue.replace(
          key,
          ""
        );
        break;
      }
    }
  }

  return parsedData;
};

/// Function for constructing the new DataMatrix string
export const editDataMatrix = (
  data: string,
  edit_key: string,
  value: string
) => {
  // Update the Quantity value
  let parsedData = parseDataMatrix(data);
  parsedData[`${edit_key}`] = value;

  const newDataMatrix =
    header +
    Object.keys(parsedData)
      .map(key => {
        // Regular expression to match content within parentheses
        const regex = /\(([^)]+)\)/;
        // Use match to get an array of matches
        const matches = key.match(regex);
        // Extract the actual key within parentheses
        const actualKey = matches ? matches[1] : key;
        return parsedData[key] ? actualKey + parsedData[key] : "";
      })
      .join(groupSeparator) +
    tail;

  return newDataMatrix;
};
export default parseDataMatrix;
