
import React from 'react';
import moment from 'moment/moment';
import { downloadStationLogs } from '../../core/system/legacy-services/wms';
import { makeStyles } from '@material-ui/core/styles';
import useStationReport from './use-station-report';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LinearProgress from '@material-ui/core/LinearProgress';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import styles from './styles';

const useStyles = makeStyles(styles);

const StationReportPage = props => {
  const {match, history} = props;
  const [report, loading, message] = useStationReport(match.params.stationId);
  const classes = useStyles();

  return (
    <Container>
      <Box py={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box py={2} mb={1}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <IconButton edge="start" color="primary" onClick={()=>history.replace('/wms/stations')}>
                    <BackIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h4">Station report</Typography>
                </Grid>
              </Grid>
            </Box>
            <Paper>
              {loading && <Box p={2}><LinearProgress /></Box>}
              {!!message && (
                <Box p={2}><Typography>{message}</Typography></Box>
              )}
              {!!report && <Box p={2}>
                <Grid container spacing={4}>
                  <Grid item xs={6} sm={4}>
                    <Typography variant="caption" color="textSecondary" gutterBottom component="div">STATION</Typography>
                    <Typography variant="h6" >{report.name}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography variant="caption" color="textSecondary" gutterBottom component="div">QTY IN STATION</Typography>
                    <Typography variant="h6" >{report.quantity}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Typography variant="caption" color="textSecondary" gutterBottom component="div">OLDEST ITEM</Typography>
                    <Typography variant="h6" >{report.oldest_item || '--'}</Typography>
                  </Grid>
                </Grid>
              </Box>}
            </Paper>
          </Grid>

          {!!report && (
            <Grid item xs={12}>
              <Box textAlign="right" py={2} mb={1}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Logs</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={loading || !report.log || !report.log.length}
                      onClick={()=>downloadStationLogs(report.log)}
                      
                    >Download CSV</Button>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tebleCell}>PART NUMBER</TableCell>
                      <TableCell className={classes.tebleCell}>QUANTITY</TableCell>
                      <TableCell className={classes.tebleCell}>PERSON</TableCell>
                      <TableCell className={classes.tebleCell}>ARRIVED</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!!report.log && report.log.map((log, i)=>(
                      <TableRow key={''+i}>
                        <TableCell className={classes.tebleCell}>{ log.part_number }</TableCell>
                        <TableCell className={classes.tebleCell}>{ log.qty }</TableCell>
                        <TableCell className={classes.tebleCell}>{ log.person_name }</TableCell>
                        <TableCell className={classes.tebleCell}>{ moment.unix(log.arrived).format('MMM DD, YYYY - hh:mma') }</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!loading && !!report.log && !report.log.length && (
                <Box p={2}>
                  <Typography variant="body2" color="textSecondary">No logs available.</Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default StationReportPage;
