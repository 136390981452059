
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const RatioedButton = withStyles(theme=>({
  root: ({ ratio='1:1' }) => {
    const [width, height] = ratio.split(':').map(Number);

    return {
      width: '100%',
      paddingBottom: 0,
      paddingTop: `${(height/width)*100}%`
    }
  },
  label: {
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0
  }
}))(Button);

export default RatioedButton;
