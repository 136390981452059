
import { useEffect, useState } from 'react';

const MagicText = function(props) {
  const {
    magicFunction = ()=>{},
    magicInput,
    children
  } = props;

  const [updatedText, setUpdatedText] = useState('');

  useEffect(()=>{
    (async ()=>{
      try{
        const text = await magicFunction(magicInput);
        if(typeof text === 'string' && text.length > 0)
          setUpdatedText(text);
      }
      catch(err){
        // ignore error. but do catch it.
      }
    })();

  }, [magicFunction, magicInput]);

  return (updatedText || children || '--');
};

export default MagicText;
