
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import RatioedButton from '../../core/components/RatioedButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

const HomePage = function() {
  return (
    <Container style={{ height: '100%' }}>
      <Box height="100%" py={2} boxSizing="border-box">
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4} md={3}>
            <Link to="/move_many" underline="none" component={RouterLink} style={{ display: 'block' }}>
              <Paper>
                <RatioedButton ratio="1:1" style={{ fontSize: 20, fontWeight: 500 }}>
                  Move many
                </RatioedButton>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Link to="/received1" underline="none" component={RouterLink} style={{ display: 'block' }}>
              <Paper>
                <RatioedButton ratio="1:1" style={{ fontSize: 20, fontWeight: 500 }}>
                  Create many
                </RatioedButton>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Link to="/AGh8dOH86" underline="none" component={RouterLink} style={{ display: 'block' }}>
              <Paper>
                <RatioedButton ratio="1:1" style={{ fontSize: 20, fontWeight: 500 }}>
                  Set printer
                </RatioedButton>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Link to="/deductitm" underline="none" component={RouterLink} style={{ display: 'block' }}>
              <Paper>
                <RatioedButton ratio="1:1" style={{ fontSize: 20, fontWeight: 500 }}>
                  Deduct
                </RatioedButton>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomePage;
