
import React, { useCallback, useState, useRef, useImperativeHandle, useEffect, forwardRef } from 'react';

const CountdownText = function(props, ref) {
  const {
    startValue = 30,
    endValue = 0,
    stepSize = 1,
    stepDelay = 1000,
    autoStart = false,
    onEnd = ()=>null,
    hidden = false,
    textBefore = '',
    textAfter = ''
  } = props;

  const [currentValue, setCurrentValue] = useState(startValue);
  const timer = useRef(null);

  const ended = currentValue <= endValue;

  const stop = useCallback(()=>{
    if(timer.current){
      clearInterval(timer.current);
      timer.current = null;
    }

  }, []);

  const reset = useCallback(()=>{
    stop();
    setCurrentValue(startValue);

  }, [stop, startValue]);

  const start = useCallback((forceReset)=>{
    if(timer.current)
      return;

    if(forceReset)
      reset();

    timer.current = setInterval(()=>{
      setCurrentValue(val=>val-stepSize);
    }, stepDelay);

  }, [reset, stepDelay, stepSize]);

  const restart = useCallback(()=>{
    reset();
    start();

  }, [reset, start]);

  const end = useCallback(()=>{
    setCurrentValue(endValue);

  }, [endValue]);

  useImperativeHandle(ref, ()=>({
    start,
    stop,
    reset,
    restart,
    end
  }), [reset, restart, stop, start, end]);

  useEffect(()=>()=>stop(), [stop]);

  useEffect(()=>{
    if(ended){
      stop();
      onEnd();
    }

  }, [ended, onEnd, stop]);

  useEffect(()=>{
    if(autoStart)
      start();

  }, [start, autoStart]);

  if(hidden)
    return null;

  return <>{textBefore}{currentValue}{textAfter}</>;
};

export default forwardRef(CountdownText);
